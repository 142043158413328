import React from 'react';

const TimeSlot = (props) => {
    return (
        <React.Fragment>
            <h2 className="time-slot" style={{ gridColumn: '10/70' }}>8:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '70/130' }}>9:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '130/190' }}>10:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '190/250' }}>11:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '250/310' }}>12:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '310/370' }}>13:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '370/430' }}>14:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '430/490' }}>15:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '490/550' }}>16:00</h2>
            <h2 className="time-slot" style={{ gridColumn: '550/610' }}>17:00</h2>
        </React.Fragment>
    )
}

export default TimeSlot;