import "./PrintApplicant.css";
import React, { useEffect, useState } from "react";
import Receipt from "./Receipt/Receipt";
import axios from "../../../../axios/BUAP/students";
import GetToken from "../../../../Shared/GetToken";
import BankStamp from "./Bankstamp/Bankstamp";
import Semester from "../../../../components/Shared/Semester";

const PrintReceipt = (props) => {
  const [financialStatement, setfinancialStatement] = useState([]);
  const [balance, setbalance] = useState(0);
  const [jdCurrency, setjdCurrency] = useState(1);
  const [semester, setsemester] = useState(1);
  const [studentName, setstudentName] = useState("");
  const [studentno, setstudentno] = useState("");
  const [minimumPayement, setminimumPayement] = useState([]);
  useEffect(() => {
    async function updateData() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const stuno = params.get("stuno");
      const studeg = params.get("studeg");
      const term = params.get("term");
      setsemester(term);
      const username = localStorage.getItem("username");
      const link =
        "GetFinancialStatement/" +
        term +
        "/" +
        username +
        "/" +
        studeg +
        "/" +
        stuno;
      axios
        .get(link, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          console.log(response.data);
          setfinancialStatement(response.data.finFrontend.financialStatement);
          setbalance(response.data.finFrontend.balance);
          setjdCurrency(response.data.finFrontend.jdCurrency);
          setminimumPayement(response.data.finFrontend.minimumPayement);
          setsemester(response.data.finFrontend.term)
        });

      axios
        .get("NameAndNum/" + studeg, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setstudentName(response.data.studentName);
          setstudentno(response.data.studentNumber);
        });
    }
    updateData();
  }, []);

  return (
    <div className="printcontainer">
      <div className="headerLogo padding">
        <i>Finance Office</i>
        <i>Student Finance Unit</i>
      </div>
      <div className={`padding studentInfo`}>
        <span>
          Student No: <strong>{studentno}</strong>{" "}
        </span>
        <span>
          Student Name: <strong>{studentName}</strong>{" "}
        </span>
      </div>
      <div className="padding">
        <div className="headerText">
          <Semester sem={semester} txt="Financial Statement for " />
        </div>
      </div>

      <div className={`grid--1--colsNotResponsive padding`}>
        <Receipt
          data={financialStatement}
          balance={balance}
          jdCurrency={jdCurrency}
        />
      </div>
      <div className={`grid--1--colsNotResponsive padding`}>
        <BankStamp
          minAmount={
            minimumPayement === null ? "Not allowed" : minimumPayement.amount
          }
          endDate={
            minimumPayement === null ? "Not allowed" : minimumPayement.enddate
          }
          stuname={studentName}
          stunum={studentno}
          term={semester}
          jdCurrency={jdCurrency}
          balance={balance}
        />
      </div>
      <div className={`footer grid--2--colsNotResponsive`}>
        <div className="footerDiv">
          <span>Finance Office / Student Finance Unit</span>
          <span>Tel: +970-2-2741241 Ext: 2291</span>
          <span>Email: studentfinance@bethlehem.edu</span>
          <span>5 Rue des Frères, Bethlehem, Palestine</span>
        </div>
        <div className="footerDiv footerDivAr">
          <span>المكتب المالي / وحدة مالية الطلبة </span>
          <span>هاتف: 2741241-2-970+، داخلي: 2291</span>
          <span>studentfinance@bethlehem.edu :بريد الكتروني</span>
          <span>5 شارع الفرير، بيت لحم، فلسطين </span>
        </div>
      </div>
    </div>
  );
};

export default PrintReceipt;
