import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { connect } from "react-redux";
import axios from "../../axios/BUAP/common";
import GetToken from "../../Shared/GetToken";

const ViewOldBuap = (props) => {
  const [url, setURL] = useState([]);
  useEffect(() => {
    axios
      .get("EncryptedUsername", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        // let urllink =
        //   "https://buapv1.bethlehem.edu/newbuap/viewoldpage.php?un=" +
        //   response.data; //Encrypted username
        const urllink =
          response.data +
          "&ut=" +
          props.usertype +
          "&page=" +
          props.page +
          "&username=" +
          props.username +
          "&stuno=" +
          props.stuno;
        setURL(urllink);
      });
  }, [props]);
  return (
    <div style={{ position: "relative" }}>
      {/* <div
        style={{
          position: "absolute",
          top: "40%",
          left: "45%",
        }}
      >
        <div
          style={{
            zIndex: "-1",
          }}
        >
          <SpinnerBootstrap />
        </div>
      </div> */}
      <div
        style={{
          position: "relative",
        }}
      >
        <iframe
          title="oldbuap"
          style={{ margin: "15px", overflow: "hidden", border: "none" }}
          src={url}
          width="98%"
          height="700px"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    usertype: state.utype.usertype,
    stuno: state.stuno.studentno,
  };
};
export default connect(mapStateToProps)(ViewOldBuap);
