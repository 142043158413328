import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios from "../../../../axios/BUAP/students";
import Semester from "../../../Shared/Semester";
import PropTypes from "prop-types";
import GetToken from "../../../../Shared/GetToken";
import { connect } from "react-redux";
import GetStudentStatus from "../../../../containers/Registration/GetStudentStatus";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import SpinnerBootstrap from "../../../UI/Spinner/Spinner";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paperItem: {
    padding: theme.spacing(0.2),
    textAlign: "left",
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  title: {
    fontWeight: "bold",
    width: 140,
    display: "inline-block",
  },
  description: {
    display: "inline-block",
  },
  name: {
    marginTop: 5,
    textAlign: "left",
  },
  [theme.breakpoints.down("xs")]: {
    title: {
      width: "auto",
    },
    description: {
      width: "auto",
    },
    paperItem: {
      fontSize: 11,
      padding: theme.spacing(0.5),
    },
    name: {
      fontSize: 15,
      fontWeight: "bold",
      textAlign: "left",
    },
  },
});
class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      open: false,
      show: this.props.show,
      studentName: "",
      studentPersonalInfo: {
        classification: "",
        faculty: "",
        degreeName: "",
        major: "",
        advisor: "",
        minor: "",
        admittedYear: "",
        paradigm: "",
        tawjihiStream: "",
        studentLevel: "",
        graduationTerm: "",
        withdraw: "",
        probation: "",
      },
      waitData: true,
    };
    this.getData = this.getData.bind(this);
    this.updateOpen = this.updateOpen.bind(this);
  }

  componentDidMount() {
    if (this.state.show && this.props.stuNo !== 0) {
      this.getData();
    } else {
      axios
        .get("StudentName/" + this.props.stuNo, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          this.setState({ studentName: response.data });
          this.setState({ waitData: false });
        });
    }
  }

  updateOpen() {
    const open = !this.state.open;
    if (open) {
      this.getData();
    }
    this.setState({ open: open });
  }

  getData() {
    axios
      .get("PersonalInfo/" + this.props.stuNo, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        this.setState({ studentPersonalInfo: response.data });
        this.setState({ studentName: response.data.studentName });
        this.setState({ waitData: false });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.waitData ? (
          <SpinnerBootstrap />
        ) : (
          <React.Fragment>
            <Typography>{this.state.errorMessage}</Typography>
            <Typography
              className={classes.name}
              style={{ fontSize: "20px", color: "black" }}
            >
              {this.props.stuNo} - {this.state.studentName}
              <GetStudentStatus />
              <br />
            </Typography>
            {!this.state.show ? (
              <Button
                variant="light"
                onClick={() => this.updateOpen()}
                aria-controls="collapse"
                aria-expanded={this.state.open}
                title="معلومات الطالب"
              >
                Student information
              </Button>
            ) : null}

            <Collapse in={this.state.show ? true : this.state.open}>
              <div className={classes.root}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Paper
                      title="الكلية"
                      className={classes.paperItem}
                      elevation={0}
                    >
                      <span className={classes.title}>Faculty :</span>
                      <span className={classes.faculty}>
                        {this.state.studentPersonalInfo.faculty}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="المشرف الاكاديمي">
                        Advisor :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.advisor}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="الدرجة">
                        Degree:
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.degreeName}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="مستوى الطالب">
                        Student Level :
                      </span>
                      {this.state.studentPersonalInfo.studentLevel}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="التخصص">
                        Major :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.major}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="سنة القبول">
                        Admitted year :
                      </span>
                      <span className={classes.description}>
                        <Semester
                          sem={this.state.studentPersonalInfo.admittedYear.toString()}
                        ></Semester>
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span
                        className={classes.title}
                        title="البرنامج الدراسي لتخصص"
                      >
                        Major POS :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.majorPOS}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span
                        className={classes.title}
                        title="شهادة الثانوية العامة"
                      >
                        Tawjihi stream :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.tawjihiStream}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="التخصص الفرعي">
                        Minor :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.minor}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="فصل التخرج">
                        Graduation Term :
                      </span>
                      <Semester
                        sem={this.state.studentPersonalInfo.graduationTerm}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span
                        className={classes.title}
                        title="البرنامج الدراسي لتخصص الفرعي"
                      >
                        Minor POS :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.minorPOS}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="التحذير الاكاديمي">
                        Probation :
                      </span>
                      {this.state.studentPersonalInfo.probation !== "" &&
                      this.state.studentPersonalInfo.probation !==
                        "Teacher evaluation block" ? (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          Yes -{" "}
                          <Semester
                            sem={this.state.studentPersonalInfo.probation}
                          />
                        </span>
                      ) : (
                        <span>None</span>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="الخطة الاكاديمية">
                        Paradigm :
                      </span>
                      <span className={classes.description}>
                        {this.state.studentPersonalInfo.paradigm}
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span className={classes.title} title="فصل الانسحاب">
                        Withdrawal term :
                      </span>
                      <Semester sem={this.state.studentPersonalInfo.withdraw} />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperItem} elevation={0}>
                      <span
                        className={classes.title}
                        title="تصنيف اللغة الانجليزية"
                      >
                        English classification :
                      </span>
                      {this.state.studentPersonalInfo.classification}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

PersonalInfo.propTypes = {
  stuNo: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    usertype: state.utype.usertype,
  };
};
export default withStyles(useStyles)(connect(mapStateToProps)(PersonalInfo));
