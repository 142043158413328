import React from "react";
import classes from "./PrintButton.module.css";
import PrintIcon from "@mui/icons-material/Print";

const PrintButton = (props) => {
  return (
    <div className={`${classes.printBtn} hideonprint`}>
      <PrintIcon onClick={() => window.print()}></PrintIcon>
    </div>
  );
};

export default PrintButton;
