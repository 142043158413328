import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "../../../../axios/BUAP/registration";
import axioscart from "../../../../axios/BUAP/cart";
import GetToken from "../../../../Shared/GetToken";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { Button, Form } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";

const PreapprovalCoursesList = (props) => {
  const [data, setData] = useState({
    courseCatalogCode: 0,
    catalogKey: "",
    title: -1,
    credits: -1,
    relatedTo: "",
    offeredIn: "",
    prerequisite: "",
  });
  const [courseCode, setCourseCode] = useState(-1);
  const [courseCatalog, setCourseCatalog] = useState("");
  const [title, setTitle] = useState("");
  const [credits, setCredits] = useState(0);
  const [msg, setMsgHandle] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    axios
      .get("ListOfOfferedCourses/", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        const data = {
          columns: [
            {
              label: "catalogKey",
              field: "catalogKey",
              sort: "asc",
              width: 150,
            },
            {
              label: "Title",
              field: "title",
              sort: "asc",
              width: 270,
            },
            {
              label: "Credits",
              field: "credits",
              sort: "asc",
              width: 150,
            },
          ],
          rows: [],
        };
        const rdata = response.data;
        let rowsData = [];
        for (let i = 0; i < rdata.length; i++) {
          rowsData.push({
            catalogKey: rdata[i].catalogKey,
            title: rdata[i].title,
            credits: rdata[i].credits,
            clickEvent: () =>
              selectCourseHandler(
                rdata[i].courseCatalogCode,
                rdata[i].catalogKey,
                rdata[i].title,
                rdata[i].credits
              ),
          });
        }

        data.rows = rowsData;
        setData(data);
      });
  }, [props.studentdegree, props.usertype]);

  const selectCourseHandler = (
    courseCode,
    courseCatalog,
    courseCatalogKey,
    credits
  ) => {
    setMsgHandle("");
    setCourseCode(courseCode);
    setCourseCatalog(courseCatalog);
    setTitle(courseCatalogKey);
    setCredits(credits);
  };

  const saveInfoHandler = async () => {
    setWait(true);

    const adddropCheck = document.getElementsByName("adddrop");
    let add = false;
    if (adddropCheck[0].checked) {
      add = true;
    } else if (adddropCheck[1].checked) {
      add = false;
    }

    const mandatoryOptionalCheck =
      document.getElementsByName("manadtoryoptional");
    let mandatory = false;
    if (mandatoryOptionalCheck[0].checked) {
      mandatory = true;
    } else if (mandatoryOptionalCheck[1].checked) {
      mandatory = false;
    }
    let note = document.querySelector('input[name = "notes"]').value;
    if (note === null || note === "") {
      note = null;
    }

    const payload = {
      inCourseCode: courseCode,
      inAdd: add,
      inManadtory: mandatory,
      inNote: note,
      inStuDeg: props.studentdegree,
      inFacCode: props.iduser,
    };

    await axioscart
      .post("AddPreApproval/", payload, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        if (response.data === "success") {
          setSubmitted(true);
        } else {
          setMsgHandle(response.data);
        }
      });

    await props.updatestudentstatus(props.upstatus + 1);
    await props.updatelist();

    setWait(false);
  };

  return (
    <>
      {!wait ? (
        <React.Fragment>
          {courseCode === -1 ? (
            <div>
              <MDBDataTable striped bordered small hover data={data} />
            </div>
          ) : (
            <div>
              {submitted ? (
                <div>
                  <Typography style={{ fontWeight: "bold", color: "green" }}>
                    The course has been added successfully!
                  </Typography>
                </div>
              ) : (
                <div>
                  Course: <b>{courseCatalog}</b> / {title} - {credits}/
                  <Button
                    variant="light"
                    onClick={() => selectCourseHandler(-1, "", "", -1)}
                    style={{ color: "#fff", backgroundColor: "#f08c00" }}
                  >
                    Select another course
                  </Button>
                  <Typography style={{ fontWeight: "bold" }}>
                    PreApproval conditions:
                  </Typography>
                  <Typography>
                    Add/Drop:
                    {["radio"].map((type) => (
                      <span key={`inline-${type}`}>
                        <Form.Check
                          inline
                          label="Add"
                          name={`adddrop`}
                          type={type}
                          id={`adddrop-1`}
                          defaultChecked={true}
                        />
                        <Form.Check
                          inline
                          label="Drop"
                          name={`adddrop`}
                          type={type}
                          id={`adddrop-2`}
                          defaultChecked={false}
                        />{" "}
                      </span>
                    ))}
                  </Typography>
                  <Typography>
                    Manadtory/Optional:
                    {["radio"].map((type) => (
                      <span key={`inline-${type}`}>
                        <Form.Check
                          inline
                          label="Manadtory"
                          name={`manadtoryoptional`}
                          type={type}
                          id={`manadtoryoptional-1`}
                          defaultChecked={true}
                        />
                        <Form.Check
                          inline
                          label="Optional"
                          name={`manadtoryoptional`}
                          type={type}
                          id={`manadtoryoptional-2`}
                          defaultChecked={false}
                        />{" "}
                      </span>
                    ))}
                  </Typography>
                  <br />
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Notes:
                    </Form.Label>
                    <Form.Control
                      id={`notes`}
                      name={`notes`}
                      type="textarea"
                      placeholder="Note"
                      rows={3}
                    />
                  </Form.Group>
                  {msg !== "" ? (
                    <div>
                      <b
                        style={{
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "12px",
                          margin: "12px 0",
                          display: "inline-block",
                        }}
                      >
                        {msg}
                      </b>
                      <br />
                    </div>
                  ) : null}
                  <div>
                    <Button onClick={saveInfoHandler} variant="success">
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <SpinnerBootstrap />
      )}
    </>
  );
};

//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    updatestudentstatus: (e) => dispatch(actionCreators.setupdatestatus(e)),
  };
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    usertype: state.utype.usertype,
    iduser: state.idu.iduser,
    updatecart: state.ucart.updatecart,
    upstatus: state.upstatus.updatestatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreapprovalCoursesList);
