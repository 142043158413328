import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import advisorAxios from "../../../../axios/BUAP/advisor";
import GetToken from "../../../../Shared/GetToken";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const SendEmail = (props) => {
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [toAdvisors, settoAdvisors] = useState(false);
  const [toStudents, settoStudents] = useState(false);

  const sendEmails = () => {
    const rawContentState = convertToRaw(message.getCurrentContent());
    const markup = draftToHtml(
      rawContentState,
      {
        trigger: "#",
        separator: " ",
      },
      true
    );
    const link = "Sendemails/" + toAdvisors + "/" + toStudents;
    const r = window.confirm("Would you like to send the message?");

    const data = {
      ListOfStudents: props.listOfStudents,
      Message: markup,
      Subject: subject,
    };

    if (r) {
      advisorAxios
        .post(link, data, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setsubject("");
          setmessage("");
          settoAdvisors(false);
          settoStudents(false);
          alert("sent successfully!");
        });
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="subject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          placeholder="Email subject"
          onChange={(e) => setsubject(e.target.value)}
          value={subject}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="msg">
        <Form.Label>Message</Form.Label>
        <Editor
          editorState={message}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorStyle={{
            padding: "1rem",
            border: "1px solid #ccc",
            height: "200px",
          }}
          onEditorStateChange={setmessage}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="to">
        <Form.Label>To:</Form.Label>
        <Form.Check
          type="checkbox"
          id={"toAdvisors"}
          label={"Advisors"}
          onChange={(e) => settoAdvisors(e.target.checked)}
          checked={toAdvisors}
        />
        <Form.Check
          type="checkbox"
          id={"toStudents"}
          label={"Students"}
          onChange={(e) => settoStudents(e.target.checked)}
          checked={toStudents}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="send">
        <Button onClick={() => sendEmails()} aria-controls="send">
          Send
        </Button>
      </Form.Group>
    </Form>
  );
};

export default SendEmail;
