import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import axios from "../../axios/BUAP/common";
import GetToken from "../../Shared/GetToken";
import ReactHtmlParser from "react-html-parser";
const Message = (props) => {
  const [msg, setMsg] = useState([]);
  useEffect(() => {
    if (props.msgCode !== 0) {
      axios
        .get("Message/" + props.msgCode, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setMsg(response.data);
        });
    }
  }, [props.msgCode]);
  return (
    <Alert key="primary" variant="primary">
      {ReactHtmlParser(msg)}
    </Alert>
  );
};

export default Message;
