import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import EmptyPhoto from '../../../../assets/images/emptyPhoto.jpg'
import PersonalInfo from './PersonalInfo';
import StudentPic from '../../../../containers/BUAP/Student/Pictures/StudentPic';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minheight: 230,
  },
  media: {
    backgroundSize: 'cover',
    maxHeight: 'auto'
  },
  paperItem: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
}));

const StudentInfo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <Paper className={classes.paper}>
            <StudentPic
              className={classes.media}
              image={EmptyPhoto}
              title="Personal Info"
              stuNo={props.stuNo}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <PersonalInfo stuNo={props.stuNo} show={true} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default StudentInfo;