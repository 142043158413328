import React, { useEffect, useState } from 'react';
import axios from '../../../../axios/BUAP/students';
import GetToken from '../../../../Shared/GetToken';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import SpinnerBootstrap from '../../../UI/Spinner/Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    paperItem: {
        padding: theme.spacing(1),
        textAlign: 'left',
        fontSize: 12,
        color: theme.palette.text.primary,
    },
    title: {
        fontWeight: "bold",
        width: 150,
        display: 'inline-block'
    },
    gridShadowBox: {
        border: '1px solid gray',
    },
    [theme.breakpoints.down('xs')]: {
        title: {
            width: 'auto'
        }
    },
}));
const TransferCourses = (props) => {
    const classes = useStyles();
    const [transferCourses, setTransferCourses] = useState('');
    const [transferCoursesCredits, setTransferCoursesCredits] = useState('');
    const [waitData, setWaitData] = useState(true);
    useEffect(() => {
        axios.get('TransferCourses/' + props.stuDeg, {
            headers: { 'Authorization': GetToken("token") }
        })
            .then(response => {
                setTransferCourses(response.data.transferCourses);
                setTransferCoursesCredits(response.data.credits);
                setWaitData(false);
            });
    }, [props.stuDeg]);

    return (
        <React.Fragment >
            {waitData ? <SpinnerBootstrap /> :
                <React.Fragment>
                    {transferCourses ?

                        <div className={classes.root}>
                            <Grid container
                                className={classes.gridShadowBox}
                            >
                                <Grid item xs={12} sm={9}>
                                    <Paper className={classes.paperItem} elevation={0}><div className={classes.title}>
                                        Transfer work: </div>{transferCourses}</Paper>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Paper className={classes.paperItem} elevation={0}><div className={classes.title}>
                                        Total Credit Hours</div>{transferCoursesCredits}</Paper>
                                </Grid>
                            </Grid>
                        </div>

                        : null}
                </React.Fragment>}</React.Fragment>
    )
}

export default TransferCourses;