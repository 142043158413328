import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState = {
    iduser: '',
};

const iduserReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETIDUSER:
            return {
                ...state,
                iduser: action.value
            }
        default:
            return state;
    }
}

export default iduserReducer;