import React, { useState, useEffect } from 'react';

const Semster = (props) => {
    const [formatedSemster, setformatedSemster] = useState('');
    useEffect(() => {
        if (props.sem) {
            const year = props.sem.toString().substring(0, 4);
            const term = parseInt(props.sem.toString().substring(4, 5));
            let txt = "";
            if (typeof props.txt !== 'undefined') {
                txt = props.txt;
            }
            if (term === 1) {
                setformatedSemster(txt + 'Spring-' + year);
            }
            else if (term === 2) {
                setformatedSemster(txt + 'Summer-' + year);
            }
            else if (term === 3) {
                setformatedSemster(txt + 'Fall-' + year);
            }

        }

    }, [props.sem, props.txt]);

    return (
        <React.Fragment>
            {formatedSemster}
        </React.Fragment>
    )
}



export default Semster;