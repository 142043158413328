import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    textAlign: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ScheduleTerm = (props) => {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
          <Paper className={classes.paperItem} elevation={0}><div className={classes.title}>{props.title}</div></Paper>
        </Grid>

      </Grid>
    </div>
  )
}

export default ScheduleTerm;