import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "../../../../axios/BUAP/cart";
import { connect } from "react-redux";
import ParadigmIcon from "../../../BUAP/Student/Paradigm/ParadigmIcon";
import PopupComponent from "../../../../containers/Registration/PopupComponent";
import ListOfSections from "./ListOfSections";
import ReactHtmlParser from "react-html-parser";
import { XCircleFill, ArrowClockwise } from "react-bootstrap-icons";
import { Button, Typography } from "@material-ui/core";
import GetToken from "../../../../Shared/GetToken";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { Form } from "react-bootstrap";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  [theme.breakpoints.down("xs")]: {
    head: {
      padding: 3,
      fontSize: 9,
    },
    body: {
      padding: 3,
      fontSize: 9,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  link: {
    cursor: "pointer",
    color: "#2a67a8",
    fontWeight: "bold",
  },
  dropIcon: {
    cursor: "pointer",
    color: "#FF0000",
    fontWeight: "bold",
    fontSize: "large",
  },
  dropRegistered: {
    cursor: "pointer",
    color: "#FF6347",
    fontWeight: "bold",
    fontSize: "large",
  },
  [theme.breakpoints.down("xs")]: {
    columnTable: {
      display: "none",
    },
    table: {
      minWidth: 200,
    },
  },
}));

const CartCourses = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(-1);
  const [selectedCatalogkey, setSelectedCatalogkey] = React.useState(-1);
  const [modalShow, setModalShow] = useState(false);
  const [actualCredits, setActualCredits] = useState(0);
  const [showCart, setShowCart] = React.useState(-1);

  const [data, setData] = useState({
    allowedToDrop: false,
    catalogCode: "",
    catalogKey: "",
    courseOffered: "",
    credits: 0,
    dropped: false,
    maxNoOfStudents: 0,
    notes: "",
    regTerm: 0,
    section: "",
    status: "",
    teacher: "",
    title: "",
    courseType: 0,
    conflict: false,
  });

  useEffect(() => {
    updateCartHandler(props.studentdegree, props.usertype);
  }, [props.studentdegree, props.updatecart, props.usertype]);

  const updateCartHandler = (stuDeg, usertype) => {
    axios
      .get("StudentCart/" + stuDeg + "/" + usertype, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        if (response.data.cartFrontEnd.available) {
          setShowCart(true);
          setData(response.data.cartFrontEnd.cart);
          setActualCredits(response.data.cartFrontEnd.actualTotalCredits);
          // const approveReject = [];
          // for (const element of response.data.cartFrontEnd.cart) {
          //   const obj = {};
          //   obj["CatalogCode"] = element.catalogCode;
          //   obj["approve"] = null;
          //   obj["rejectReason"] = null;
          //   approveReject.push(obj);
          //   props.setupcoursecart({
          //     courseCode: element.catalogCode,
          //     status: element.status,
          //   });
          // }
        } else {
          setShowCart(false);
        }
      });
  };

  const changeSections = (courseCode, catalogKey) => {
    setSelected(courseCode);
    setSelectedCatalogkey(catalogKey);
    setModalShow(true);
  };

  const dropIconOnCart = (
    sectionStatus,
    catalogCode,
    courseOffered,
    dropped,
    courseType,
    allowedToDrop
  ) => {
    let icon = null;
    const allowedTypes = [4, -1, 8];
    if (
      props.usertype !== "0" ||
      allowedToDrop === true ||
      allowedTypes.includes(courseType) ||
      sectionStatus === "1"
    ) {
      if (dropped) {
        icon = (
          <Button onClick={() => undoADroppedSection(catalogCode)}>
            <ArrowClockwise className={classes.dropIcon} />
          </Button>
        );
      } else if (
        sectionStatus === "1" ||
        sectionStatus === "2" ||
        sectionStatus === "3"
      ) {
        icon = (
          <Button
            onClick={() =>
              dropSectionAddedToTheCart(catalogCode, courseOffered)
            }
          >
            <XCircleFill className={classes.dropIcon} />
          </Button>
        );
      } else if (
        sectionStatus === "Registered" ||
        sectionStatus === "Planned" ||
        (sectionStatus === "4" && props.usertype !== "0")
      ) {
        icon = (
          <Button onClick={() => dropRegisteredSection(catalogCode)}>
            <XCircleFill className={classes.dropRegistered} />
          </Button>
        );
      }
    }
    return icon;
  };

  const dropRegisteredSection = (catalogCode) => {
    const r = window.confirm("Do you want to drop this section?");
    if (r === true) {
      const payload = {
        inStuDeg: props.studentdegree,
        inCourseCode: catalogCode,
        inUsername: props.username,
      };
      axios
        .delete("DeleteRegisteredSectionFromTheCart", {
          headers: { Authorization: GetToken("token") },
          data: payload,
        })
        .then((response) => {
          if (response.data === true) {
            updateCartHandler(props.studentdegree, props.usertype);
            props.setupcoursecart({
              courseCode: catalogCode,
              status: "Dropped",
            });
          } else {
            alert("An error occured!");
          }
        });
    }
  };

  const dropSectionAddedToTheCart = (catalogCode, courseOffered) => {
    const r = window.confirm(
      "Do you want to delete this section from the cart?"
    );
    if (r === true) {
      let payload = {
        inStuDeg: props.studentdegree,
        inCourseOfferedCode: courseOffered,
        inCourseCode: catalogCode,
      };

      axios
        .delete("DeleteSectionFromTheCart", {
          headers: { Authorization: GetToken("token") },
          data: payload,
        })
        .then((response) => {
          if (response.status === 200) {
            updateCartHandler(props.studentdegree, props.usertype);
            props.setupcoursecart({
              courseCode: catalogCode,
              status: "",
            });
          } else {
            alert("An error occured!");
          }
        });
    }
  };

  const undoADroppedSection = (catalogCode) => {
    const r = window.confirm("Do you want to undo this section to the cart?");
    if (r === true) {
      const payload = {
        inStuDeg: props.studentdegree,
        inCourseCode: catalogCode,
      };
      axios
        .post("undoADroppedSection", payload, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          if (response.data === true) {
            updateCartHandler(props.studentdegree, props.usertype);
            props.setupcoursecart({
              courseCode: catalogCode,
              status: "Registered",
            });
          } else {
            alert("An error occured!");
          }
        });
    }
  };

  const onApproveRejectCheck = (event) => {
    const courseOfferedCode = event.target.name;
    //const checkValue = document.querySelector('input[name = "'+courseOfferedCode+'"]:checked');
    const radioButtonCheck = document.getElementsByName(courseOfferedCode);
    let approveValue = false;
    if (radioButtonCheck[0].checked) {
      approveValue = true;
    } else if (radioButtonCheck[1].checked) {
      approveValue = false;
    }

    let rejectReason = document.querySelector(
      'input[name = "reason' + courseOfferedCode + '"]'
    );
    if (approveValue === true || rejectReason === null || rejectReason === "") {
      rejectReason = null;
    } else {
      rejectReason = rejectReason.value;
    }
    const payload = {
      inStuDeg: props.studentdegree,
      inOfferdCourseCode: courseOfferedCode,
      inUsername: props.username,
      inApprove: approveValue,
      inRejectReason: rejectReason,
    };
    axios
      .post("ApproveReject", payload, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        if (response.data === true) {
          updateCartHandler(props.studentdegree, props.usertype);
        } else {
          alert("An error occured!");
        }
      });
  };

  const AddRejectReason = (courseOfferedCode) => {
    const rejectReason = document.querySelector(
      'input[name = "reason' + courseOfferedCode + '"]'
    ).value;
    const payload = {
      inStuDeg: props.studentdegree,
      inOfferdCourseCode: courseOfferedCode,
      inUsername: props.username,
      inApprove: false,
      inRejectReason: rejectReason,
    };
    axios
      .post("ApproveReject", payload, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        if (response.data === true) {
          updateCartHandler(props.studentdegree, props.usertype);
        } else {
          alert("An error occured!");
        }
      });
  };

  return (
    <React.Fragment>
      {showCart ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="customized table"
              padding="none"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Course</StyledTableCell>
                  <StyledTableCell className={classes.columnTable}>
                    Course title
                  </StyledTableCell>
                  <StyledTableCell>Credits</StyledTableCell>
                  <StyledTableCell>Time</StyledTableCell>
                  <StyledTableCell>Teacher</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  {props.allowtoreg !== 0 && props.allowtoreg !== 14 ? (
                    <StyledTableCell>Drop</StyledTableCell>
                  ) : null}
                  <StyledTableCell>Notes</StyledTableCell>
                  {props.usertype !== "0" && props.allowtoreg === 1 ? (
                    <StyledTableCell>Approve/Reject</StyledTableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data).map((index) => {
                  let style = null;
                  if (selected === data[index].catalogCode) {
                    style = {
                      backgroundColor: "#FFFACD",
                    };
                  }
                  return (
                    <StyledTableRow
                      key={data[index].courseOffered}
                      hover={true}
                      style={style}
                      selected={
                        selected === data[index].catalogCode ? true : false
                      }
                    >
                      {props.allowtoreg !== 0 &&
                      !(props.usertype === "0" && data[index].status === "4") &&
                      data[index].status !== "Freehour" ? (
                        <StyledTableCell
                          className={classes.link}
                          component="th"
                          scope="row"
                          onClick={() =>
                            changeSections(
                              data[index].catalogCode,
                              data[index].catalogKey
                            )
                          }
                        >
                          {data[index].conflict ? (
                            <span style={{ color: "red" }}>
                              {ReactHtmlParser(data[index].catalogKey)}{" "}
                              {data[index].section}
                            </span>
                          ) : (
                            <span>
                              {ReactHtmlParser(data[index].catalogKey)}{" "}
                              {data[index].section}
                            </span>
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell>
                          {data[index].conflict ? (
                            <span style={{ color: "red" }}>
                              {ReactHtmlParser(data[index].catalogKey)}{" "}
                              {data[index].section}
                            </span>
                          ) : (
                            <span>
                              {ReactHtmlParser(data[index].catalogKey)}{" "}
                              {data[index].section}
                            </span>
                          )}
                        </StyledTableCell>
                      )}

                      {props.allowtoreg !== 0 &&
                      !(props.usertype === "0" && data[index].status === "4") &&
                      data[index].status !== "Freehour" ? (
                        <StyledTableCell
                          className={[classes.columnTable, classes.link]}
                          onClick={() =>
                            changeSections(
                              data[index].catalogCode,
                              data[index].catalogKey
                            )
                          }
                        >
                          {data[index].conflict ? (
                            <span style={{ color: "red" }}>
                              {ReactHtmlParser(data[index].title)}
                            </span>
                          ) : (
                            <span>{ReactHtmlParser(data[index].title)}</span>
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell className={classes.columnTable}>
                          {data[index].conflict ? (
                            <span style={{ color: "red" }}>
                              {ReactHtmlParser(data[index].title)}
                            </span>
                          ) : (
                            <span>{ReactHtmlParser(data[index].title)}</span>
                          )}
                        </StyledTableCell>
                      )}

                      <StyledTableCell>
                        {data[index].credits} 
                            {data[index].credits!==data[index].actualCredits?<strong> ({data[index].actualCredits} Cr.)</strong>:null}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data[index].conflict ? (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {ReactHtmlParser(data[index].sectionTime)}
                          </span>
                        ) : (
                          <span>
                            {ReactHtmlParser(data[index].sectionTime)}
                          </span>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{data[index].teacher}</StyledTableCell>
                      <StyledTableCell>
                        {props.allowtoreg === 14 && data[index].dropped ? (
                          <>
                            {dropIconOnCart(
                              data[index].status,
                              data[index].catalogCode,
                              data[index].courseOffered,
                              data[index].dropped,
                              data[index].courseType,
                              data[index].allowedToDrop
                            )}
                          </>
                        ) : (
                          <ParadigmIcon
                            status={data[index].status}
                          ></ParadigmIcon>
                        )}
                      </StyledTableCell>
                      {props.allowtoreg !== 0 && props.allowtoreg !== 14 ? (
                        <StyledTableCell>
                          {dropIconOnCart(
                            data[index].status,
                            data[index].catalogCode,
                            data[index].courseOffered,
                            data[index].dropped,
                            data[index].courseType,
                            data[index].allowedToDrop
                          )}
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell>{data[index].notes}</StyledTableCell>
                      {props.allowtoreg === 1 &&
                      props.usertype !== "0" &&
                      (data[index].status === "1" ||
                        data[index].status === "2" ||
                        data[index].status === "3" ||
                        data[index].status === "4") ? (
                        <StyledTableCell component="th" scope="row">
                          <Form>
                            {["radio"].map((type) => (
                              <div key={`inline-${type}`}>
                                <Form.Check
                                  inline
                                  label="Approve"
                                  name={data[index].courseOffered}
                                  type={type}
                                  id={`inline-${data[index].courseOffered}-1`}
                                  onChange={onApproveRejectCheck}
                                  defaultChecked={
                                    data[index].status === "2" ? true : false
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="Reject"
                                  name={data[index].courseOffered}
                                  type={type}
                                  id={`inline-${data[index].courseOffered}-2`}
                                  onChange={onApproveRejectCheck}
                                  defaultChecked={
                                    data[index].status === "4" ? true : false
                                  }
                                />
                                {data[index].status === "4" ? (
                                  <React.Fragment>
                                    <Form.Control
                                      id={`reason${data[index].courseOffered}`}
                                      name={`reason${data[index].courseOffered}`}
                                      defaultValue={data[index].notes}
                                      type="text"
                                      placeholder="Reject reason"
                                      style={{ width: "90%" }}
                                    />
                                    <Button
                                      onClick={() =>
                                        AddRejectReason(
                                          data[index].courseOffered
                                        )
                                      }
                                      variant="contained"
                                      color="primary"
                                    >
                                      Add reason
                                    </Button>
                                  </React.Fragment>
                                ) : null}
                              </div>
                            ))}
                          </Form>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          component="th"
                          scope="row"
                        ></StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper>
            <Typography align="right" style={{ padding: "4px 3%" }}>
              <b>Total credits:</b> {actualCredits}
            </Typography>
          </Paper>
          <PopupComponent
            show={modalShow}
            header={ReactHtmlParser(selectedCatalogkey)}
            onHide={() => setModalShow(false)}
            size="l"
          >
            <ListOfSections
              coursecode={selected}
              actualCredits={actualCredits}
              updateCartHandler={updateCartHandler}
              buttonTitle="Change section"
            />
          </PopupComponent>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setupcoursecart: (e) => dispatch(actionCreators.setcoursecart(e)),
  };
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    updatecart: state.ucart.updatecart,
    username: state.uname.username,
    usertype: state.utype.usertype,
    allowtoreg: state.allowreg.allowtoreg,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartCourses);
