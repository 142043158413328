function addCartStatus(status) {
    if(status==="Completed" || status==="Success"){
        alert("The course has been added!");
        return true;
    }
    else if(status==="NoAllowedToRegister"){
        alert("You can't register this course!");
    }
    else if(status==="Exist"){
        alert("The course has already added!");
    }
    else if(status==="RegisteredThisSemester"){
        alert("The course is already registered this semester, you can't add it!");
    }
    else if(status==="Closed"){
        alert("The course is full!");
    }
    else if(status==="PreRequisite"){
        alert("You have not completed the prerequisite!");
    }
    else if(status==="Overload"){
        alert("You are cart is full, you can't add more courses!");
    }
    else if(status==="IGrade"){
        alert("You can't register a course with I Grade!");
    }
    else if(status==="Succ"){
        alert("The course has already successed!");
    }
    else if(status==="OutOfPOS"){
        alert("Added but it will be calculated as an elective course, it is out of the program of study!");
         return true;
    }
    return false;
}

export default addCartStatus;  