import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "../../axios/BUAP/registration";
import axioscart from "../../axios/BUAP/cart";
import GetToken from "../../Shared/GetToken";
import { connect } from "react-redux";
import PopupComponent from "./PopupComponent";
import ListOfSections from "./Cart/CartCourses/ListOfSections";

const ListOfOfferedSections = (props) => {
  const [data, setData] = useState({
    courseCatalogCode: 0,
    catalogKey: "",
    title: -1,
    credits: -1,
    relatedTo: "",
    offeredIn: "",
    prerequisite: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [courseCode, setCcourseCode] = useState(false);
  const [courseCatalog, setCourseCatalog] = useState(false);
  const [actualCredits, setActualCredits] = useState(0);

  const handleClick = (courseCode, courseCatalog) => {
    setCcourseCode(courseCode);
    setCourseCatalog(courseCatalog);
    setModalShow(true);
  };

  useEffect(() => {
    axios

      .get("ListOfOfferedCourses/", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        const data = {
          columns: [
            {
              label: "catalogKey",
              field: "catalogKey",
              sort: "asc",
              width: 150,
            },
            {
              label: "Title",
              field: "title",
              sort: "asc",
              width: 270,
            },
            {
              label: "Credits",
              field: "credits",
              sort: "asc",
              width: 150,
            },
          ],
          rows: [],
        };
        const rdata = response.data;
        let rowsData = [];
        for (let i = 0; i < rdata.length; i++) {
          rowsData.push({
            catalogKey: rdata[i].catalogKey,
            title: rdata[i].title,
            credits: rdata[i].credits,
            clickEvent: () =>
              handleClick(rdata[i].courseCatalogCode, rdata[i].catalogKey),
          });
        }

        data.rows = rowsData;

        setData(data);

        axioscart
          .get("StudentCart/" + props.studentdegree + "/" + props.usertype, {
            headers: { Authorization: GetToken("token") },
          })
          .then((response) => {
            //Update to Get the actual total credits only
            setActualCredits(response.data.cartFrontEnd.actualTotalCredits);
          });
      });
  }, [props.studentdegree, props.usertype]);

  return (
    <div>
      <MDBDataTable striped bordered small data={data} />
      <PopupComponent
        show={modalShow}
        header={courseCatalog}
        onHide={() => setModalShow(false)}
        size="l"
      >
        <ListOfSections
          coursecode={courseCode}
          actualCredits={actualCredits}
          buttonTitle="Add section"
        />
      </PopupComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    usertype: state.utype.usertype,
  };
};

export default connect(mapStateToProps)(ListOfOfferedSections);
