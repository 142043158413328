import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState = {
    name: '',
};

const nameReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETNAME:
            return {
                ...state,
                name: action.value
            }
        default:
            return state;
    }
}

export default nameReducer;