import React from 'react';

const TrackSlot = (props) => {
    return (
        <React.Fragment>
            <span className="track-slot" aria-hidden="true" style={{ gridRow: 'Mon', gridColumn: 'tracks' }}>Mon</span>
            <span className="track-slot" aria-hidden="true" style={{ gridRow: 'Tue', gridColumn: 'tracks' }}>Tue</span>
            <span className="track-slot" aria-hidden="true" style={{ gridRow: 'Wed', gridColumn: 'tracks' }}>Wed</span>
            <span className="track-slot" aria-hidden="true" style={{ gridRow: 'Thr', gridColumn: 'tracks' }}>Thr</span>
            <span className="track-slot" aria-hidden="true" style={{ gridRow: 'Fri', gridColumn: 'tracks' }}>Fri</span>
            <span className="track-slot" aria-hidden="true" style={{ gridRow: 'Sat', gridColumn: 'tracks' }}>Sat</span>
        </React.Fragment>
    )
}

export default TrackSlot; 