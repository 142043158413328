import React, { useEffect, useState } from "react";
import classes from "./GoogleMeetLink.module.css";
import teacher from "../../../../axios/BUAP/teacher";
import GetToken from "../../../../Shared/GetToken";

const GoogleMeetLinks = (props) => {
  const [courses, setcourses] = useState([]);
  const [data, setdata] = useState([]);
  const [filledform, setfilledform] = useState(false);
  const [numberOfIgnoredCourses, setnumberOfIgnoredCourses] = useState(0);

  useEffect(() => {
    let filled = false;
    teacher
      .get("FilledGoogleMeetLinks/" + props.teacherCode, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        //Not filled out the google meet links
        const filledCode = Number(response.data);
        if (filledCode === 1) {
          filled = true;
          setfilledform(true);
        }
      });

    const url = "ListOfCourses/" + props.teacherCode;
    teacher
      .get(url, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setcourses(response.data);
        if (filled) {
          const ign = response.data.filter((ele) => ele.googleMeet === "");
          setnumberOfIgnoredCourses(ign.length);
          response.data.forEach((e) =>
            updateArrValue(
              e.codes[0],
              e.googleMeet === ""
                ? ""
                : `https://meet.google.com/${e.googleMeet}`
            )
          );
        } else {
          setnumberOfIgnoredCourses(0);
        }
      });
  }, [props.teacherCode]);

  const inputHandler = (e) => {
    const courseOfferedId = e.target.getAttribute("name").replace("txt", "");
    const courseOfferedValue = e.target.value;
    updateArrValue(courseOfferedId, courseOfferedValue);
  };

  const updateArrValue = (id, value) => {
    id = Number(id);
    const arr = data;
    const index = arr.findIndex((c) => c.id === id);
    if (index !== -1) {
      //Delete if the new text is  empty
      if (value === "") {
        deleteCourse(index, id);
      }
      //Update it if it is found
      else {
        arr[index].value = value;
        crossListCourses(id, value, "update");
      }
    }
    //Create
    else {
      arr.push({
        id: id,
        value: value,
      });
      crossListCourses(id, value, "add");
    }
    setdata(arr);
  };

  const deleteCourse = (index, id) => {
    if (index !== -1) {
      const arr = data;
      arr.splice(index, 1);
      crossListCourses(id, -1, "delete");
      setdata(arr);
    }
  };

  const crossListCourses = (id, value, type) => {
    id = Number(id);
    const arr = data;
    const courseArr = courses.filter((c) => c.codes[0] === id)[0];
    if (courseArr === undefined) {
      return;
    }
    const course = courseArr.codes;
    course.forEach((elementId) => {
      if (elementId !== Number(id)) {
        if (type === "add") {
          arr.push({
            id: String(elementId),
            value: value,
          });
        } else if (type === "update") {
          const index = arr.findIndex((c) => c.id === String(elementId));
          arr[index].value = value;
        } else if (type === "delete") {
          const index = arr.findIndex((c) => c.id === String(elementId));
          arr.splice(index, 1);
        }
      }
    });
    setdata(arr);
  };

  const checkboxHandler = (e) => {
    const id = Number(e.target.getAttribute("name").replace("chx", ""));
    let courseLength = 1;

    const checked = e.target.checked;
    courses.forEach((c) => {
      if (c.codes[0] === Number(id)) {
        courseLength = c.codes.length;
      }
    });

    let element = document.getElementById(`txt${id}`);
    const count = numberOfIgnoredCourses;
    const index = data.findIndex((c) => c.id === id);

    if (checked) {
      //Disable and empty the text
      element.disabled = true;
      element.value = "The course has no online meeting";
      //Remove the course from the arr if exsits
      deleteCourse(index, id);
      setnumberOfIgnoredCourses(count + courseLength);
    } else {
      //Disable and empty the text
      element.disabled = false;
      element.value = "";
      setnumberOfIgnoredCourses(
        count - courseLength < 0 ? 0 : count - courseLength
      );
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //Number of items are required to be filled
    if (!filledform) {
      let requiredItems = 0;
      courses.forEach((c) => {
        requiredItems += c.codes.length;
      });
      requiredItems -= numberOfIgnoredCourses;

      if (requiredItems !== data.length) {
        alert("You should fill out all the courses");
        return;
      }
    }

    //Check if there is duplicate in the link for two courses
    let found = false;
    data.forEach((element1) => {
      if (!found) {
        data.forEach((element2) => {
          const course1 = courses.find(
            (e) => Number(e.codes[0]) === Number(element1.id)
          );
          const course2 = courses.find(
            (e) => Number(e.codes[0]) === Number(element2.id)
          );
          if (
            Number(element1.id) !== Number(element2.id) &&
            element1.value === element2.value &&
            course1 !== null &&
            course1 !== undefined &&
            course2 !== undefined
          ) {
            found = true;
            alert(
              `You can't using the same link for both ${course1.catalogKey.join(
                ","
              )} and ${course2.catalogKey.join(",")}`
            );
          }
        });
      }
    });

    if (found) {
      return;
    }
    //Check if all links are validate
    const updatedData = [];
    let valid = true;
    data.forEach((element) => {
      const updatedLink = extractGoogleMeetLink(element.value);
      if (updatedLink === null && element.value !== "") {
        const course = courses.find((e) => e.codes[0] === Number(element.id));
        alert(`Invalid link of ${course.catalogKey}`);
        valid = false;
      }
      updatedData.push({
        id: element.id,
        value: updatedLink,
      });
    });

    //send them to the database and wait response
    if (valid) {
      if (!filledform) {
        teacher
          .post("GoogleMeetLinks/" + props.teacherCode, updatedData, {
            headers: { Authorization: GetToken("token") },
          })
          .then((response) => {
            if (response.status === 200) {
              props.submittedHandler(true);
              if (props.updateData !== undefined) {
                props.updateData();
              }
            }
          });
      } else {
        teacher
          .put("GoogleMeetLinks/" + props.teacherCode, updatedData, {
            headers: { Authorization: GetToken("token") },
          })
          .then((response) => {
            if (response.status === 200) {
              props.submittedHandler(true);
            }
          });
        alert("Updated");
      }
    }
  };

  const extractGoogleMeetLink = (inLink) => {
    inLink = inLink.replace(/\?.*/, "");
    const regexp = /meet.google.com?/g;
    const array = [...inLink.matchAll(regexp)];
    if (array.length > 1) {
      return null;
    }

    const rxGoogleMeet = /(https:\/\/)meet.google.com\/(.*)$/;
    // const rxGoogleMeet =
    //   /(https:\/\/)?meet.google.com\/[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-([a-zA-Z0-9]){3}$/;
    const link = rxGoogleMeet.exec(inLink);
    if (link === null) {
      return null;
    } else {
      return link[2];
    }
  };

  return (
    <div>
      <form>
        <div className={classes.course}>
          {courses.map((course) => (
            <>
              <span className={classes.courseTitle}>
                {course.catalogKey.join(" and ")}
              </span>
              {filledform && props.teacherCode === -1 ? (
                course.googleMeet !== "" ? (
                  <a
                    href={"https://meet.google.com/" + course.googleMeet}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {course.googleMeet}
                  </a>
                ) : (
                  <span>
                    This course is not given online and does not have a Google
                    Meet link
                  </span>
                )
              ) : (
                <div className={classes.googleLink}>
                  <input
                    type="text"
                    id={`txt${course.codes[0]}`}
                    name={`txt${course.codes[0]}`}
                    placeholder={
                      filledform
                        ? "The course has no online meeting"
                        : "Google Meet link"
                    }
                    onChange={inputHandler}
                    defaultValue={
                      filledform &&
                      props.teacherCode !== -1 &&
                      course.googleMeet !== ""
                        ? "https://meet.google.com/" + course.googleMeet
                        : ""
                    }
                    disabled={filledform && course.googleMeet === ""}
                    required
                  />
                  <span>
                    <input
                      type="checkbox"
                      id={`chx${course.codes[0]}`}
                      name={`chx${course.codes[0]}`}
                      onChange={checkboxHandler}
                      defaultChecked={filledform && course.googleMeet === ""}
                    />
                    <label>
                      This course is not given online and does not have a Google
                      Meet link
                    </label>
                  </span>
                </div>
              )}
            </>
          ))}
        </div>
        <div>
          {filledform && props.teacherCode === -1 ? null : (
            <div className={classes.btns}>
              <input
                className={classes.input}
                type="submit"
                value={props.teacherCode === -1 ? "Submit" : "Save"}
                onClick={submitHandler}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
export default GoogleMeetLinks;
