import React, { Component } from "react";
import Modal from "../../components/UI/Modal/Modal";
import axios from "axios";

const withErrorHandler = (WrappedComponent) => {
  return class extends Component {
    state = {
      error: null,
    };

    componentDidMount() {
      axios.interceptors.request.use((req) => {
        this.setState({ error: null });
        return req;
      });
      axios.interceptors.response.use(
        (res) => res,
        (error) => {
          this.setState({ error: error });
        }
      );
    }

    render() {
      return (
        <React.Fragment>
          {this.state.error ? (
            <Modal>Error in BUAP.</Modal>
          ) : (
            <WrappedComponent {...this.props} />
          )}
        </React.Fragment>
      );
    }
  };
};

export default withErrorHandler;
