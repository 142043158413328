import axios from "axios";
import * as baseURL from "./baseurl";

const instance = axios.create({
  baseURL: baseURL.BASEURL + "/api/BUAP/Cart/",
});
instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    window.location.href = "/";
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 405) {
      window.location.href = "/";
    }
  }
);

export default instance;
