import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  header: {
    fontSize: 25,
  },
  [theme.breakpoints.down("xs")]: {
    title: {
      fontSize: 10,
    },
    header: {
      fontSize: 15,
    },
  },
}));

const styles = {
  "Applied for graduation": {
    color: "#296400",
  },
  Completed: {
    color: "#296400",
  },
  "Eligible/Submitted": {
    color: "#296530",
  },
  "Incomplete registration": {
    color: "#ff0000",
  },
  "Should PreRegister": {
    color: "#6a71c6",
  },
  "Eligible/Incomplete registration": {
    color: "#ff0000",
  },
  "Needs Attention": {
    color: "#fce300",
  },
  "In Process": {
    color: "#dc66c6",
  },
  Eligible: {
    color: "#296530",
  },
  Withdraw: {
    color: "#030169",
  },
  "Not Eligible": {
    color: "#FF0000",
  },
};

const CartHeader = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined" square>
      <CardContent>
        <Typography className={classes.header} title="حالة الطالب">
          Student status:
          <span style={{ ...styles[String(props.status)], fontWeight: "bold" }}>
            {" "}
            {props.status}
          </span>
        </Typography>
        {props.allowtoreg ? (
          <Typography className={classes.title}>
            <b title="عدد الساعات المسموحة لتسجيل">Registration Load: </b>(
            {props.minLoad} to {props.maxLoad})
          </Typography>
        ) : null}
        <Typography className={classes.title}>
          <span
            style={{
              color: "red",
              fontWeight: "bold",
              backgroundColor: "white",
            }}
          >
            {props.underProbation}
          </span>
        </Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    allowtoreg: state.allowreg.allowtoreg,
  };
};

export default connect(mapStateToProps)(CartHeader);
