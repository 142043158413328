import React, { useEffect, useState } from "react";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { connect } from "react-redux";
import ViewStudent from "../../Superuser/Student/Viewstudent";

const ViewStudentRoute = (props) => {
  const [stuno, setStuno] = useState(null);
  const [username, setUsername] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    async function updateData() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const stuno = params.get("stuno");
      const studeg = params.get("studeg");
      await props.setstudentdegree(studeg);
      await props.setstuno(stuno);
      if (props.usertype === "0" || props.usertype === 0) {
        await props.setusertype(0);
      } else {
        const usertype = localStorage.getItem("usertype");
        await props.setusertype(usertype);
      }
      const username = localStorage.getItem("username");
      const facCode = localStorage.getItem("facCode");
      //await setView(<ViewStudent stuno={stuno} username={username}></ViewStudent>);
      await props.setusername(username);
      await props.setiduser(facCode);
      await setStuno(stuno);
      await setUsername(username);
      setShow(true);
    }
    updateData();
  }, [props]);
  return (
    <React.Fragment>
      {show ? (
        <ViewStudent stuno={stuno} username={username}></ViewStudent>
      ) : null}
    </React.Fragment>
  );
};

//Redux
const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    usertype: state.utype.usertype,
  };
};

//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setusertype: (e) => dispatch(actionCreators.setusertype(e)),
    setusername: (e) => dispatch(actionCreators.setusername(e)),
    setstudentdegree: (e) => dispatch(actionCreators.setstudentdegree(e)),
    setstuno: (e) => dispatch(actionCreators.setstudentno(e)),
    setiduser: (e) => dispatch(actionCreators.setiduser(e)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewStudentRoute);
