import React from 'react';
import axios from '../../../../axios/BUAP/students';
import GetToken from '../../../../Shared/GetToken';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import SpinnerBootstrap from '../../../../components/UI/Spinner/Spinner';

class StudentPic extends React.Component {
    state = {
        studentPicSRC: null,
        waitData: true
    }
    componentDidMount() {
        axios.get('GetStudentPic/' + this.props.stuNo, {
            headers: { 'Authorization': GetToken("token") }
        })
            .then(response => {
                this.setState({ studentPic: 'data:image/jpeg;base64,' + response.data, waitData: false });

            });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.waitData ? <SpinnerBootstrap /> :
                    <div>
                        <CardMedia
                            className={this.props.className}
                            image={this.state.studentPic}
                            component='img' src={this.state.studentPic}
                        />

                    </div>}
            </React.Fragment>
        )
    }
}

StudentPic.propTypes = {
    stuNo: PropTypes.string.isRequired,
};

export default StudentPic;
