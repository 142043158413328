import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState={
    username: '',
};

const usernameReducer=(state=inititalState,action)=>{
    switch(action.type){
        case actionTypes.SETUSERNAME:
            return {
                ...state,
                username:action.value
            }
        default: 
            return state;
    }
}

export default usernameReducer;