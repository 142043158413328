import Table from "react-bootstrap/Table";
import classes from "./grouped.module.css";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

function BUGroupedDataOne(props) {
  const [color, setColor] = useState("primary");
  useEffect(() => {
    if (props.semester === "First Year") {
      setColor("primary");
    } else if (props.semester === "Second Year") {
      setColor("success");
    } else if (props.semester === "Third Year") {
      setColor("info");
    } else if (props.semester === "Fourth Year") {
      setColor("warning");
    } else {
      setColor("dark");
    }
  }, [props.level, props.semester]);

  return (
    <div>
      <Alert variant={color}>
        {props.semester} / {props.level}
      </Alert>
      <Table striped bordered hover size="sm" className={classes.grouptable}>
        <thead>
          <tr>
            {props.columns.map((column) => (
              <React.Fragment>
                {column.name === "title" ? (
                  <th
                    className={classes.title}
                    style={{ textAlign: column.aligmentstyle }}
                  >
                    {column.title}
                  </th>
                ) : (
                  <th style={{ textAlign: column.aligmentstyle }}>
                    {column.title}
                  </th>
                )}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((row) => (
            <tr>
              <th style={{ width: "5%" }}>{row.status}</th>
              <th
                style={{
                  cursor: "pointer",
                  color: "#2a67a8",
                  fontWeight: "bold",
                  width: "12%",
                }}
                onClick={() =>
                  props.showCourseInParadimg(
                    row.groupType,
                    row.courseCode,
                    row.catalogKey,
                    row.courseType
                  )
                }
              >
                {row.catalogKey}
              </th>

              <th className={classes.title} style={{ width: "50%" }}>
                {row.title}
                <br />
                {row.prerequisite && props.prerequisite ? (
                  <span>
                    <b>Prerequisite</b>: {row.prerequisite}
                    <br />
                  </span>
                ) : null}
                {row.offeredIn && props.offeredIn ? (
                  <span>
                    <b>Last Offered in:</b> {row.offeredIn}
                    <br />
                  </span>
                ) : null}
                {row.relatedTo && props.relatedTo ? (
                  <span>
                    <b>Related to:</b> {row.relatedTo}
                    <br />
                  </span>
                ) : null}
              </th>
              <th>{row.courseType}</th>
              <th style={{ width: "3%", textAlign: "center" }}>
                {row.credits}
              </th>
              {props.add ? (
                <th style={{ width: "5%", textAlign: "center" }}>{row.add}</th>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default BUGroupedDataOne;
