import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";
import axios from "../../../../axios/BUAP/common";
import GetToken from "../../../../Shared/GetToken";
import { Link as RouterLink } from "react-router-dom";
import axiosStudents from "../../../../axios/BUAP/students";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: "black",
    backgroundColor: "#eeeeee",
  },

  adv: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: "left",
    color: "#191970",
    backgroundColor: "#F8F8FF",
    "&:hover, &:focus": {
      backgroundColor: "blue",
      color: "white",
      fontWeight: "none",
      textDecoration: "none",
    },
  },

  advhighlight: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: "left",
    color: "white",
    backgroundColor: "#DC143C",
    fontWeight: "bold",
    "&:hover, &:focus": {
      backgroundColor: "orange",
      color: "white",
      fontWeight: "none",
      textDecoration: "none",
    },
  },
  advLink: {
    textDecoration: "none",
    color: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  welcomeMsg: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    textAlign: "center",
    color: "rgba(0,0,0,0.7)",
    backgroundColor: "#ffdd57",
    fontWeight: "bold",
    fontSize: "24px",
    letterSpacing: "-0.8px",
  },
}));

const Advertisements = (props) => {
  const classes = useStyles();

  const [advList, setadvList] = useState(false);
  const [socialsurvey, setSocialsurvey] = useState(false);

  useEffect(() => {
    let username = 0;
    let rank = 0;
    if (
      props.stuno !== "-1" &&
      props.stuno !== -1 &&
      props.stuno !== undefined
    ) {
      username = props.stuno;
      rank = 0;
    } else {
      username = props.username;
      rank = props.usertype;
    }
    axios
      .get("AdvertisementsList/" + rank + "/" + username, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setadvList(response.data);
      });
    if (props.usertype === "0") {
      axiosStudents
        .get("IsSocialSurveyOpen/" + username, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setSocialsurvey(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} className={classes.welcomeMsg}>
          Welcome to the Academic portal (BUAP) / Beta version
        </Grid>
        <Grid item xs={12} xl={4}>
          <Typography className={classes.paper}>
            <b title="المكتب المالي">Finance</b>
          </Typography>
          {props.usertype === "0" && socialsurvey ? (
            <RouterLink className={classes.advLink} to="/socsur">
              <Typography
                className={classes.adv}
                style={{
                  backgroundColor: "red",
                  textAlign: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                لتعبئة نموذج المسح الإجتماعي الرجاء الضغط هنا
              </Typography>
            </RouterLink>
          ) : null}

          {Object.keys(advList).map((key, index) => {
            if (advList[key].groupType === 1) {
              return (
                <Link
                  id={advList[key].iId}
                  className={classes.advLink}
                  href={advList[index].cAttachfile}
                  target="_blank"
                  key={key}
                >
                  <Typography
                    key={key}
                    className={classes.adv}
                    style={{ backgroundColor: advList[key].iColor }}
                  >
                    {advList[key].cCaption}
                  </Typography>
                </Link>
              );
            } else {
              return null;
            }
          })}
        </Grid>
        <Grid item xs={12} xl={4}>
          <Typography className={classes.paper}>
            <b title="المكتب الاكاديمي">Academic</b>
          </Typography>
          {/* */}
          {false &&
          props.showgoogleformlink != null &&
          props.showgoogleformlink ? (
            <Link
              className={classes.advLink}
              onClick={() => props.showgoogleformhandler(true)}
              style={{
                color: "#3f51b5",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <Typography key={-1} className={classes.adv}>
                Google meet links form
              </Typography>
            </Link>
          ) : null}
          {Object.keys(advList).map((key, index) => {
            if (advList[key].groupType === 2) {
              return (
                <Link
                  id={advList[key].iId}
                  className={classes.advLink}
                  href={advList[index].cAttachfile}
                  target="_blank"
                  key={key}
                >
                  <Typography
                    key={key}
                    className={classes.adv}
                    style={{ backgroundColor: advList[key].iColor }}
                  >
                    {advList[key].cCaption}
                  </Typography>
                </Link>
              );
            } else {
              return null;
            }
          })}
        </Grid>
        <Grid item xs={12} xl={4}>
          <Typography className={classes.paper}>
            <b title="اعلانات عامة">General</b>
          </Typography>
          {Object.keys(advList).map((key, index) => {
            if (advList[key].groupType === 3) {
              return (
                <Link
                  id={advList[key].iId}
                  className={classes.advLink}
                  href={advList[index].cAttachfile}
                  target="_blank"
                  key={key}
                >
                  <Typography
                    className={classes.adv}
                    style={{ backgroundColor: advList[key].iColor }}
                    key={key}
                  >
                    {advList[key].cCaption}
                  </Typography>
                </Link>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    usertype: state.utype.usertype,
    stuno: state.stuno.studentno,
  };
};
export default connect(mapStateToProps)(Advertisements);
