import React, { Component } from "react";
import "./ScheduleComponent.css";
import ScheduleItem from "./ScheduleItem/ScheduleItem";
import axios from "../../axios/BUAP/shared";
import { connect } from "react-redux";
import GetToken from "../../Shared/GetToken";
import SpinnerBootstrap from "../../components/UI/Spinner/Spinner";
import ScheduleView from "./ScheduleView";
import PrintButton from "../Shared/PrintButton/PrintButton";
import Blocked from "../../containers/BUAP/Student/Blocked";

const listOfColors = [
  "#DA4453",
  "#F6BB42",
  "#8CC152",
  "#37BC9B",
  "#3BAFDA",
  "#4A89DC",
  "#967ADC",
  "#D770AD",
  "#AAB2BD",
  "#434A54",
];

let count = 1;
let courseColor = "";
class Schedule extends Component {
  state = {
    scheduleList: [
      {
        courseDetailsKey: "",
        slotTime: "",
        viewTime: "",
        catalogKey: "",
        roomKey: "",
        teacherName: "",
        dayAbbs: "",
        conflictNote: "",
        daycode: 0,
      },
    ],
    max: 0,
    waitData: true,
    width: 0,
  };

  componentDidMount() {
    this.getSchedule();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sem !== this.props.sem ||
      prevProps.facCode !== this.props.facCode
    ) {
      this.getSchedule();
    }
  }

  getSchedule() {
    count = 1;
    if (typeof this.props.facCode === "undefined") {
      //Students
      axios
        .get(
          "Schedule/ScheduleMainInfo/" +
            this.props.studentdegree +
            "/" +
            this.props.sem,
          {
            headers: { Authorization: GetToken("token") },
          }
        )
        .then((response) => {
          this.updateData(response.data.data);
        })
        .catch(function (error) {
          this.setState({ waitData: false });
        });
    } else {
      //Teacher
      axios
        .get(
          "Schedule/ScheduleTeacher/" +
            this.props.facCode +
            "/" +
            this.props.sem,
          {
            headers: { Authorization: GetToken("token") },
          }
        )
        .then((response) => {
          this.updateData(response.data);
        })
        .catch(function (error) {
          this.setState({ waitData: false });
        });
    }
  }

  colorItemsHandle = (catalogKey) => {
    if (catalogKey !== courseColor) {
      courseColor = catalogKey;
      count++;
    }
    return listOfColors[count];
  };

  updateData = (data) => {
    let max = 0;
    for (const i of data) {
      const val = i.slotTime.split("/");
      if (val[1] - val[0] > max) {
        max = val[1] - val[0];
      }
    }
    this.setState({
      scheduleList: data,
      waitData: false,
      max: max,
    });
  };

  render() {
    const listItems = this.state.scheduleList.map((course) => (
      <ScheduleItem
        key={course.courseDetailsKey}
        day={course.dayAbbs}
        value={course.slotTime}
        title={course.catalogKey}
        room={course.roomKey}
        time={course.viewTime}
        teacher={course.teacherName}
        bgcolor={this.colorItemsHandle(course.catalogKey)}
        conflictNote={course.conflictNote}
        max={this.state.max}
      />
    ));

    return (
      <React.Fragment>
        {this.state.blocked ? (
          <Blocked />
        ) : this.state.waitData ? (
          <SpinnerBootstrap />
        ) : (
          <>
            <ScheduleView
              listItems={listItems}
              scheduleList={this.state.scheduleList}
              listOfColors={listOfColors}
            ></ScheduleView>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
  };
};

export default connect(mapStateToProps)(Schedule);
