import React, { useEffect, useRef, useState } from "react";
import TranscriptTerm from "../TranscriptTerm/TranscriptTerm";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import axios from "../../../../../axios/BUAP/students";
import GetToken from "../../../../../Shared/GetToken";
import Blocked from "../../../../../containers/BUAP/Student/Blocked";
import SpinnerBootstrap from "../../../../UI/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minheight: 230,
  },
  media: {
    height: 200,
  },
  paperItem: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
}));

const Transcripts = (props) => {
  const [terms, setTerms] = useState([]);
  const [AllowedToShow, setAllowedToShow] = useState(true);
  const [waitData, setWaitData] = useState(true);

  useEffect(() => {
    axios
      .get("TranscriptTerms/" + props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setTerms(response.data);
        setAllowedToShow(true);
        setWaitData(false);
      })
      .catch(function (error) {
        setWaitData(false);
        setTerms([]);
        setAllowedToShow(false);
      });
  }, [props.studentdegree, setAllowedToShow]);

  const classes = useStyles();
  return (
    <React.Fragment>
      {waitData ? (
        <SpinnerBootstrap />
      ) : (
        <React.Fragment>
          {AllowedToShow ? (
            <div className={classes.root}>
              <Grid container spacing={1}>
                {Object.keys(terms).map((key, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <TranscriptTerm
                          crsAttem={terms[key].crsAttem}
                          crsCumEarned={terms[key].crsCumEarned}
                          crsEarned={terms[key].crsEarned}
                          gpaSemester={terms[key].gpaSemester}
                          gpaMajor={terms[key].gpaMajor}
                          gpaCumEarned={terms[key].gpaCumEarned}
                          majorText={terms[key].majorText}
                          minorText={terms[key].minorText}
                          message={terms[key].message}
                          term={terms[key].term}
                        />
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ) : (
            <Blocked></Blocked>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    stuno: state.stuno.studentno,
  };
};

export default connect(mapStateToProps)(Transcripts);
