import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import advisorAxios from "../../../../axios/BUAP/advisor";
import GetToken from "../../../../Shared/GetToken";
import { connect } from "react-redux";
//This component needs to review as it has a lot of changes
const CustomFilter = (props) => {
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState(-1);

  const [departmentsData, setDepartmentsData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(-1);
  const [chairpersonsData, setChairpersonsData] = useState([]);
  const [chairpersons, setChairpersons] = useState([]);
  const [chairperson, setChairperson] = useState(-1);
  const [terms, setTerms] = useState([]);
  const [term, setTerm] = useState(props.selectedterm);

  useEffect(() => {
    advisorAxios
      .get("FacuilitesForAdviseeList/" + props.username, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setFaculties(response.data);
        if (response.data.length === 1) {
          setFaculty(response.data[0].value);
          //props.faculty(response.data[0].value);
        }
      });
    advisorAxios
      .get("DepartmentsForAdviseeList/" + props.username, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setDepartments(response.data);
        setDepartmentsData(response.data);
        if (response.data.length === 1) {
          setDepartment(response.data[0].value);
          //props.departement(response.data[0].value);
        }
      });
    advisorAxios
      .get("ChairpersonsForAdviseeList/" + props.username, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setChairpersons(response.data);
        setChairpersonsData(response.data);

        if (response.data.length === 1) {
          setChairperson(response.data[0].value);
          //props.chairperson(response.data[0].value);
        }
      });
    advisorAxios
      .get("ListOfTerms/", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        let data = response.data.sort(
          (a, b) => parseFloat(a.value) - parseFloat(b.value)
        );
        data.reverse();
        setTerms(data);
        setTerm(data[3].value);
      });
  }, [props.username]);

  const changeFaculty = (facultyValue) => {
    setFaculty(facultyValue.value);
    setDepartment(-1);
    setChairperson(-1);
    props.faculty(facultyValue.value);
    props.departement(-1);
    props.chairperson(-1);

    const viewDepartments = departmentsData.filter(function (el) {
      return el.faculty === facultyValue.value || el.faculty === -1;
    });
    setDepartments(viewDepartments);

    const viewChairpersons = chairpersonsData.filter(function (el) {
      return el.faculty === facultyValue.value || el.faculty === -1;
    });
    setChairpersons(viewChairpersons);
  };

  const changeDepartment = (departmentValue) => {
    setDepartment(departmentValue.value);
    setChairperson(-1);
    const viewChairpersons = chairpersonsData.filter(function (el) {
      return (
        (el.faculty === departmentValue.faculty &&
          el.departement === departmentValue.value) ||
        el.faculty === -1
      );
    });
    setChairpersons(viewChairpersons);

    props.departement(departmentValue.value);
    props.chairperson(-1);
  };

  const changeChairpersons = (chairpersonValue) => {
    setChairperson(chairpersonValue.value);
    props.chairperson(chairpersonValue.value);
  };

  const changeTerm = (termValue) => {
    setTerm(termValue.value);
    props.setterm(termValue.value);
  };

  return (
    <Row style={{ margin: 0 }}>
      <Col style={{ zIndex: 1004 }} xs={12} md={2}>
        <Form.Label title="الفصل الدراسي">Term:</Form.Label>
        <Select
          defaultValue={-1}
          options={terms}
          onChange={changeTerm}
          value={terms.filter((option) => option.value === term)}
        />
      </Col>

      {faculties.length > 0 ? (
        <Col style={{ zIndex: 1003 }} xs={12} md={2}>
          <Form.Label title="الكلية">Faculty:</Form.Label>
          <Select
            defaultValue={-1}
            options={faculties}
            onChange={changeFaculty}
            value={faculties.filter((option) => option.value === faculty)}
            isDisabled={faculties.length === 1 ? true : false}
          />
        </Col>
      ) : null}
      {departments.length > 0 ? (
        <Col style={{ zIndex: 1002 }} xs={12} md={2}>
          <Form.Label title="القسم/البرنامج">Department/Program:</Form.Label>
          <Select
            defaultValue={-1}
            options={departments}
            onChange={changeDepartment}
            value={departments.filter((option) => option.value === department)}
            isDisabled={departments.length === 1 ? true : false}
          />
        </Col>
      ) : null}
      {chairpersons.length > 0 ? (
        <Col style={{ zIndex: 1001 }} xs={12} md={2}>
          <Form.Label title="منسق البرنامج">
            Chairperson/Coordinator:
          </Form.Label>
          <Select
            defaultValue={-1}
            options={chairpersons}
            onChange={changeChairpersons}
            value={chairpersons.filter(
              (option) => option.value === chairperson
            )}
            isDisabled={chairpersons.length === 1 ? true : false}
          />
        </Col>
      ) : null}

      <Col style={{ zIndex: 1000, paddingTop: "28px" }} xs={12} md={3}>
        <Button variant="primary" onClick={props.viewData} title="عرض البيانات">
          Show
        </Button>{" "}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    usertype: state.utype.usertype,
  };
};

export default connect(mapStateToProps)(CustomFilter);
