import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: "center",
        padding: 5,
        fontWeight: 'bold',
        fontSize: 20
    },
    [theme.breakpoints.down('xs')]: {
        header: {
            fontSize: 15
        }
    },
}));

const Header = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.header}>
            {props.header}
        </Typography>
    )
}

Header.propTypes = {
    header: PropTypes.string.isRequired,
};


export default Header;
