import React, { useEffect, useState } from "react";
import Advertisements from "../../../containers/BUAP/Homepage/Advertisements/Advertisements";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import axiosStudents from "../../../axios/BUAP/students";
import teacher from "../../../axios/BUAP/teacher";
import PopupComponent from "../../../containers/Registration/PopupComponent";

import GetToken from "../../../Shared/GetToken";
import ViewOldBuap from "../../../components/BUAP/ViewOldBuap";
import Blocked from "../Student/Blocked";
import GoogleMeetLinks from "../Teacher/CourseScedule/GoogleMeetLinks";

const HomePage = (props) => {
  const [block, setBlock] = useState(false);
  const [showGoogleLinkOnAdv, setshowGoogleLinkOnAdv] = useState(false);
  const [components, setComponents] = useState(null);
  const [modalgooglemeetShow, setmodalgooglemeetShow] = useState(false);
  const [teacherEvalationBlock, setteacherEvalationBlock] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onHide = () => {
    setmodalgooglemeetShow(false);
    setSubmitted(false);
  };

  useEffect(() => {
    //Check if the student blocked
    if (Number(props.usertype) === 0 && props.stuno !== -1) {
      axiosStudents
        .get("CheckBlockKind/" + props.stuno, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          if (response.data.teacherEvalation) {
            setteacherEvalationBlock(true);
          }

          if (
            response.data.teacherEvalation ||
            response.data.libraryBlock ||
            response.data.loanBlock ||
            response.data.registrationBlock
          ) {
            setBlock(true);
          } else if (response.data.financialBlock) {
            setBlock(true);
            setComponents(<ViewOldBuap page="fin" />);
          }
        });
    }
    //Check if the teacher should fill out the google meets
    // 
    else if (false &&
      Number(props.usertype) !== 0 //Not a student
    ) {
      teacher
        .get("FilledGoogleMeetLinks", {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          //Not filled out the google meet links
          const filledCode = Number(response.data);
          if (filledCode === 0) {
            setmodalgooglemeetShow(true);
          }
          if (filledCode === 0 || filledCode === 1) {
            setshowGoogleLinkOnAdv(true);
          }
        });
    }
  }, [props.usertype, props.stuno]);

  return (
    <>
      <Container maxWidth="xl">
        {teacherEvalationBlock ? (
          <ViewOldBuap page="tee" />
        ) : !block ? (
          <React.Fragment>
            <Advertisements
              showgoogleformlink={showGoogleLinkOnAdv}
              showgoogleformhandler={setmodalgooglemeetShow}
            />
            {props.usertype === "0" && props.stuno !== -1 ? (
              <ViewOldBuap page="tee" />
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Blocked />
          </React.Fragment>
        )}
      </Container>
      <PopupComponent
        show={modalgooglemeetShow}
        header={"Google meet links submission form - " + props.name}
        onHide={onHide}
        size="xl"
      >
        {submitted ? (
          <p style={{ textAlign: "center" }}>Submitted successfully</p>
        ) : (
          <GoogleMeetLinks teacherCode={-1} submittedHandler={setSubmitted} />
        )}
      </PopupComponent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    usertype: state.utype.usertype,
    stuno: state.stuno.studentno,
    name: state.fullname.name,
  };
};
export default connect(mapStateToProps)(HomePage);
