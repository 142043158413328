import * as actionTypes from "./actionsTypes";

export const saveUsertype = (payload) => {
  return {
    type: actionTypes.SETUSERTYPE,
    value: payload,
  };
};

export const setusertype = (payload) => {
  return (dispatch, getState) => {
    setTimeout(() => {
      dispatch(saveUsertype(payload));
    }, 0);
  };
};
