import React from "react";
import classes from "./ScheduleItem.module.css";

const ScheduleItem = (props) => {
  const style =
    props.conflictNote === "" ? classes.session : classes.sessionConfilt;
  const title =
    props.conflictNote === ""
      ? props.title
      : props.title + "(" + props.conflictNote + ")";
  const val = props.value.split("/");
  return (
    <div
      className={style}
      style={{
        gridRow: props.day,
        gridColumn: props.value,
        backgroundColor: props.bgcolor,
        zIndex: props.max - (val[1] - val[0]),
      }}
      title={props.teacher}
    >
      <h3 className={classes.sessionTitle}>{title}</h3>
      <span className={classes.sessionItem}>{props.room}</span>
      <span className={classes.sessionItem}>{props.time}</span>
    </div>
  );
};

export default ScheduleItem;
