import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import BankstampImg from "../../../../../assets/images/bankstamp.gif";
import classes from "./Bankstamp.module.css";
import moment from "moment";

const BankStamp = (props) => {
  useEffect(() => {
    if (props.term === -1) {
      return;
    }

    //Format student No
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    const semster =
      props.term.toString().substring(0, 1) +
      props.term.toString().substring(2, 5);

    let ctx = document.getElementById("canvas").getContext("2d");
    let img = new Image();
    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      ctx.font = "700 18px 'Inter, Courier, monospace'";
      ctx.fillText("Student No: " + zeroPad(props.stunum, 7), 12, 25);
      ctx.fillText("Name: " + props.stuname, 12, 50);
      ctx.fillText("Semester Code:              <--------------------", 12, 75);
      ctx.font = "700 18px 'Inter, Courier, monospace'";
      ctx.fillText(semster, 140, 75);
      ctx.fillText(
        "Minimum payment: **" +
          (props.minAmount || props.balance) +
          (props.jdCurrency ? " JD" : " ₪"),
        12,
        100
      );
      ctx.fillText(
        "Last date for payment: " + moment(props.endDate).format("DD/MM/YYYY"),
        12,
        125
      );
      ctx.font = "700 14px 'Inter, Courier, monospace'";
      ctx.fillText("Printed On: " + moment().format("DD/MM/YYYY"), 110, 150);
    };
    img.src = BankstampImg;
  });
  return (
    <div>
      <Grid style={{ textAlign: "center", paddingTop: "15px" }}>
        <canvas id="canvas" width="371" height="167"></canvas>
      </Grid>
      <p className={classes.note}>
        The student can pay up to:{" "}
        <strong>
          {props.minAmount > props.balance ? props.minAmount : props.balance}{" "}
          {props.jdCurrency ? " JD" : " ₪"}
        </strong>
      </p>
    </div>
  );
};

export default BankStamp;
