import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import "./GroupedTable.css";
import ReactHtmlParser from "react-html-parser";

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => (a[orderBy] > b[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class GroupedTable extends React.Component {
  state = {
    groupBy: this.props.groupBy,
    sortBy: "",
    sortOrder: "asc",
    expandedGroups: [""],
  };

  getColumnData = (columns) => {
    return columns.filter((item) => item.dataKey !== this.state.groupBy);
  };

  getGroupedData = (rows) => {
    const groupedData = rows.reduce((acc, item) => {
      let key = item[this.state.groupBy];
      let groupData = acc[key] || [];
      acc[key] = groupData.concat([item]);
      return acc;
    }, {});

    const expandedGroups = {};
    const { sortBy, sortOrder } = this.state;
    Object.keys(groupedData).forEach((item) => {
      //expandedGroups[item] = this.state.expandedGroups.indexOf(item) !== -1;
      if (this.props.expanded) {
        expandedGroups[item] = groupedData[item].sort(
          getSorting(sortOrder, sortBy)
        ); //Open all groups
      } else {
        expandedGroups[item] = this.state.expandedGroups.indexOf(item) !== -1;
      }
      groupedData[item] = groupedData[item].sort(getSorting(sortOrder, sortBy));
    });

    this.groups = expandedGroups;
    return groupedData;
  };

  handleRequestSort = (property) => {
    const sortBy = property;
    let sortOrder = "desc";

    if (this.state.sortBy === property && this.state.sortOrder === "desc") {
      sortOrder = "asc";
    }

    this.setState({ sortOrder, sortBy });
  };

  getCourseDetails(prerequisite, relatedTo, lastOfferedIn) {
    let details = "";
    if (prerequisite) {
      details +=
        '<span class="posshowPrerequisite"><br/><b>Prerequisite: </b>' +
        prerequisite +
        "</span>";
    }
    if (relatedTo) {
      details +=
        '<span class="posshowRelatedTo"><br/><b>Related to: </b>' +
        relatedTo +
        "</span>";
    }
    if (lastOfferedIn) {
      details +=
        '<span class="posshowOfferedIn"><br/><b>Last Offered in: </b>' +
        lastOfferedIn +
        "</span>";
    }
    return details;
  }

  expandRow = (groupVal) => {
    const curr = this.groups[groupVal];
    let expandedGroups = this.state.expandedGroups;
    if (curr) {
      expandedGroups = expandedGroups.filter((item) => item !== groupVal);
    } else {
      if (expandedGroups.indexOf(groupVal) === -1) {
        //Maintain all open groups ever!!
        expandedGroups = expandedGroups.concat([groupVal]);
      }
    }
    this.setState({ expandedGroups });
  };

  render() {
    let { rows, columns } = this.props;
    let columnData = this.getColumnData(columns);
    let groupedData = this.getGroupedData(rows);
    let { sortBy, sortOrder } = this.state;
    return (
      <Table className="GroupedTable" padding="none">
        <TableHead style={{ backgroundColor: "#F0F8FF" }}>
          <TableRow>
            {columnData.map((item) => (
              <TableCell
                key={item.dataKey}
                className={item["className"]}
                style={item["style"]}
              >
                <TableSortLabel
                  active={sortBy === item.dataKey}
                  direction={sortOrder}
                  onClick={this.handleRequestSort.bind(null, item.dataKey)}
                >
                  <b>{item.title}</b>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedData).map((key, index) => {
            return (
              <React.Fragment>
                <TableRow style={{ backgroundColor: "#F8F8FF" }} key={key}>
                  <TableCell
                    colSpan={columnData.length}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      paddingLeft: 0,
                      fontSize: 12,
                    }}
                    onClick={this.expandRow.bind(null, key)}
                  >
                    <IconButton>
                      <Icon>
                        {this.groups[key] ? "expand_more" : "chevron_right"}
                      </Icon>
                    </IconButton>
                    <span>{key}</span>
                  </TableCell>
                </TableRow>
                {this.groups[key] &&
                  groupedData[key].map((item) => (
                    <TableRow className={item["status"]}>
                      {columnData.map((col, index) => (
                        <TableCell key={index} className={col.itemsStyle}>
                          {item[col.dataKey]}

                          {col.dataKey === "title"
                            ? ReactHtmlParser(
                                this.getCourseDetails(
                                  item["prerequisite"],
                                  item["relatedTo"],
                                  item["offeredIn"]
                                )
                              )
                            : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default GroupedTable;
