import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "../../../../axios/BUAP/cart";
import { connect } from "react-redux";
import PopupComponent from "../../../../containers/Registration/PopupComponent";
import GetToken from "../../../../Shared/GetToken";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { Form, Button } from "react-bootstrap";
import PreapprovalCoursesList from "./PreapprovalCoursesList";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  [theme.breakpoints.down("xs")]: {
    head: {
      padding: 3,
      fontSize: 9,
    },
    body: {
      padding: 3,
      fontSize: 9,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  link: {
    cursor: "pointer",
    color: "#2a67a8",
    fontWeight: "bold",
  },
  dropIcon: {
    cursor: "pointer",
    color: "#FF0000",
    fontWeight: "bold",
    fontSize: "large",
  },
  dropRegistered: {
    cursor: "pointer",
    color: "#FF6347",
    fontWeight: "bold",
    fontSize: "large",
  },
  [theme.breakpoints.down("xs")]: {
    columnTable: {
      display: "none",
    },
    table: {
      minWidth: 200,
    },
  },
}));

const PreapprovalCourses = (props) => {
  const classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({
    catalogCode: -1,
    catalogKey: "",
    title: "",
    credits: 0,
    notes: "",
    addDrop: false,
    mandatoryOptional: false,
    registered: false,
  });
  const [waitData, setWaitData] = useState(true);
  useEffect(() => {
    axios
      .get("PreApproval/" + props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setData(response.data);
        setWaitData(false);
      });
  }, [props.studentdegree]);

  const updateHandler = () => {
    axios
      .get("PreApproval/" + props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setData(response.data);
      });
  };

  const updateRecordHandler = async (catalogCode) => {
    const adddropCheck = document.getElementsByName("adddrop" + catalogCode);
    let add = false;
    if (adddropCheck[0].checked) {
      add = true;
    } else if (adddropCheck[1].checked) {
      add = false;
    }

    const mandatoryOptionalCheck = document.getElementsByName(
      "manadtoryoptional" + catalogCode
    );
    let mandatory = false;
    if (mandatoryOptionalCheck[0].checked) {
      mandatory = true;
    } else if (mandatoryOptionalCheck[1].checked) {
      mandatory = false;
    }
    let note = document.querySelector(
      'input[name = "note' + catalogCode + '"]'
    ).value;
    if (note === "") {
      note = null;
    }
    await axios
      .put(
        "updatepreapproval/" +
          catalogCode +
          "/" +
          props.studentdegree +
          "/" +
          props.iduser +
          "/" +
          add +
          "/" +
          mandatory +
          "/" +
          note,
        {
          headers: { Authorization: GetToken("token") },
        }
      )
      .then((response) => {
        alert("Updated!");
        props.updatestudentstatus(props.upstatus + 1);
      });
  };

  const deleteHandler = async (courseCode, courseCatalog) => {
    const r = window.confirm(
      "Would you like delete the " +
        courseCatalog +
        " from the preapproval courses?"
    );
    if (r) {
      await axios
        .delete(
          "DeletePreApproval/" +
            courseCode +
            "/" +
            props.studentdegree +
            "/" +
            props.iduser,
          {
            headers: { Authorization: GetToken("token") },
          }
        )
        .then((response) => {
          alert("Course has been removed from the preapproval courses!.");
          props.updatestudentstatus(props.upstatus + 1);
        });
      await updateHandler();
    }
  };

  return (
    <React.Fragment>
      {waitData ? (
        <SpinnerBootstrap />
      ) : (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Button onClick={() => setModalShow(true)} variant="success">
              Add a new preapproval courses
            </Button>
            <Table
              className={classes.table}
              aria-label="customized table"
              padding="none"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Course</StyledTableCell>
                  <StyledTableCell className={classes.columnTable}>
                    Course title
                  </StyledTableCell>
                  <StyledTableCell>Credits</StyledTableCell>
                  <StyledTableCell>Add/Drop</StyledTableCell>
                  <StyledTableCell>Mandatory</StyledTableCell>
                  <StyledTableCell>Notes</StyledTableCell>
                  <StyledTableCell>Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data).map((index) => {
                  return (
                    <StyledTableRow key={data[index].catalogCode} hover={true}>
                      <StyledTableCell>
                        {data[index].catalogKey}
                      </StyledTableCell>
                      <StyledTableCell>{data[index].title}</StyledTableCell>
                      <StyledTableCell>{data[index].credits}</StyledTableCell>
                      <StyledTableCell>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              inline
                              label="Add"
                              name={`adddrop${data[index].catalogCode}`}
                              type={type}
                              id={`inline-${data[index].catalogCode}-1`}
                              defaultChecked={data[index].addDrop}
                            />
                            <Form.Check
                              inline
                              label="Drop"
                              name={`adddrop${data[index].catalogCode}`}
                              type={type}
                              id={`inline-${data[index].catalogCode}-2`}
                              defaultChecked={!data[index].addDrop}
                            />{" "}
                          </div>
                        ))}
                      </StyledTableCell>

                      <StyledTableCell>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              inline
                              label="Manadtory"
                              name={`manadtoryoptional${data[index].catalogCode}`}
                              type={type}
                              id={`inline-${data[index].catalogCode}-3`}
                              defaultChecked={data[index].mandatoryOptional}
                            />
                            <Form.Check
                              inline
                              label="Optional"
                              name={`manadtoryoptional${data[index].catalogCode}`}
                              type={type}
                              id={`inline-${data[index].courseOffered}-4`}
                              defaultChecked={!data[index].mandatoryOptional}
                            />{" "}
                          </div>
                        ))}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Form.Control
                          id={`note${data[index].catalogCode}`}
                          name={`note${data[index].catalogCode}`}
                          defaultValue={data[index].notes}
                          type="text"
                          placeholder="Note"
                          style={{ width: "90%" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          onClick={() =>
                            updateRecordHandler(data[index].catalogCode)
                          }
                          variant="success"
                        >
                          Update
                        </Button>
                        <Button
                          onClick={() => deleteHandler(data[index].catalogCode)}
                          variant="danger"
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <PopupComponent
            show={modalShow}
            header={"Preapproval courses"}
            onHide={() => setModalShow(false)}
            size="xl"
          >
            <PreapprovalCoursesList updatelist={() => updateHandler()} />
          </PopupComponent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    updatestudentstatus: (e) => dispatch(actionCreators.setupdatestatus(e)),
  };
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    iduser: state.idu.iduser,
    upstatus: state.upstatus.updatestatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreapprovalCourses);
