import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  degCal: {
    backgroundColor: 'white',
    color: 'black',
    padding: 10,
    textAlign: 'left',
    marginBottom: 10
  },
  title: {
    fontWeight: 'bold',
  },
  titleSize:{
    fontSize:'12px'
  }
}));

const DegreeCalculation = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.degCal} elevation={1} square>
      <Typography className={[classes.title,classes.titleSize]}>
        {props.title}: {props.allCredits} out of {props.outOf} credits
      </Typography>
      <Typography className={classes.titleSize}>
        Completed: {props.completed} | Current Semester: {props.current} | Planned: {props.planned}
      </Typography>
      {props.GPA ?
        <Typography  className={classes.titleSize}>
          {props.title==="Total credits"?"Cumulative":props.title} GPA: <b>{props.GPA<2.0 ?<span style={{color:'red'}}>{props.GPA}</span>:props.GPA}</b>
        </Typography>
        : null
      }

    </Paper>
  )
}


DegreeCalculation.propTypes = {
  title: PropTypes.string.isRequired,
  allCredits: PropTypes.number.isRequired,
  outOf: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  planned: PropTypes.number.isRequired,
};

export default DegreeCalculation;