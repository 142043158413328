import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppHeader from './AppHeader'
import Content from './Content';
import PropTypes from 'prop-types';
import Navigation from './Navigation/Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'white'
  },
  buttons: {
    display: 'none',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  copyright: {
    padding: '50px 50px 50px 50px',
    bottom: '30px'
  },
}));


const Layout = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className={classes.root}>
      <AppHeader
        handleDrawerToggle={handleDrawerToggle}
        title={props.title}
      >
        </AppHeader>
      <Navigation mobileOpen={mobileOpen} data={props.data}
        handleDrawerToggle={handleDrawerToggle} />
      <Content className={classes.content}>
        {props.children}
      </Content>

    </div>
  )
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
};



export default Layout;