import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios/BUAP/registration";
import { connect } from "react-redux";
import GetToken from "../../Shared/GetToken";
import Semster from "../../components/Shared/Semester";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paperItem: {
    padding: theme.spacing(1),
    textAlign: "left",
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: "bold",
    width: 150,
    display: "inline-block",
  },
  gridShadowBox: {
    border: "1px solid gray",
    boxShadow: "5px 10px 8px #888888",
  },
  [theme.breakpoints.down("xs")]: {
    title: {
      width: "auto",
    },
  },
}));

const RegisteredCourses = (props) => {
  const [courses, setCourses] = useState("");
  const [totalCredits, setTotalCredits] = useState(0);

  useEffect(() => {
    let url = "";
    if (props.sem === -1) {
      return;
    } else if (!props.sem) {
      url = "RegisteredCoursesWithoutTerm/" + props.studentdegree;
    } else {
      url = "RegisteredCourses/" + props.studentdegree + "/" + props.sem;
    }

    axios
      .get(url, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        let listOfcourses = "";
        let totalCreditsAxios = 0;
        for (const item of response.data) {
          listOfcourses += item.catalogKey + item.section + ", ";
          totalCreditsAxios += item.credits;
        }
        listOfcourses = listOfcourses.substring(0, listOfcourses.length - 2);
        setCourses(listOfcourses);
        setTotalCredits(totalCreditsAxios);
      });
  }, [props.studentdegree, props.sem, props.updatecart]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridShadowBox}>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.paperItem} elevation={0}>
            <div className={classes.title}>
              <Semster sem={props.sem} />{" "}
              {props.type === 1 ? "preregistration" : "registration"}:{" "}
            </div>{" "}
            {courses}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paperItem} elevation={0}>
            <div className={classes.title}>Total Credit Hours</div>
            {totalCredits}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    updatecart: state.ucart.updatecart,
  };
};
export default connect(mapStateToProps)(RegisteredCourses);
