import React, { Component } from "react";
import BUAP from "./containers/BUAP/BUAP";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import WebView from "./components/Mobile/Webview";
import ViewStudent from "./containers/BUAP/Advisor/AdviseeList/ViewStudent";
import PrintReceipt from "./components/BUAP/Student/Financial/PrintReceipt";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/mobile/">
            <WebView></WebView>
          </Route>
          <Route path="/admin/">
            <WebView></WebView>
          </Route>
          <Route path="/advisee/showstudent">
            <ViewStudent></ViewStudent>
          </Route>
          <Route path="/printreceipt/" exact>
            <PrintReceipt />
          </Route>
          <Route path="/">
            <BUAP></BUAP>
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
