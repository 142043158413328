import React, { useEffect, useState } from "react";
import CartHeader from "./CartHeader/CartHeader";
import CartCourses from "./CartCourses/CartCourses";
import { Paper, Typography } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "../../../axios/BUAP/registration";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/BUAP/BUAP";
import PopupComponent from "../PopupComponent";
import GetToken from "../../../Shared/GetToken";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Alerts from "../../../components/Shared/Alerts/Alerts";
import ListOfOfferedCourses from "../ListOfOfferedCourses";
import PreapprovalCourses from "./PreapprovalCourses/PreapprovalCourses";
import PreapprovalCoursesStudent from "./PreapprovalCourses/PreapprovalCoursesStudent";
import { Alert, Button } from "react-bootstrap";
import NotificationAndNeedattentions from "../../../components/Shared/Alerts/NotificationAndNeedattentions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 3,
  },
  submit: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  search: {
    padding: theme.spacing(2),
    textAlign: "right",
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("xs")]: {
    button: {
      fontSize: 10,
    },
  },
}));

const Cart = (props) => {
  const classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [waitShow, setWaitShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [notifiactions, setNotification] = useState("");
  const [needattentions, setNeedAttentions] = useState("");

  const submitHanlder = async () => {
    setWaitShow(true);
    const payload = {
      inStuDeg: props.studentdegree,
      inUserType: props.usertype,
    };

    axios
      .post("SubmitButton", payload, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setSubmitted(false);
        if (
          response.data.notifiactions.length === 0 &&
          response.data.needAttentions.length === 0
        ) {
          props.updatestudentstatus(props.upstatus + 1);
          props.setupdatecart(props.updatecart + 1);
          setSubmitted(true);
        } else {
          setNotification(response.data.notifiactions);
          setNeedAttentions(response.data.needAttentions);
        }
        setWaitShow(false);
      });
  };

  const searchForACourseHandler = () => {
    setModalShow(true);
  };

  const reopenForTheStudent = () => {
    const r = window.confirm(
      "Would you like to reopen the preregistration for the student?"
    );
    if (r) {
      axios
        .get("ReopenForStudent/" + props.studentdegree, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("The preregistration is reopend for the student!");
            props.setupdatecart(-1);
          }
        });
    }
  };

  return (
    <React.Fragment>
      {props.status.includes("Applied for graduation") ||
      props.status === "Dismissed" ||
      props.status === "Disappeared" ||
      (props.status != null && props.status.includes("Graduated")) ? (
        <CartHeader
          status={props.status}
          minLoad={props.minLoad}
          maxLoad={props.maxLoad}
          underProbation={props.underProbation}
        />
      ) : (
        <React.Fragment>
          <CartHeader
            status={props.status}
            minLoad={props.minLoad}
            maxLoad={props.maxLoad}
            underProbation={props.underProbation}
          />
          <CartCourses />
          {props.allowtoreg !== 0 ? (
            <React.Fragment>
              <div className={classes.root}>
                <Grid
                  justifyContent="space-between"
                  alignItems="center"
                  container
                  spacing={3}
                >
                  {!waitShow ? (
                    <Grid item xs={6}>
                      <Paper elevation={0} className={classes.submit}>
                        <Button
                          onClick={() => submitHanlder()}
                          className={classes.button}
                          startIcon={<SendIcon></SendIcon>}
                          variant="primary"
                        >
                          {props.allowtoreg === 1 && props.usertype !== "0" ? (
                            <>Save</>
                          ) : (
                            <>Submit</>
                          )}
                        </Button>

                        {submitted ? (
                          <div style={{ color: "green" }}>
                            Submitted Successfully
                          </div>
                        ) : null}

                        {props.allowtoreg === 1 && props.usertype !== "0" ? (
                          //Convert the button in a stack
                          <span>
                            {" "}
                            {}
                            <Button
                              onClick={() => reopenForTheStudent()}
                              className={classes.button}
                              variant="danger"
                            >
                              Reopen for student
                            </Button>
                          </span>
                        ) : null}
                        {null}
                      </Paper>
                      <NotificationAndNeedattentions
                        notifiaction={notifiactions}
                        needAttentions={needattentions}
                        studentdegree={props.studentdegree}
                      />
                    </Grid>
                  ) : (
                    <Spinner />
                  )}
                  {props.allowreg !== 0 && props.allowtoreg !== 14 ? (
                    <Grid item xs={6}>
                      <Paper elevation={0} className={classes.search}>
                        <Button
                          className={classes.button}
                          variant="info"
                          onClick={searchForACourseHandler}
                        >
                          Search for a course
                        </Button>
                      </Paper>
                    </Grid>
                  ) : null}
                </Grid>
                <Alerts></Alerts>
              </div>
              <React.Fragment>
                {props.usertype !== "0" ? (
                  <React.Fragment>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        paddingTop: "20px",
                        paddingBottom: "5px",
                      }}
                    >
                      Preapproval courses
                    </Typography>
                    <PreapprovalCourses />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        paddingTop: "20px",
                        paddingBottom: "5px",
                      }}
                    >
                      Preapproval courses
                    </Typography>
                    {props.allowtoreg === 1 && props.usertype === "0" ? (
                      <PreapprovalCoursesStudent />
                    ) : null}
                  </React.Fragment>
                )}
              </React.Fragment>
              <PopupComponent
                show={modalShow}
                header="List of offered courses"
                onHide={() => setModalShow(false)}
                size="xl"
              >
                <ListOfOfferedCourses />
              </PopupComponent>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    allowtoreg: state.allowreg.allowtoreg,
    usertype: state.utype.usertype,
    updatecart: state.ucart.updatecart,
    upstatus: state.upstatus.updatestatus,
  };
};
//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setupdatecart: (e) => dispatch(actionCreators.setupdatecart(e)),
    updatestudentstatus: (e) => dispatch(actionCreators.setupdatestatus(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
