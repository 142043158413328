import React, { useRef } from "react";
import Container from "@material-ui/core/Container";
import NameAndNum from "../../../../components/BUAP/Student/Information/NameAndNum";
import Transcripts from "../../../../components/BUAP/Student/Transcript/Transcripts/Transcripts";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";

const Transcript = () => {
  const ref = useRef();
  return (
    <Container>
      <div style={{ textAlign: "right" }}>
        <ReactToPrint
          bodyClass="print-agreement"
          content={() => ref.current}
          trigger={() => <Button type="primary">Print</Button>}
        />
      </div>
      <div ref={ref}>
        <NameAndNum />
        <p
          className="showonprint"
          style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold" }}
        >
          {" "}
          Transcript
          <br />
          Unofficial copy
        </p>
        <Transcripts />
      </div>{" "}
    </Container>
  );
};
export default Transcript;
