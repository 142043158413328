import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../Logo/Logo";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: "#1864ab",
  },
  card: {
    boxShadow: "none",
    borderRadius: 0,
  },
  header: {
    display: "flex",
    borderBottom: "2px solid #bbb",
    flexDirection: "column",
  },
  username: {
    alignSelf: "center",
    justifySelf: "center",
    paddingBottom: "13px",
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div>
        <Logo />
      </div>
      <div className={classes.username}>
        <div>
          <b>Name: {props.name}</b>
        </div>
        <div style={{ fontSize: "14px" }}>username: {props.username}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    name: state.fullname.name,
  };
};

export default connect(mapStateToProps)(Profile);
