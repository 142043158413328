import React, { useEffect, useState } from "react";
import axios from "../../../axios/BUAP/common";
import GetToken from "../../../Shared/GetToken";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Grid, ListItem, ListItemText } from "@material-ui/core";

const AdditionalLinks = (props) => {
  const [additionallinks, setAdditionallinks] = useState([]);

  useEffect(() => {
    if (props.videos === true) {
      axios
        .get("additionalLinksVideos/" + props.usertype, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setAdditionallinks(response.data);
        });
    } else {
      axios
        .get("additionalLinks/" + props.usertype, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setAdditionallinks(response.data);
        });
    }
  }, [props.usertype, props.videos]);
  return (
    <React.Fragment>
      <Grid item xs={12}>
        {!props.videos ? (
          <a
            href={"https://www.jstor.org/"}
            target="_self"
            referrerpolicy="unsafe-url"
            key={"jstor"}
          >
            <ListItem button>
              <ListItemText inset primary={"JSTOR"} />
            </ListItem>
          </a>
        ) : null}
        {additionallinks.map((link) => (
          <NavLink to={{ pathname: link.url }} target="_blank" key={link.key}>
            <ListItem button>
              <ListItemText inset primary={link.label} />
            </ListItem>
          </NavLink>
        ))}
      </Grid>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    usertype: state.utype.usertype,
  };
};

export default connect(mapStateToProps)(AdditionalLinks);
