import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";
import axiosCommon from "../../../axios/BUAP/common";
import GetToken from "../../../Shared/GetToken";

const Forms = (props) => {
  const [listOfDate, setListOfDate] = useState([]);
  useEffect(() => {
    axiosCommon
      .get("forms", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setListOfDate(response.data);
      });
  }, []);
  return (
    <Container>
      {listOfDate.map((row) => (
        <Accordion key={row.formOffice.code}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {row.formOffice.name}- {row.formOffice.nameA}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {row.listOfCategiries.map((categry, key) => (
                <React.Fragment key={key}>
                  <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                    {categry.formCategory.name}- {categry.formCategory.nameA}
                  </span>
                  <ul key={key}>
                    {categry.listOfForms.map((form, key) => (
                      <li key={key}>
                        <a
                          key={key}
                          href={form.formLinkname}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {form.formTitle}- {form.formTitleA}
                        </a>
                      </li>
                    ))}
                  </ul>
                  {categry.listOfsubCategories.map((subcategry, key) => (
                    <React.Fragment key={key}>
                      <ul key={key}>
                        <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                          {subcategry.formSubCategory.name}-{" "}
                          {subcategry.formSubCategory.nameA}
                        </span>
                        {subcategry.listOfForms.map((form, key) => (
                          <li key={key}>
                            <a
                              key={key}
                              href={form.formLinkname}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {form.formTitle}-{" "}
                              <span dir="rtl">{form.formTitleA}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default Forms;
