import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import HtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
    degCal: {
        backgroundColor: 'white',
        color: 'black',
        padding: 10,
        textAlign: 'left',
        marginBottom: 10
    },
    title: {
        fontSize: 12
    }
}));

const POSFooter = (props) => {
    const classes = useStyles();
    const [footer, setFooter] = useState('');
    useEffect(() => {
        if (props.txt !== '') {
            setFooter(
                <Paper className={classes.degCal} elevation={1} square>
                    <span style={{fontWeight: 'bold',}}>#Satisfied courses:</span>
                    <Typography className={classes.title}>
                        {HtmlParser(props.txt)}
                    </Typography>
                </Paper>
            )
        }

    }, [props.txt, classes.degCal, classes.title]);

    return (
        <React.Fragment>
            {footer}
        </React.Fragment>
    )
}


POSFooter.propTypes = {
    txt: PropTypes.string.isRequired,
};

export default POSFooter;