import React, { useEffect, useState } from "react";
import NestedListMUI from "../components/Layout/Navigation/NestedList/NestedListMUI";
import { connect } from "react-redux";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import UploadIcon from "@mui/icons-material/Upload";
import ViewListIcon from "@mui/icons-material/ViewList";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import ListIcon from "@mui/icons-material/List";
import BalanceIcon from "@mui/icons-material/Balance";
import GradeIcon from "@mui/icons-material/Grade";
import ElevatorIcon from "@mui/icons-material/Elevator";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import SubjectIcon from "@mui/icons-material/Subject";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import InfoIcon from "@mui/icons-material/Info";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import GradingIcon from "@mui/icons-material/Grading";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import UpdateIcon from "@mui/icons-material/Update";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GradientIcon from "@mui/icons-material/Gradient";
import SettingsIcon from "@mui/icons-material/Settings";
import AppsIcon from "@mui/icons-material/Apps";
import FeedbackIcon from "@mui/icons-material/Feedback";
const ListOfItems = (props) => {
  const getIcon = (iconname) => {
    switch (iconname) {
      case "PersonSearch":
        return <PersonSearchIcon />;
      case "Upload":
        return <UploadIcon />;
      case "ViewList":
        return <ViewListIcon />;
      case "ScheduleSend":
        return <ScheduleSendIcon />;
      case "List":
        return <ListIcon />;
      case "Balance":
        return <BalanceIcon />;
      case "Grade":
        return <GradeIcon />;
      case "Elevator":
        return <ElevatorIcon />;
      case "SettingsAccessibility":
        return <SettingsAccessibilityIcon />;
      case "Subject":
        return <SubjectIcon />;
      case "Announcement":
        return <AnnouncementIcon />;
      case "Info":
        return <InfoIcon />;
      case "Schedule":
        return <ScheduleIcon />;
      case "Summarize":
        return <SummarizeIcon />;
      case "ListAlt":
        return <ListAltIcon />;
      case "CalendarMonth":
        return <CalendarMonthIcon />;
      case "EventBusy":
        return <EventBusyIcon />;
      case "Grading":
        return <GradingIcon />;
      case "DynamicForm":
        return <DynamicFormIcon />;
      case "ContactSupport":
        return <ContactSupportIcon />;
      case "Update":
        return <UpdateIcon />;
      case "Search":
        return <SearchIcon />;
      case "Notifications":
        return <NotificationsIcon />;
      case "Gradient":
        return <GradientIcon />;
      case "Settings":
        return <SettingsIcon />;
      case "Feedback":
        return <FeedbackIcon />;
      default:
        return <AppsIcon />;
    }
  };

  const [list, setList] = useState([]);
  useEffect(() => {
    let items = [];
    props.data.map((row, key) =>
      items.push({
        key: key + 1,
        label: row.title,
        labelAr: row.titleAr,
        icon: () => getIcon(row.iconname),
        URL: "/" + row.component,
        items: [],
        externalLink: false,
      })
    );
    setList(items);
  }, [props.usertype, props.data]);

  return <NestedListMUI lists={list} />;
};
const mapStateToProps = (state) => {
  return {
    usertype: state.utype.usertype,
  };
};
export default connect(mapStateToProps)(ListOfItems);
