import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactHtmlParser from "react-html-parser";

import { connect } from "react-redux";
import axios from "../../../../axios/BUAP/registration";
import axioscart from "../../../../axios/BUAP/cart";
import ParadigmIcon from "./ParadigmIcon";
import Semster from "../../../../components/Shared/Semester";
import PopupComponent from "../../../Registration/PopupComponent";
import ListOfSections from "../../../Registration/Cart/CartCourses/ListOfSections";
import { Button } from "@material-ui/core";
import AddCourseStatus from "../../../../components/BUAP/Student/Paradigm/AddCourseStatus";
import GetToken from "../../../../Shared/GetToken";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: 10,
  },
  changeSection: {
    padding: 10,
  },
  [theme.breakpoints.down("xs")]: {
    table: {
      maxWidth: "100%",
      minWidth: 0,
      "& td, th": {
        padding: 8,
        fontSize: 13,
      },
    },
    title: {
      display: "none",
    },
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ListOfCourses = (props) => {
  const classes = useStyles();

  const [data, setData] = useState({
    courseCode: 0,
    coursestatus: "",
    catalogKey: 0,
    credits: 0,
    title: "",
    incart: -1,
    regGrade: "",
    regTerm: "",
    add: 0,
    registered: false,
    notes: "",
    courseType: 0,
  });

  const [selectedCatalogkey, setSelectedCatalogkey] = React.useState(-1);
  const [modalShow, setModalShow] = useState(false);
  const [actualCredits, setActualCredits] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [emptyMsg, setEmptyMsg] = useState(null);
  const [wait, setWait] = useState(false);

  const updateCourseHandler = (stuDeg) => {};

  useEffect(() => {
    setWait(true);
    const courseType =
      props.courseType === "" ? "General elective" : props.courseType;
    axios
      .get(
        "GetStudentParadigmCourse/" +
          props.studentdegree +
          "/" +
          props.group +
          "/" +
          props.coursecode +
          "/" +
          props.usertype +
          "/" +
          courseType,
        {
          headers: { Authorization: GetToken("token") },
        }
      )
      .then((response) => {
        setData(response.data.paradigmData);
        if (response.data.paradigmData.length === 0) {
          if (props.coursecode === 2289) {
            setEmptyMsg("The student does not have a minor yet!");
          } else {
            setEmptyMsg("No courses found!");
          }
        }
      });

    axioscart
      .get("StudentCart/" + props.studentdegree + "/" + props.usertype, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        //Update to Get the actual total credits only
        setActualCredits(response.data.cartFrontEnd.actualTotalCredits);
      });
    setWait(false);
  }, [
    props.studentdegree,
    props.coursecode,
    props.group,
    props.usertype,
    props.courseType,
  ]);

  const viewCourseHandler = (catalogKey, courseCode, add) => {
    if (add === 1) {
      setSelectedCourse(courseCode);
      setModalShow(true);
      setSelectedCatalogkey(catalogKey);
    } else {
      alert("The course is offerd but you are not allow to register it!");
    }
  };

  return (
    <>
      {!wait ? (
        <React.Fragment>
          {emptyMsg === null ? (
            <TableContainer component={Paper}>
              <Table
                size="small"
                fontSize={100}
                className={classes.table}
                aria-label="spanning table"
                padding="none"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell className={classes.title}>Title</TableCell>
                    <TableCell>Course type</TableCell>
                    <TableCell align="center">Credits</TableCell>
                    <TableCell align="center">Grade</TableCell>
                    <TableCell align="center">Term</TableCell>
                    {props.allowtoreg !== false &&
                    props.allowtoreg !== 0 &&
                    props.allowtoreg !== 14 ? (
                      <TableCell align="center">Add</TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(data).map((key, index) => {
                    return (
                      <StyledTableRow key={index} hover={true}>
                        <TableCell align="center">
                          <ParadigmIcon status={data[key].courseStatus} />
                        </TableCell>
                        <TableCell>{data[key].catalogKey}</TableCell>
                        <TableCell className={classes.title}>
                          {ReactHtmlParser(data[key].title)}
                          <br />
                          {data[key].prerequisite ? (
                            <span>
                              <b>Prerequisite</b>: {data[key].prerequisite}
                              <br />
                            </span>
                          ) : null}
                          {data[key].offeredIn ? (
                            <span>
                              <b>Last Offered in:</b> {data[key].offeredIn}
                              <br />
                            </span>
                          ) : null}
                          {data[key].relatedTo ? (
                            <span>
                              <b>Related to:</b> {data[key].relatedTo}
                              <br />
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell>{data[key].courseType}</TableCell>
                        <TableCell align="center">
                          {data[key].credits}
                        </TableCell>
                        <TableCell align="center">
                          {data[key].regGrade}
                        </TableCell>
                        <TableCell align="center">
                          <Semster sem={data[key].regTerm}></Semster>
                          {data[key].notes}
                        </TableCell>
                        {props.allowtoreg !== false &&
                        props.allowtoreg !== 0 &&
                        props.allowtoreg !== 14 ? (
                          <TableCell align="center">
                            <Button
                              style={{ color: "red" }}
                              onClick={() =>
                                viewCourseHandler(
                                  data[key].catalogKey,
                                  data[key].courseCode,
                                  data[key].add
                                )
                              }
                            >
                              <AddCourseStatus
                                status={data[key].add}
                              ></AddCourseStatus>
                            </Button>
                          </TableCell>
                        ) : null}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <React.Fragment>
              <b>{emptyMsg}</b>
            </React.Fragment>
          )}
          <PopupComponent
            show={modalShow}
            header={selectedCatalogkey}
            onHide={() => setModalShow(false)}
            size="l"
          >
            <ListOfSections
              coursecode={selectedCourse}
              actualCredits={actualCredits}
              updateCartHandler={updateCourseHandler}
              buttonTitle="Add section"
            />
          </PopupComponent>
        </React.Fragment>
      ) : (
        <SpinnerBootstrap />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    usertype: state.utype.usertype,
    allowtoreg: state.allowreg.allowtoreg,
  };
};
export default connect(mapStateToProps)(ListOfCourses);
