import React, { useEffect } from "react";
const PageNotFound = () => {
  useEffect(() => {
    var path = window.location.pathname;

    window.location = "https://buapv1.bethlehem.edu/" + path;
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default PageNotFound;
