import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import StudentInfo from "../../../../components/BUAP/Student/StudentInfo/StudentInfo";
import GeneralInfo from "../../../../components/BUAP/Student/StudentInfo/GeneralInfo";
import AddressInfo from "../../../../components/BUAP/Student/StudentInfo/AddressInfo";
import POS from "../../Student/POS/POS";
import Schedule from "../../Student/Schedule/Schedule";
import Paradigm from "../../Student/Paradigm/Paradigm";
import FinalExam from "../../Student/FinalExam/FinalExam";
import { Typography } from "@material-ui/core";
import NameAndNum from "../../../../components/BUAP/Student/Information/NameAndNum";
import axios from "../../../../axios/BUAP/students";
import axiosCommon from "../../../../axios/BUAP/common";
import GetToken from "../../../../Shared/GetToken";
import Advertisements from "../../Homepage/Advertisements/Advertisements";
import Financial from "../../Student/Financial/Financial";
import Transcript from "../../Student/Transcript/Transcript";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";

const StudentInfoForAdviseeList = (props) => {
  const [tabsData, setTabsData] = useState([]);
  const [defaultTab, setDefaultTab] = useState(0);
  const refTranscript = useRef();
  const refPR = useRef();
  useEffect(() => {
    //Used to generate paradigm and student degree if they are not generated
    axios.get("StudentDegree/" + props.studentno, {
      headers: { Authorization: GetToken("token") },
    });

    axiosCommon
      .get("AutherizedTabs/", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setTabsData(response.data);
        let arrayLength = response.data.length;
        let count = 0;
        for (let i = 0; i < arrayLength; i++) {
          if (response.data[i].defaultRank) {
            setDefaultTab(count);
            break;
          }
          count++;
        }
        //setDefaultTab()
      });
  }, [props.studentno]);

  //Convert the component to lazy component in the future for performance purposes
  const gettabcomponent = (tabidNo, key) => {
    let tab;
    switch (tabidNo) {
      case 1:
        tab = (
          <TabPanel key={key}>
            <StudentInfo stuNo={props.studentno} />
            <GeneralInfo stuNo={props.studentno} />
            <AddressInfo stuNo={props.studentno} />
          </TabPanel>
        );
        break;
      case 2:
        tab = (
          <TabPanel key={key}>
            <div style={{ textAlign: "right" }}>
              <ReactToPrint
                bodyClass="print-agreement"
                content={() => refTranscript.current}
                trigger={() => <Button type="primary">Print</Button>}
              />
            </div>
            <div ref={refTranscript}>
              <div className="showonprint">
                <NameAndNum />
              </div>
              <Schedule />
            </div>
          </TabPanel>
        );
        break;
      case 3:
        tab = (
          <TabPanel key={key}>
            <Transcript />
          </TabPanel>
        );
        break;
      case 4:
        tab = (
          <TabPanel key={key}>
            <POS />
          </TabPanel>
        );
        break;
      case 5:
        tab = (
          <TabPanel key={key}>
            <div style={{ textAlign: "right" }}>
              <ReactToPrint
                bodyClass="print-agreement"
                content={() => refPR.current}
                trigger={() => <Button type="primary">Print</Button>}
              />
            </div>
            <div ref={refPR}>
              <div className="showonprint">
                <NameAndNum />
              </div>
              <Paradigm />
            </div>
          </TabPanel>
        );
        break;
      case 6:
        tab = (
          <TabPanel key={key}>
            <FinalExam />
          </TabPanel>
        );
        break;
      case 7:
        tab = (
          <TabPanel key={key}>
            <Financial />
          </TabPanel>
        );
        break;
      case 8:
        tab = (
          <TabPanel key={key}>
            <Advertisements />
          </TabPanel>
        );
        break;
      default:
        tab = <TabPanel></TabPanel>;
    }

    return tab;
  };

  return (
    <React.Fragment>
      <Typography>
        <NameAndNum></NameAndNum>
      </Typography>
      <Tabs>
        <TabList>
          {tabsData.map((row, key) => (
            <Tab key={key}>{row.title}</Tab>
          ))}
        </TabList>
        {tabsData.map((row, key) => gettabcomponent(row.autoid, key))}
      </Tabs>
    </React.Fragment>
  );
};

export default StudentInfoForAdviseeList;
