import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from "../../../../axios/BUAP/students";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GetToken from "../../../../Shared/GetToken";
import SpinnerBootstrap from "../../../UI/Spinner/Spinner";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    fontSize: 12,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minheight: 230,
  },
  media: {
    height: 200,
  },
  paperItem: {
    padding: theme.spacing(0.2),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: "bold",
    width: 100,
    display: "inline-block",
  },
  lineSpace: {
    margin: 0,
    marginBottom: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    title: {
      width: "auto",
    },
  },
});

class GeneralInfo extends React.Component {
  state = {
    errorMessage: null,
    GeneralInfo: {
      nameA: "",
      relgion: "",
      gender: "",
      maritalStatus: "",
      nationality: "",
      certificateYear: 0,
      tawjihiStream: "",
      birthDate: "",
      idNoType: "",
      schoolName: "",
    },
    waitData: true,
  };

  componentDidMount() {
    axios
      .get("GeneralInfo/" + this.props.stuNo, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        this.setState({ GeneralInfo: response.data });
        this.setState({ waitData: false });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.waitData ? (
          <SpinnerBootstrap />
        ) : (
          <React.Fragment>
            <Typography style={{ marginTop: 15, fontWeight: "bold" }}>
              General information
              <hr className={classes.lineSpace} />
            </Typography>

            <div className={classes.root}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Paper
                    className={classes.paperItem}
                    elevation={0}
                    title="الاسم باللغة العربية"
                  >
                    <div className={classes.title}>Arabic name :</div>
                    {this.state.GeneralInfo.nameA}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الجنسية">
                      Nationality :
                    </div>
                    {this.state.GeneralInfo.nationality}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="تاريخ الولادة">
                      Birth Date :
                    </div>
                    {this.state.GeneralInfo.birthDate}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الديانة">
                      Religion :
                    </div>
                    {this.state.GeneralInfo.relgion}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الجنس">
                      Gender :
                    </div>
                    {this.state.GeneralInfo.gender}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الحالة الاجتماعية">
                      Marital Status :
                    </div>
                    {this.state.GeneralInfo.maritalStatus}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div
                      className={classes.title}
                      title="مدرسة الثانوية العامة"
                    >
                      High school :
                    </div>
                    {this.state.GeneralInfo.schoolName}
                  </Paper>
                </Grid>

                {/* <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="رقم الهوية">
                      ID No./ Type :
                    </div>
                    {this.state.GeneralInfo.idNoType}
                  </Paper>
                </Grid> */}
              </Grid>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

GeneralInfo.propTypes = {
  stuNo: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(GeneralInfo);
