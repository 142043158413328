import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PlusCircle from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: 18,
    },
    allowAdd: {
        color: 'green',
        fontWeight: 'bold',
    },
    notAllowAdd: {
        color: 'red',
    },
    [theme.breakpoints.down('xs')]: {
        icon: {
            fontSize: 15,
            maxWidth: "15px",
        },
    }
}));

const AddCourseStatus = (props) => {
    const [addStatus, setAddStatus] = useState(null);
    const iconClasses = useStyles();

    useEffect(() => {
        //9 -> Group
        if (props.status === 1 || props.status === 9) {
            setAddStatus(
                <PlusCircle className={[iconClasses.icon, iconClasses.allowAdd]}></PlusCircle>
            );
        }
        else if (props.status === 2) {
            setAddStatus(<PlusCircle className={[iconClasses.icon, iconClasses.notAllowAdd]}></PlusCircle>);
        }
    }, [iconClasses.allowAdd, iconClasses.completed, iconClasses.icon, iconClasses.notAllowAdd, props.status])

    return (
        <React.Fragment>
            {addStatus}
        </React.Fragment>
    )
}


AddCourseStatus.propTypes = {
    status: PropTypes.number.isRequired,
};

export default AddCourseStatus;


