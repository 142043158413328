import React, { useEffect, useState } from "react";
import classes from "./AdviseeList.module.css";
import advisorAxios from "../../../../axios/BUAP/advisor";
import GetToken from "../../../../Shared/GetToken";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import CustomFilter from "./CustomFilter";
import PopupComponent from "../../../Registration/PopupComponent";
import StudentInfoForAdviseeList from "./StudentInfoForAdviseeList";
import Select from "react-select";
import { Button, Collapse } from "react-bootstrap";
import SendEmail from "./SendEmail";
import getSemester from "../../../../Functions/GetSemester";
const AdviseeList = (props) => {
  const [data, setData] = useState([]);
  const [filtereddata, setfiltereddata] = useState([]);

  ///Custom filter
  const [filteredFaculty, setfilteredFaculty] = useState(-1);
  const [filteredDepartment, setfilteredDepartment] = useState(-1);
  const [filteredChairperson, setfilteredChairperson] = useState(-1);
  const [term, setterm] = useState([]);
  const [defaultTerms, setdefaultTerms] = useState([]);
  const [isloading, setisloading] = useState(true);

  const [modalShow, setmodalShow] = useState(false);
  const [selectedStudent, setselectedStudent] = useState(0);

  //Filtered
  const [majorsFilterd, setmajorsFilterd] = useState([]);
  const [majorFilterd, setmajorFilterd] = useState("Majors");
  const [minorsFilterd, setminorsFilterd] = useState([]);
  const [minorFilterd, setminorFilterd] = useState("Minors");
  const [classificationsFilterd, setclassificationsFilterd] = useState([]);
  const [classificationFilterd, setclassificationFilterd] =
    useState("Classifications");
  const [advisorsFilterd, setadvisorsFilterd] = useState([]);
  const [advisorFilterd, setadvisorFilterd] = useState("Advisors");
  const [statusesFilterd, setstatusesFilterd] = useState([]);
  const [statuteFilterd, setstatuteFilterd] = useState("Registration statutes");

  const [probationsFilterd, setprobationsFilterd] = useState([]);
  const [probationFilterd, setprobationFilterd] = useState("Probation");

  const [studentName, setstudentName] = useState("");
  const [studentNum, setstudentNum] = useState("");

  const [moreinfo, setmoreinfo] = useState(false);

  //More info
  const [telephoneColumn, settelephoneColumn] = useState(false);
  const [mobileColumn, setmobileColumn] = useState(false);
  const [withdrawColumn, setwithdrawColumn] = useState(false);
  const [dismissedColumn, setdismissedColumn] = useState(false);
  const [lastattendedColumn, setlastattendedColumn] = useState(false);

  //Send emails
  const [sendEmails, setSendEmails] = useState(false);
  const [listOfSelectedStudents, setlistOfSelectedStudents] = useState([]);

  const [selectallchecks, setselectallchecks] = useState(false);

  useEffect(() => {
    //Default terms
    advisorAxios
      .get("DefaultTermsForAdviseeList/", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setterm(response.data[2].value);
        setdefaultTerms(response.data);
      });

    const link = "AdviseeList/" + props.username + "/-2/-2/-2/0/";
    advisorAxios
      .get(link, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setData(response.data);
        setfiltereddata(response.data);
        filtersData(response.data);
      });
  }, [props.username]);

  const setSelectedRow = (id, studentno, studeg) => {
    props.setstudentdegree(studeg);
    props.setstuno(studentno);
    setselectedStudent(studentno);
    setmodalShow(true);
  };

  const showStudentFile = (studentno, studeg) => {
    const win = window.open(
      "/advisee/showstudent?stuno=" + studentno + "&studeg=" + studeg,
      "_blank"
    );
    win.focus();
  };

  const viewData = () => {
    setisloading(true);
    const link =
      "AdviseeList/" +
      props.username +
      "/" +
      filteredFaculty +
      "/" +
      filteredDepartment +
      "/" +
      filteredChairperson +
      "/" +
      term +
      "/";
    advisorAxios
      .get(link, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setData(response.data);
        setfiltereddata(response.data);
        filtersData(response.data);
      });
  };

  const filtersData = (data) => {
    const majors = {};
    let minors = {};
    const classifications = {};
    const advisors = {};
    const statuses = {};
    for (let i = 0; i < data.length; i++) {
      const major = data[i].major;
      majors[major] = major;
      const minor = data[i].minor;
      if (minor === "") {
        minors[minor] = "No minor";
      } else {
        minors[minor] = minor;
      }
      const classification = data[i].classification;
      classifications[classification] = classification;

      const advisor = data[i].advisor;
      advisors[advisor] = advisor;

      const status = data[i].status;
      statuses[status] = status;
    }

    const majorsoptions = [{ value: "Majors", label: "Majors" }];
    for (const major in majors) {
      majorsoptions.push({ value: major, label: major });
    }
    setmajorsFilterd(majorsoptions);

    const minorsoptions = [{ value: "Minors", label: "Minors" }];
    for (const minor in minors) {
      minorsoptions.push({ value: minor, label: minor });
    }
    setminorsFilterd(minorsoptions);

    const classificationsoptions = [
      { value: "Classifications", label: "Classifications" },
    ];
    for (const classification in classifications) {
      classificationsoptions.push({
        value: classification,
        label: classification,
      });
    }
    setclassificationsFilterd(classificationsoptions);

    const advisorsoptions = [{ value: "Advisors", label: "Advisors" }];
    for (const advisor in advisors) {
      advisorsoptions.push({
        value: advisor,
        label: advisor,
      });
    }
    setadvisorsFilterd(advisorsoptions);
    const sem = getSemester(term);
    const statusesoptions = [
      {
        value: "Registration statutes " + sem,
        label: "Registration statutes " + sem,
      },
    ];
    for (const status in statuses) {
      statusesoptions.push({
        value: status,
        label: status,
      });
    }
    setstatuteFilterd("Registration statutes " + sem);
    setstatusesFilterd(statusesoptions);

    const probationOptions = [
      {
        value: "Probation",
        label: "Probation",
      },
    ];
    probationOptions.push({
      value: true,
      label: "True",
    });
    probationOptions.push({
      value: false,
      label: "False",
    });
    setprobationsFilterd(probationOptions);

    setisloading(false);
  };
  //Need to update this as a asyn with await for all lists
  const updateMinorsHandler = (e) => {
    setminorFilterd(e.value);
    setfiltereddata(
      data.filter(
        (v) =>
          (v.major === majorFilterd || majorFilterd === "Majors") &&
          (v.minor === e.value || e.value === "Minors") &&
          (v.classification === classificationFilterd ||
            classificationFilterd === "Classifications") &&
          (v.advisor === advisorFilterd || advisorFilterd === "Advisors") &&
          (v.onProbation === probationFilterd ||
            probationFilterd === "Probation") &&
          (v.status === statuteFilterd ||
            statuteFilterd.includes("Registration statutes"))
      )
    );
  };

  const updateClassificationsHandler = (e) => {
    setclassificationFilterd(e.value);
    setfiltereddata(
      data.filter(
        (v) =>
          (v.major === majorFilterd || majorFilterd === "Majors") &&
          (v.minor === minorFilterd || minorFilterd === "Minors") &&
          (v.classification === e.value || e.value === "Classifications") &&
          (v.advisor === advisorFilterd || advisorFilterd === "Advisors") &&
          (v.onProbation === probationFilterd ||
            probationFilterd === "Probation") &&
          (v.status === statuteFilterd ||
            statuteFilterd.includes("Registration statutes"))
      )
    );
  };

  const updateAdvisorsHandler = (e) => {
    setadvisorFilterd(e.value);
    setfiltereddata(
      data.filter(
        (v) =>
          (v.major === majorFilterd || majorFilterd === "Majors") &&
          (v.minor === minorFilterd || minorFilterd === "Minors") &&
          (v.classification === classificationFilterd ||
            classificationFilterd === "Classifications") &&
          (v.advisor === e.value || e.value === "Advisors") &&
          (v.onProbation === probationFilterd ||
            probationFilterd === "Probation") &&
          (v.status === statuteFilterd ||
            statuteFilterd.includes("Registration statutes"))
      )
    );
  };

  const updateProbationHandler = (e) => {
    setprobationFilterd(e.value);
    setfiltereddata(
      data.filter(
        (v) =>
          (v.major === majorFilterd || majorFilterd === "Majors") &&
          (v.minor === minorFilterd || minorFilterd === "Minors") &&
          (v.classification === classificationFilterd ||
            classificationFilterd === "Classifications") &&
          (v.advisor === advisorFilterd || advisorFilterd === "Advisors") &&
          (v.onProbation === e.value || e.value === "Probation") &&
          (v.status === statuteFilterd ||
            statuteFilterd.includes("Registration statutes"))
      )
    );
  };

  const updateStatuesHandler = (e) => {
    setstatuteFilterd(e.value);
    setfiltereddata(
      data.filter(
        (v) =>
          (v.major === majorFilterd || majorFilterd === "Majors") &&
          (v.minor === minorFilterd || minorFilterd === "Minors") &&
          (v.classification === classificationFilterd ||
            classificationFilterd === "Classifications") &&
          (v.advisor === advisorFilterd || advisorFilterd === "Advisors") &&
          (v.onProbation === probationFilterd ||
            probationFilterd === "Probation") &&
          (v.status === e.value || e.value.includes("Registration statutes"))
      )
    );
  };

  const updateMajorsHandler = (e) => {
    setmajorFilterd(e.value);
    setfiltereddata(
      data.filter(
        (v) =>
          (v.major === e.value || e.value === "Majors") &&
          (v.minor === minorFilterd || minorFilterd === "Minors") &&
          (v.classification === classificationFilterd ||
            classificationFilterd === "Classifications") &&
          (v.advisor === advisorFilterd || advisorFilterd === "Advisors") &&
          (v.onProbation === probationFilterd ||
            probationFilterd === "Probation") &&
          (v.status === statuteFilterd ||
            statuteFilterd.includes("Registration statutes"))
      )
    );
  };

  const filterNum = (e) => {
    setstudentNum(e.target.value);

    if (e.target.value === "") {
      setfiltereddata(data);
    } else {
      const num = e.target.value;
      const updatedData = data.filter((v) => v.stuNo.indexOf(num) > -1);
      setfiltereddata(updatedData);
    }
  };

  const filtername = (e) => {
    setstudentName(e.target.value);

    if (e.target.value === "") {
      setfiltereddata(data);
    } else {
      const name = e.target.value;
      const updatedData = data.filter(
        (v) => v.studentName.toLowerCase().indexOf(name.toLowerCase()) > -1
      );
      setfiltereddata(updatedData);
    }
  };

  const updateSelected = () => {
    const table = document.getElementById("table");
    const checkBoxes = table.getElementsByTagName("input");
    const listOfStudents = [];
    for (let i = 1; i < checkBoxes.length; i++) {
      if (checkBoxes[i].checked) {
        const row = checkBoxes[i].parentNode.parentNode;
        const studentnum = row.cells[2].innerHTML
          .replace("<button>", "")
          .replace("</button>", "");
        listOfStudents.push(studentnum);
      }
    }
    setlistOfSelectedStudents(listOfStudents);
  };

  const sendEmailsHandler = () => {
    updateSelected();

    setSendEmails(true);
  };

  const selectall = () => {
    const slected = selectallchecks;
    setselectallchecks(!slected);
    const allInputs = document.getElementsByTagName("input");
    for (let i = 0, max = allInputs.length; i < max; i++) {
      if (allInputs[i].type === "checkbox") {
        if (!slected) {
          allInputs[i].checked = true;
        } else {
          allInputs[i].checked = false;
        }
      }
    }
  };

  return (
    <div className={classes.container}>
      <PopupComponent
        show={modalShow}
        header={selectedStudent}
        onHide={() => setmodalShow(false)}
        size="xl"
      >
        <StudentInfoForAdviseeList
          studentno={selectedStudent}
        ></StudentInfoForAdviseeList>
      </PopupComponent>
      <PopupComponent
        show={sendEmails}
        header={"Send emails"}
        onHide={() => setSendEmails(false)}
        size="xl"
      >
        <SendEmail listOfStudents={listOfSelectedStudents} />
      </PopupComponent>

      <CustomFilter
        faculty={setfilteredFaculty}
        departement={setfilteredDepartment}
        chairperson={setfilteredChairperson}
        setterm={setterm}
        viewData={viewData}
        selectedterm={term}
      />

      {isloading ? (
        <b title="الرجاء الانتظار حتى يتم تحميل الصفحة ...">
          Please wait while data is loading ...<Spinner></Spinner>{" "}
        </b>
      ) : (
        <div>
          <div>
            <p
              style={{
                paddingLeft: "20px",
                fontSize: "14px",
                fontWeight: "normal",
                letterSpacing: "1px",
              }}
              title="الطلبة المسجلين بالفصل الحالي أو السابق"
            >
              Include All students registered on this semester{" "}
              <b>{defaultTerms[0].label}</b> or they were registered on the
              previous semester <b>{defaultTerms[1].label}</b> (The total number
              of students: <b>{data.length}</b> ) / (filtered students:{" "}
              <b>{filtereddata.length}</b> )
            </p>
            <Button onClick={sendEmailsHandler} aria-controls="sendEmails">
              Send emails
            </Button>
          </div>
          <div style={{ textAlign: "right" }}>
            <input
              type="button"
              onClick={() => setmoreinfo(!moreinfo)}
              value="Add more info"
            />

            <Collapse in={moreinfo}>
              <div id="addColumns">
                <input
                  name="telephoneColumn"
                  type="checkbox"
                  checked={telephoneColumn}
                  onChange={() => settelephoneColumn(!telephoneColumn)}
                />{" "}
                Telephone{" "}
                <span className={classes.paddingInput}>
                  <input
                    name="mobileColumn"
                    type="checkbox"
                    checked={mobileColumn}
                    onChange={() => setmobileColumn(!mobileColumn)}
                  />{" "}
                  Mobile{" "}
                </span>
                <span className={classes.paddingInput}>
                  <input
                    name="withdrawnSemesterColumn"
                    type="checkbox"
                    checked={withdrawColumn}
                    onChange={() => setwithdrawColumn(!withdrawColumn)}
                  />{" "}
                  Withdrawn semester{" "}
                </span>
                <span className={classes.paddingInput}>
                  <input
                    name="dismissedSemesterColumn"
                    type="checkbox"
                    checked={dismissedColumn}
                    onChange={() => setdismissedColumn(!dismissedColumn)}
                  />{" "}
                  Dismissed semester{" "}
                </span>
                <span className={classes.paddingInput}>
                  <input
                    name="lastAttendedSemesterColumn"
                    type="checkbox"
                    checked={lastattendedColumn}
                    onChange={() => setlastattendedColumn(!lastattendedColumn)}
                  />{" "}
                  Last attended semester
                </span>
              </div>
            </Collapse>
          </div>

          <table className={classes.advtable} id="table">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" onClick={selectall} />
                </th>
                <th scope="col">#</th>
                <th scope="col">
                  <input
                    type="text"
                    value={studentNum}
                    placeholder="Number"
                    style={{ width: "70px" }}
                    onChange={filterNum}
                  />
                </th>
                <th scope="col">
                  <input
                    type="text"
                    placeholder="Student name"
                    style={{ width: "220px" }}
                    value={studentName}
                    onChange={filtername}
                  />
                </th>
                <th scope="col">
                  <Select
                    defaultValue={-1}
                    options={majorsFilterd}
                    onChange={updateMajorsHandler}
                    value={majorsFilterd.filter(
                      (option) => option.value === majorFilterd
                    )}
                  />
                </th>
                <th scope="col">
                  <Select
                    defaultValue={-1}
                    options={minorsFilterd}
                    onChange={updateMinorsHandler}
                    value={minorsFilterd.filter(
                      (option) => option.value === minorFilterd
                    )}
                  />
                </th>
                <th scope="col">
                  <Select
                    defaultValue={-1}
                    options={classificationsFilterd}
                    onChange={updateClassificationsHandler}
                    value={classificationsFilterd.filter(
                      (option) => option.value === classificationFilterd
                    )}
                  />
                </th>
                <th scope="col">
                  <Select
                    defaultValue={-1}
                    options={advisorsFilterd}
                    onChange={updateAdvisorsHandler}
                    value={advisorsFilterd.filter(
                      (option) => option.value === advisorFilterd
                    )}
                  />
                </th>
                <th scope="col">
                  <Select
                    defaultValue={-1}
                    options={probationsFilterd}
                    onChange={updateProbationHandler}
                    value={probationsFilterd.filter(
                      (option) => option.value === probationFilterd
                    )}
                  />
                </th>
                <th scope="col">
                  <Select
                    defaultValue={-1}
                    options={statusesFilterd}
                    onChange={updateStatuesHandler}
                    value={statusesFilterd.filter(
                      (option) => option.value === statuteFilterd
                    )}
                  />
                </th>
                {telephoneColumn ? <th scope="col">Telephone</th> : null}
                {mobileColumn ? <th scope="col">Mobile</th> : null}
                {withdrawColumn ? <th scope="col">Withdrawn</th> : null}
                {dismissedColumn ? <th scope="col">Dismissed</th> : null}
                {lastattendedColumn ? <th scope="col">Last attended</th> : null}
              </tr>
            </thead>
            <tbody>
              {filtereddata.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>{index + 1}</td>
                  <td>
                    <button
                      onClick={() =>
                        setSelectedRow(row.id, row.stuNo, row.stuDeg)
                      }
                    >
                      {row.stuNo}
                    </button>
                  </td>
                  <td>{row.studentName}</td>
                  <td>{row.major}</td>
                  <td>{row.minor}</td>
                  <td>{row.classification}</td>
                  <td>{row.advisor}</td>
                  <td>{row.onProbation ? "True" : "False"}</td>
                  <td>
                    <button
                      onClick={() => showStudentFile(row.stuNo, row.stuDeg)}
                    >
                      {row.status}
                    </button>
                  </td>
                  {telephoneColumn ? <td>{row.phone}</td> : null}
                  {mobileColumn ? <td>{row.mobile}</td> : null}
                  {withdrawColumn ? <td>{row.withdrawnSemester}</td> : null}
                  {dismissedColumn ? <td>{row.dismissedSemester}</td> : null}
                  {lastattendedColumn ? (
                    <td>{row.lastAttendedSemester}</td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

//Redux
const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setstudentdegree: (e) => dispatch(actionCreators.setstudentdegree(e)),
    setstuno: (e) => dispatch(actionCreators.setstudentno(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdviseeList);
