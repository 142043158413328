import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Select from "react-select";

import { Col, Form, Row } from "react-bootstrap";
import axios from "../../../../axios/BUAP/shared";
import GetToken from "../../../../Shared/GetToken";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10px",
  },
  whatifDesc: {
    color: "red",
    fontWeight: "bold",
  },
  [theme.breakpoints.down("xs")]: {
    table: {
      maxWidth: "100%",
      minWidth: 0,
      "& td, th": {
        padding: 8,
        fontSize: 13,
      },
    },
  },
}));

const WhatIf = (props) => {
  const classes = useStyles();
  const [major, setMajor] = useState(-1);
  const [majoroptions, setMajorOptions] = useState([]);
  const [minor, setMinor] = useState(-1);
  const [minoroptions, setMinorOptions] = useState([]);
  const [posminor, setPOSMinor] = useState(-1);
  const [posminoroptions, setPOSMinorOptions] = useState([]);
  const [showminor, setshowminor] = useState(false);

  useEffect(() => {
    axios
      .get("WhatIfMajors/", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setMajorOptions(response.data);
      });
    // axios
    //   .get("WhatIfMinors/", {
    //     headers: { Authorization: GetToken("token") },
    //   })
    //   .then((response) => {
    //     setMinorOptions(response.data);
    //   });
  }, []);

  const handleMajor = (e) => {
    const major = e.value;
    setMajor(major);
    const hasMinor = majoroptions.find((c) => c.value === major).hasMinor;
    setMinor(0);
    setPOSMinor(0);
    if (hasMinor) {
      setshowminor(true);
      axios
        .get("WhatIfMinors/", {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setMinorOptions(response.data);
        });
    } else {
      setshowminor(false);
      setMinorOptions([]);
      props.whatIFHandle(major, -1);
    }
  };

  const handleMinor = (e) => {
    setMinor(e.value);
    setPOSMinor(-1);
    axios
      .get("WhatIfPOSMinors/" + e.value, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setPOSMinorOptions(response.data);
        if (response.data.length === 1) {
          setPOSMinor(response.data[0].value);
          props.whatIFHandle(major, response.data[0].value);
        }
      });
  };

  const handlePOSMinor = (e) => {
    setPOSMinor(e.value);
    props.whatIFHandle(major, e.value);
  };
  return (
    <div className={classes.root}>
      <span className={classes.whatifDesc}>
        The “what if” tool will roughly show your degree progress in case you
        transferred to another Major or Minor. This tool is not official POS,
        and there is no guarantee that your transfer application will be
        accepted.
      </span>
      <Container>
        <Row>
          <Col>
            <Form.Label>Major:</Form.Label>
            <Select
              defaultValue={-1}
              options={majoroptions}
              onChange={handleMajor}
              value={majoroptions.filter((option) => option.value === major)}
            />
          </Col>
          {showminor ? (
            <>
              <Col>
                <Form.Label>Minor:</Form.Label>
                <Select
                  defaultValue={-1}
                  onChange={handleMinor}
                  options={minoroptions}
                  value={minoroptions.filter(
                    (option) => option.value === minor
                  )}
                />
              </Col>

              <Col>
                <Form.Label>POS of Minor:</Form.Label>
                <Select
                  defaultValue={-1}
                  onChange={handlePOSMinor}
                  options={posminoroptions}
                  value={posminoroptions.filter(
                    (option) => option.value === posminor
                  )}
                />
              </Col>
            </>
          ) : null}
        </Row>
      </Container>
    </div>
  );
};

export default WhatIf;
