import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState = {
    studentno: 0,
};

const studentnoReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETSTUDENTNO:
            return {
                ...state,
                studentno: action.value
            }
        default:
            return state;
    }
}

export default studentnoReducer;