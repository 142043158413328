import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PalPayImage from "../../../../assets/images/palpay.png";
const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    borderStyle: "outset;",
    width: "365px",
    margin: "auto",
    marginTop: "10px",
  },
});

class PALPAY extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <div style={{ textAlign: "center" }}>
          <Typography>
            <a
              href="https://docs.google.com/spreadsheets/d/1vUR1XsJW1qvWWsgL_CLRoz_HWsfQYJuOO72IxR6hGKY/edit#gid=1228703548"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Click here for the list of PalPay locations</strong>
            </a>
          </Typography>
        </div>

        <div>
          <img src={PalPayImage} alt="PalPay" />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(PALPAY);
