import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Semester from '../../../../Shared/Semester';
import ReactHtmlParser from 'react-html-parser';
import axios from '../../../../../axios/BUAP/students';
import { connect } from 'react-redux';
import GetToken from '../../../../../Shared/GetToken';
import SpinnerBootstrap from '../../../../UI/Spinner/Spinner';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#708090',
    color: 'white',
    fontWeight: "bold",
    padding: 6
  },
  normalFont: {
    fontWeight: "normal",
  },
  footer: {
    backgroundColor: '#FFF5EE',
    color: 'black',
    padding: 5,
    paddingLeft: 10,
    textAlign: 'left',
  },
  row: {
    padding: 0,
  },
  msg: {
    fontSize: 12
  },
  courseKey: {
    paddingLeft: 10,
    width: '10%',
  },
  tableRowsFontSize: {
    fontSize: '0.72rem'
  },
  [theme.breakpoints.down('xs')]: {
    courseKey: {
      width: 'auto',
    },
    courseTitle: {
      display: 'none'
    },
  },
  verticalAlligned: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '8em'
  }
}));


const TranscriptTerm = (props) => {
  const classes = useStyles();

  const [courses, setCourses] = useState([]);
  const [waitData, setWaitData] = useState(true);
  useEffect(() => {
    axios.get('TranscriptCourses/' + props.term + "/" + props.studentdegree, {
      headers: { 'Authorization': GetToken("token") }
    })
      .then(response => {
        setCourses(response.data);
        setWaitData(false);
      });
  }, [props.studentdegree, props.term]);


  return (
    <React.Fragment >
      {waitData ? <SpinnerBootstrap /> :
        <TableContainer component={Paper}>
          <Paper className={classes.paper} elevation={0} square><Semester sem={props.term}></Semester> / <span className={classes.normalFont}>{props.majorText}
          </span>{props.minorText ? <span className={classes.normalFont}> / {props.minorText}</span> : null}</Paper>
          <Table aria-label="simple table" padding="none">
            <TableHead>
              <TableRow>
                <TableCell className={classes.courseKey}>Course</TableCell>
                <TableCell className={classes.courseTitle}>Course Title</TableCell>
                <TableCell align="center">Hrs</TableCell>
                <TableCell align="left">Grade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >

              {Object.keys(courses).map((key, index) => {
                return (

                  <TableRow key={index} className={classes.row} >
                    <TableCell className={[classes.courseKey, classes.tableRowsFontSize]} component="th" scope="row" >
                      {courses[key].course}
                    </TableCell>
                    <TableCell className={[classes.courseTitle, classes.tableRowsFontSize]}>{courses[key].courseTitle}</TableCell>
                    <TableCell align="center" className={classes.tableRowsFontSize}>{courses[key].hrs}</TableCell>
                    <TableCell className={[classes.verticalAlligned, classes.tableRowsFontSize]}>{courses[key].grade}</TableCell>
                  </TableRow>

                )
              })}
            </TableBody>
          </Table>
          <Paper className={classes.footer} elevation={0} square>
            <Typography className={classes.msg}>
              <b>CRS:</b>  Attem: {props.crsAttem} | Earned:{props.crsEarned} | Cum. Earned:{props.crsCumEarned}
            </Typography>
            <Typography className={classes.msg}>
              <b>GPA:</b>  Semester:{props.gpaSemester == null ? '0.00' : props.gpaSemester.toFixed(2)}
              | Major: {props.gpaMajor == null ? '0.00' : props.gpaMajor.toFixed(2)}
              | Cum. Earned:{props.gpaCumEarned == null ? '0.00' : props.gpaCumEarned.toFixed(2)}
            </Typography>
            <Typography className={classes.msg}>
              <b>Message: </b>{ReactHtmlParser(props.message)}
            </Typography>
          </Paper>
        </TableContainer>}</React.Fragment>
  );
}




const mapStateToProps = state => {
  return {
    studentdegree: state.studeg.studentdegree,
  }
}

export default (connect(mapStateToProps)(TranscriptTerm));