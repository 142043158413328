import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactHtmlParser from "react-html-parser";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { connect } from "react-redux";
import axios from "../../../../axios/BUAP/registration";
import cartAxios from "../../../../axios/BUAP/cart";
import ParadigmIcon from "../../../BUAP/Student/Paradigm/ParadigmIcon";
import GetToken from "../../../../Shared/GetToken";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";
import { Alert } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import addCartStatus from "../../../../Shared/CourseStatus";

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: 10,
  },
  changeSection: {
    padding: 10,
  },
  msg: {
    padding: 10,
  },
  [theme.breakpoints.down("xs")]: {
    table: {
      maxWidth: "100%",
      minWidth: 0,
      "& td, th": {
        padding: 8,
        fontSize: 13,
      },
    },
  },
}));

const StyledTableRow = withStyles((theme) => ({
  /*root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },*/
}))(TableRow);

const ListOfSections = (props) => {
  const [data, setData] = useState({
    catalogKey: "",
    cnt: 0,
    comments: "",
    groupCode: -1,
    incart: -1,
    maxNoOfStudents: 0,
    offeredCode: 0,
    registered: "",
    resGroup: false,
    restricted: false,
    section: "",
    sectionTime: "",
    teacher: "",
  });

  const [offeredSectionSelected, SetOfferedSection] = React.useState(0);
  const [waitData, setWaitData] = useState(true);
  const [msg, setMessage] = useState("");

  useEffect(() => {
    axios
      .get("OfferedSections/" + props.studentdegree + "/" + props.coursecode, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setData(response.data);
        setWaitData(false);
      });
  }, [props.studentdegree, props.coursecode]);
  const classes = useStyles();
  const changeSectionHandler = (cskey) => {
    setMessage("");
    if (data[cskey].cnt >= data[cskey].maxNoOfStudents) {
      alert("This section is closed! You can't add it to the cart");
    } else if (data[cskey].registered) {
      alert("This section has already registered!");
    } else if (data[cskey].offeredCode === data[cskey].incart) {
      alert("The course is alreay in the cart!");
    } else {
      SetOfferedSection(data[cskey].offeredCode);
    }
  };

  const clickChangeSectionHandler = (offeredCourse, max, cnt) => {
    if (!window.confirm("Would you like to add the course?")) {
      return;
    }
    setMessage("");
    const payload = {
      inStuDeg: props.studentdegree,
      inOfferedCourse: offeredCourse,
      inCourseCode: props.coursecode,
      inMax: max,
      actualCredits: props.actualCredits,
      inRegistered: cnt,
      inUsername: props.username,
    };
    cartAxios
      .post("AddSection", payload, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        if (addCartStatus(response.data[0])) {
          props.setupdatecart(props.updatecart + 1);
          if (props.usertype === "0") {
            props.setupcoursecart({
              courseCode: props.coursecode,
              status: "INCART",
            });
          } else {
            props.setupcoursecart({
              courseCode: props.coursecode,
              status: "Approved",
            });
          }
          //Update data
          let updatedData = data;
          const arrayLength = updatedData.length;
          for (let i = 0; i < arrayLength; i++) {
            //Need to review this part, why we don't take status 1, ...
            updatedData[i].incart = payload.inOfferedCourse;
          }

          setData(updatedData);
        
          if (response.data[1] === "True" || response.data[0] === "OutOfPOS" || response.data[0] === "Success" ) {
            props.setupdatecart(-1);
          }
        }
      });
  };
  return (
    <React.Fragment>
      {waitData ? (
        <SpinnerBootstrap />
      ) : (
        <React.Fragment>
          {msg === "" ? (
            <TableContainer component={Paper}>
              <Table
                size="small"
                fontSize={100}
                className={classes.table}
                aria-label="spanning table"
                padding="none"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Section</TableCell>
                    <TableCell
                      style={{ paddingLeft: "3px", minWidth: "200px" }}
                    >
                      Time
                    </TableCell>
                    {/* <TableCell align="center">Registered</TableCell>
                    <TableCell align="center" style={{ paddingLeft: "3px" }}>
                      Max
                    </TableCell> */}
                    <TableCell align="center" style={{ paddingLeft: "3px" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" style={{ paddingLeft: "3px" }}>
                      Comments
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(data).map((key, index) => {
                    return (
                      <StyledTableRow
                        key={index}
                        hover={true}
                        onClick={() => changeSectionHandler(key)}
                        selected={
                          offeredSectionSelected === data[key].offeredCode
                            ? true
                            : false
                        }
                      >
                        <TableCell align="center">
                          {data[key].section}
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "500", fontSize: "13px" }}
                        >
                          <b>{data[key].teacher}</b>
                          <br />
                          {ReactHtmlParser(data[key].sectionTime)}
                        </TableCell>
                        {/* <TableCell align="center">{data[key].cnt}</TableCell>
                        <TableCell align="center">
                          {data[key].maxNoOfStudents}
                        </TableCell> */}
                        <TableCell align="center">
                          {data[key].cnt < data[key].maxNoOfStudents &&
                          (data[key].incart === undefined ||
                            data[key].incart === "" ||
                            data[key].incart === -1) &&
                          data[key].registered === 0 ? (
                            <button
                              onClick={() =>
                                clickChangeSectionHandler(
                                  data[key].offeredCode,
                                  data[key].maxNoOfStudents,
                                  data[key].cnt
                                )
                              }
                            >
                              <PlusCircleFill style={{ color: "green" }} />
                            </button>
                          ) : (
                            <ParadigmIcon
                              available={
                                data[key].cnt < data[key].maxNoOfStudents
                              }
                              status={data[key].incart}
                              registered={data[key].registered}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {data[key].comments}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert variant="primary">{msg}</Alert>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    updatecart: state.ucart.updatecart,
    usertype: state.utype.usertype,
    username: state.uname.username,
  };
};

//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setupdatecart: (e) => dispatch(actionCreators.setupdatecart(e)),
    setupcoursecart: (e) => dispatch(actionCreators.setcoursecart(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListOfSections);
