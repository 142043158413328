import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import PopupComponent from "../../../containers/Registration/PopupComponent";
import ListOfSections from "../../../containers/Registration/Cart/CartCourses/ListOfSections";
import axios from "../../../axios/BUAP/registration";
import GetToken from "../../../Shared/GetToken";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/BUAP/BUAP";

const notificationsStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  [theme.breakpoints.down("xs")]: {
    header: {
      fontSize: 13,
    },
    alert: {
      fontSize: 12,
    },
  },
}));

const NotificationAndNeedattentions = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [courseCode, setCourseCode] = useState(false);
  const [courseCatalog, setCourseCatalog] = useState(false);
  const [needAttentionsFiltered, setNeedAttentionsFiltered] = useState(
    props.needAttentions
  );

  const notificationsStyle = notificationsStyles();

  const showCourse = (courseCode, header) => {
    setCourseCatalog(header);
    setCourseCode(courseCode);
    setModalShow(true);
  };

  useEffect(() => {
    setNeedAttentionsFiltered(props.needAttentions);
  }, [props.needAttentions]);

  const bypassAttention = (courseCode) => {
    const payload = {
      inStuDeg: props.studentdegree,
      inCourseCode: courseCode,
    };

    axios
      .post("BypassAttention/", payload, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        var filteredData = needAttentionsFiltered.filter(function (obj) {
          return obj["courseCode"] !== courseCode;
        });
        setNeedAttentionsFiltered(filteredData);
        props.setupdatecart(-1);
      });
  };

  return (
    <div className={notificationsStyle.root}>
      {props.notifiaction.length > 0 ? (
        <Alert severity="error" className={notificationsStyle.alert}>
          <AlertTitle className={notificationsStyle.header}>
            Student registration is incomplete because:
          </AlertTitle>
          {props.notifiaction.map((row, index) => (
            <div key={index}>
              {ReactHtmlParser(row.desc)}
              {row.courseCode !== -1 && row.offered ? (
                <span>
                  <Button
                    variant="primary"
                    onClick={() => showCourse(row.courseCode, row.catalogKey)}
                  >
                    Add {row.catalogKey} course
                  </Button>
                </span>
              ) : null}
            </div>
          ))}
        </Alert>
      ) : null}

      {needAttentionsFiltered.length > 0 ? (
        <Alert severity="warning" className={notificationsStyle.alert}>
          <AlertTitle className={notificationsStyle.header}>
            Need attentions:
          </AlertTitle>
          {needAttentionsFiltered.map((row, index) => (
            <React.Fragment>
              <div key={index}>
                <b style={{ fontSize: 16 }}>{ReactHtmlParser(row.desc)}</b>
                {row.courseCode !== -1 && row.offered ? (
                  <span>
                    <span>
                      <Button
                        variant="primary"
                        onClick={() =>
                          showCourse(row.courseCode, row.catalogKey)
                        }
                      >
                        Add {row.catalogKey} course
                      </Button>
                    </span>
                    <span> </span>
                  </span>
                ) : null}
                {props.usertype !== "0" && props.usertype !== 0 ? (
                  <Button
                    variant="secondary"
                    onClick={() => bypassAttention(row.courseCode)}
                  >
                    Bypass Attention ({row.catalogKey})
                  </Button>
                ) : null}
              </div>
              <br />
            </React.Fragment>
          ))}
        </Alert>
      ) : null}
      <PopupComponent
        show={modalShow}
        header={courseCatalog}
        onHide={() => setModalShow(false)}
        size="l"
      >
        <ListOfSections
          coursecode={courseCode}
          actualCredits={0}
          buttonTitle="Add section"
        />
      </PopupComponent>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupdatecart: (e) => dispatch(actionCreators.setupdatecart(e)),
  };
};

export default connect(null, mapDispatchToProps)(NotificationAndNeedattentions);
