import React from "react";

const Error = () => {
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "25px",
        paddingTop: "35px",
      }}
    >
      An error has been occured!
    </div>
  );
};

export default Error;
