import React, { useState, useEffect } from 'react';
import axios from '../../axios/BUAP/cart';
import GetToken from '../../Shared/GetToken';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Semester from '../../components/Shared/Semester';

const useStyles = makeStyles((theme) => ({
    studentStatus: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingTop: 0,
        color: 'green'
    },
    [theme.breakpoints.down('xs')]: {
        studentStatus: {
            fontSize: 13,
        },
    }
}));

const GetStudentStatus = (props) => {
    const classes = useStyles();
    const [semester, setSemester] = useState('');
    const [status, setStatus] = useState('');
    //const preStuDeg=usePrevious("studentdegree");
    useEffect(() => {
        axios.get("MinMaxStatus/" + props.studentdegree + "/" + props.usertype + "/false", {
            headers: { 'Authorization': GetToken("token") }
        }).then(
            response => {
                setStatus(response.data.status);
                setSemester(<Semester sem={response.data.currentTerm} />);
            },
        );
    }, [props.studentdegree, props.usertype]);

    return (
        //Add filter for dismissed
        <Typography className={classes.studentStatus}>
            {semester}: {status}
        </Typography>
    )
}

const mapStateToProps = state => {
    return {
        studentdegree: state.studeg.studentdegree,
        usertype: state.utype.usertype,
    }
}


export default (connect(mapStateToProps))(GetStudentStatus);