import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import PersonalInfo from "../../../../components/BUAP/Student/StudentInfo/PersonalInfo";
import DegreeCalculations from "../../../../components/BUAP/Student/POS/DegreeCalculations/DegreeCalculations";
import GroupedTable from "../../../../components/Shared/Tables/GroupedTable/GroupedTable";
import axios from "../../../../axios/BUAP/students";
import axioscommon from "../../../../axios/BUAP/common";
import { connect } from "react-redux";
import POSFooter from "../../../../components/BUAP/Student/POS/Footer/POSFooter";
import DegreeCalculation from "../../../../components/BUAP/Student/POS/DegreeCalculations/DegreeCalculation/DegreeCalculation";
import GetToken from "../../../../Shared/GetToken";
import classes from "./POS.module.css";
import TransferCourses from "../../../../components/BUAP/Student/POS/TransferCourses";
import { Alert, Button, Col, Collapse, Row } from "react-bootstrap";
import WhatIf from "../../../../components/BUAP/Student/POS/WhatIf";
import { AlertTitle } from "@material-ui/lab";
import Blocked from "../Blocked";
import PrintIcon from "@mui/icons-material/Print";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";

const columns = [
  { dataKey: "requirement", title: "Requirement" },
  { dataKey: "course", title: "Course catalog" },
  {
    dataKey: "title",
    title: "Title",
    className: "GroupedTableMobile",
    itemsStyle: "GroupedTableMobile",
  },
  { dataKey: "credits", title: "Credits" },
  { dataKey: "grade", title: "Grade" },
  { dataKey: "term", title: "Term" },
];

class POS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrerequisite: false,
      showRelatedTo: false,
      showOfferedIn: false,
      expandedAll: true,
      data: [
        {
          requirement: "",
          course: "",
          title: "",
          catalogKey: "",
          grade: "",
          term: "",
          prerequisite: "",
          relatedTo: "",
          offeredIn: "",
          status: "",
        },
      ],
      footer: "",
      hasMinor: false,
      minor: [
        {
          title: "Minor",
          sum: 0,
          outOf: 0,
          completed: 0,
          current: 0,
          planned: 0,
          gpa: 0,
        },
      ],
      major: [
        {
          title: "Major",
          sum: 0,
          outOf: 0,
          completed: 0,
          current: 0,
          planned: 0,
          gpa: 0,
        },
      ],
      hasElective: false,
      elective: [
        {
          title: "General electives",
          sum: 0,
          outOf: 0,
          completed: 0,
          current: 0,
          planned: 0,
          gpa: 0,
        },
      ],
      degree: [
        {
          title: "Degree",
          sum: 0,
          outOf: 0,
          completed: 0,
          current: 0,
          planned: 0,
          gpa: 0,
        },
      ],
      total: [
        {
          title: "Total",
          sum: 0,
          outOf: 0,
          completed: 0,
          current: 0,
          planned: 0,
          gpa: 0,
        },
      ],
      posWorkingTerm: 0,
      columnsopen: false,
      loadWhatIF: null,
      showWhatIF: false,
      showPOS: true,
      printPOS: "",
      waitData: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleexpandedAll = this.handleexpandedAll.bind(this);
  }
  componentDidMount() {
    axios
      .get("ProgramOfStudy/" + this.props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        var isTrueSet = response.data._posFrontend.hasMinor === true;
        this.setState({
          data: response.data._posFrontend.pos,
          footer: response.data._posFrontend.footer,
          hasMinor: isTrueSet,
          minor: response.data._posFrontend.minorDegree,
          major: response.data._posFrontend.majorDegree,
          hasElective: response.data._posFrontend.hasElective,
          elective: response.data._posFrontend.electiveDegree,
          degree: response.data._posFrontend.totalDegree,
          total: response.data._posFrontend.totalCredits,
          posWorkingTerm: response.data._posFrontend.posWorkingTerm,
          showPOS: true,
          waitData: false,
        });
      })
      .catch((err) => {
        this.setState({ showPOS: false, waitData: false });
      });
  }

  handleInputChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value,
    });

    if (value) {
      document
        .querySelectorAll(".pos" + name)
        .forEach((a) => (a.style.display = "initial"));
    } else {
      document
        .querySelectorAll(".pos" + name)
        .forEach((a) => (a.style.display = "none"));
    }
  };

  handleexpandedAll(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      expandedAll: value,
    });
  }

  whatIFHandle = (major, posMinor) => {
    const url =
      "WhatIf/" + major + "/" + posMinor + "/" + this.props.studentdegree;

    axios
      .get(url, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        const isTrueSet = response.data._posFrontend.hasMinor === true;
        this.setState({
          data: response.data._posFrontend.pos,
          footer: response.data._posFrontend.footer,
          hasMinor: isTrueSet,
          minor: response.data._posFrontend.minorDegree,
          major: response.data._posFrontend.majorDegree,
          hasElective: response.data._posFrontend.hasElective,
          elective: response.data._posFrontend.electiveDegree,
          degree: response.data._posFrontend.totalDegree,
          total: response.data._posFrontend.totalCredits,
          posWorkingTerm: response.data._posFrontend.posWorkingTerm,
          showWhatIF: true,
        });
      });
  };

  showColumnNames() {
    const columnsopen = this.state.columnsopen;
    this.setState({ columnsopen: !columnsopen });
    if (!columnsopen) {
      this.setState({
        loadWhatIF: <WhatIf whatIFHandle={this.whatIFHandle} />,
      });
    }
  }

  printPOS = async (e) => {
    let user = "";
    let code = "";
    await axioscommon
      .get("EncryptTxt/" + this.props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        code = response.data;
      });
    await axioscommon
      .get("EncryptTxt/" + this.props.stuno, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        user = response.data;
      });
    const url =
      "https://reg.bethlehem.edu/student/PrintPOS.aspx?code=" +
      code +
      "&user=" +
      user +
      "&sys=2";
    const win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.waitData ? (
          <SpinnerBootstrap />
        ) : (
          <React.Fragment>
            {this.state.showPOS ? (
              <Container>
                <PrintIcon onClick={() => this.printPOS()}></PrintIcon>
                <PersonalInfo stuNo={this.props.stuno} show={false} />
                <TransferCourses stuDeg={this.props.studentdegree} />
                {/* <RegisteredCourses sem={this.state.posWorkingTerm} type={3} /> */}
                <Row>
                  <Col>
                    <Button
                      onClick={() => this.showColumnNames()}
                      aria-controls="whatif"
                      aria-expanded={this.state.columnsopen}
                    >
                      What if
                    </Button>
                  </Col>
                </Row>
                <Collapse in={this.state.columnsopen}>
                  <div id="whatif">{this.state.loadWhatIF}</div>
                </Collapse>
                <br />
                <input
                  name="showPrerequisite"
                  type="checkbox"
                  checked={this.state.showPrerequisite}
                  onChange={this.handleInputChange}
                />{" "}
                Prerequisite
                <span className={classes.paddingInput}>
                  <input
                    name="showRelatedTo"
                    type="checkbox"
                    checked={this.state.showRelatedTo}
                    onChange={this.handleInputChange}
                    className={classes.paddingInput}
                  />{" "}
                  Related to
                </span>
                <span className={classes.paddingInput}>
                  <input
                    name="showOfferedIn"
                    type="checkbox"
                    checked={this.state.showOfferedIn}
                    onChange={this.handleInputChange}
                    className={classes.paddingInput}
                  />{" "}
                  Offered in
                </span>
                <span className={classes.paddingInput}>
                  <input
                    name="expandedAll"
                    type="checkbox"
                    checked={this.state.expandedAll}
                    onChange={this.handleexpandedAll}
                    className={classes.paddingInput}
                  />{" "}
                  Expanded all
                </span>
                {this.state.showWhatIF ? (
                  <Alert variant="danger">
                    <AlertTitle>
                      This is not a legal Program Of Study
                    </AlertTitle>
                  </Alert>
                ) : null}
                <GroupedTable
                  columns={columns}
                  rows={this.state.data}
                  expanded={this.state.expandedAll}
                  groupBy="requirement"
                />
                <POSFooter txt={this.state.footer}></POSFooter>
                <DegreeCalculations>
                  {this.state.hasMinor ? (
                    <DegreeCalculation
                      title={this.state.minor.title}
                      allCredits={this.state.minor.sum}
                      outOf={this.state.minor.outOf}
                      completed={this.state.minor.completed}
                      current={this.state.minor.current}
                      planned={this.state.minor.planned}
                      GPA={this.state.minor.gpa}
                    />
                  ) : null}

                  <DegreeCalculation
                    title={this.state.major.title}
                    allCredits={this.state.major.sum}
                    outOf={this.state.major.outOf}
                    completed={this.state.major.completed}
                    current={this.state.major.current}
                    planned={this.state.major.planned}
                    GPA={this.state.major.gpa}
                  />

                  {this.state.hasElective ? (
                    <DegreeCalculation
                      title={this.state.elective.title}
                      allCredits={this.state.elective.sum}
                      outOf={this.state.elective.outOf}
                      completed={this.state.elective.completed}
                      current={this.state.elective.current}
                      planned={this.state.elective.planned}
                      GPA={this.state.elective.gpa}
                    />
                  ) : null}

                  <DegreeCalculation
                    title={this.state.degree.title}
                    allCredits={this.state.degree.sum}
                    outOf={this.state.degree.outOf}
                    completed={this.state.degree.completed}
                    current={this.state.degree.current}
                    planned={this.state.degree.planned}
                    GPA={this.state.degree.gpa}
                  />

                  <DegreeCalculation
                    title={this.state.total.title}
                    allCredits={this.state.total.sum}
                    outOf={this.state.total.outOf}
                    completed={this.state.total.completed}
                    current={this.state.total.current}
                    planned={this.state.total.planned}
                    GPA={this.state.total.gpa}
                  />
                </DegreeCalculations>
              </Container>
            ) : (
              <Blocked></Blocked>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    stuno: state.stuno.studentno,
  };
};
export default connect(mapStateToProps)(POS);
