import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import PopupComponent from "../../containers/Registration/PopupComponent";
import AdditionalLinks from "./Navigation/AdditionalLinks";

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#1971c2",
    margin: "0",
    padding: "0",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    "@media print": {
      display: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  helpButton: {
    marginRight: theme.spacing(2),
    background: "transparent",
    textDecoration: "none",
    border: "0",
    fontSize: "18px",
    color: "white",
  },
  help: {
    alignSelf: "center",
    justifyContent: "center",
  },
}));

const AppHeader = (props) => {
  const classes = useStyles();
  const [showVideoLinks, setShowVideoLinks] = useState(false);

  const help = () => {
    setShowVideoLinks(true);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box style={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={11}>
                <Typography variant="h6">{props.title}</Typography>
              </Grid>
              <Grid item xs={4} sm={1} className={classes.help}>
                <input
                  type="submit"
                  value={"Help(?)"}
                  title="مساعدة"
                  className={classes.helpButton}
                  onClick={() => help()}
                />
              </Grid>
            </Grid>
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <PopupComponent
        show={showVideoLinks}
        header={"Links"}
        onHide={() => setShowVideoLinks(false)}
        size="xl"
      >
        <AdditionalLinks videos={true} />
      </PopupComponent>
    </React.Fragment>
  );
};

AppHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default AppHeader;
