export const SETUSERTYPE = "SETUSERTYPE";
export const SETUSERNAME = "SETUSERNAME";
export const SETSTUDENTDEGREE = "SETSTUDENTDEGREE";
export const SETERROR = "SETERROR";
export const SETUPDATECART = "SETUPDATECART";
export const SETSTUDENTNO = "SETSTUDENTNO";
export const SETALLOWREG = "SETALLOWREG";
export const SETCOURSECART = "SETCOURSECART";
export const SETIDUSER = "SETIDUSER";
export const SETPAGETITLE = "SETPAGETITLE";
export const SETNAME = "SETNAME";
export const SETUPDATESTAUS = "SETUPDATESTAUS";
