import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import NameAndNum from "../../../../components/BUAP/Student/Information/NameAndNum";
import Header from "../../../../components/Shared/Header";
import Receipt from "../../../../components/BUAP/Student/Financial/Receipt/Receipt";
import axios from "../../../../axios/BUAP/students";
import { connect } from "react-redux";
import GetToken from "../../../../Shared/GetToken";
import Semester from "../../../../components/Shared/Semester";
import Select from "react-select";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid, Typography } from "@material-ui/core";
import Blocked from "../Blocked";
import PALPAY from "../../../../components/BUAP/Student/Financial/PALPAY";
import CreditCard from "../../../../components/BUAP/Student/Financial/CreditCard";
import axiosCommon from "../../../../axios/BUAP/common";
import BankStamp from "../../../../components/BUAP/Student/Financial/Bankstamp/Bankstamp";

class Financial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: -1,
      financialStatement: [],
      balance: 0,
      jdCurrency: false,
      avilableTerms: [{}],
      header: null,
      outStandingBalance: "",
      studentName: "",
      studentNumber: "",
      minimumPayement: [],
      showBankstamp: false,
      show: false,
      url: "",
    };
    this.updateData = this.updateData.bind(this);
    //Login to old buap for visa card
    axiosCommon
      .get("EncryptedUsername", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        const urllink =
          response.data +
          "&ut=" +
          props.usertype +
          "&page=login" +
          "&username=" +
          props.username +
          "&stuno=" +
          props.stuno;
        this.setState({ ...this.state, urllink: urllink });
      });
  }

  updateData = (term) => {
    axios
      .get(
        "GetFinancialStatement/" +
          term +
          "/" +
          this.props.username +
          "/" +
          this.props.studentdegree +
          "/" +
          this.props.stuno,
        {
          headers: { Authorization: GetToken("token") },
        }
      )
      .then((response) => {
        let showStamp = false;
        if (
          response.data.finFrontend.minimumPayement !== null &&
          response.data.finFrontend.minimumPayement.amount !== 0
        ) {
          showStamp = true;
        }
        this.setState({
          minimumPayement: null,
        });
        this.setState({
          minimumPayement: response.data.finFrontend.minimumPayement,
          financialStatement: response.data.finFrontend.financialStatement,
          balance: response.data.finFrontend.balance,
          jdCurrency: response.data.finFrontend.jdCurrency,
          term: response.data.finFrontend.term,
          header: (
            <div>
              <div>
                <Semester
                  sem={response.data.finFrontend.term}
                  txt="Financial Statement for "
                />
              </div>
              {(showStamp &&
                response.data.finFrontend.minimumPayement !== null &&
                response.data.finFrontend.minimumPayement.amount > 0) ||
              (response.data.finFrontend.outStandingBalance !== "" &&
                response.data.finFrontend.minimumPayement !== null &&
                response.data.finFrontend.minimumPayement.amount > 0) ? (
                <div>
                  <a
                    href={`/printreceipt?stuno=${this.props.stuno}&studeg=${this.props.studentdegree}&term=${response.data.finFrontend.term}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Print
                  </a>
                </div>
              ) : null}
            </div>
          ),
          outStandingBalance: response.data.finFrontend.outStandingBalance,
          showBankstamp: showStamp,
        });
      });
  };

  componentDidMount() {
    let term = -1;
    axios
      .get("NameAndNum/" + this.props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        this.setState({
          studentName: response.data.studentName,
          studentNumber: response.data.studentNumber,
        });
      });

    axios
      .get("GetFinancialStatementTerms/" + this.props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        term = Math.max.apply(
          Math,
          response.data.map(function (o) {
            return o.value;
          })
        );
        this.setState({
          avilableTerms: response.data,
          term: term,
        });
        if (response.status === 200) {
          this.setState({
            show: true,
          });
        }
        this.updateData(term);
      });
  }

  handleChange = (value) => {
    this.setState({
      ...this.state,
      term: value.value,
    });
    this.updateData(value.value);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.show ? (
          <Container>
            <iframe
              title="oldbuap"
              style={{ border: "none", display: "hide" }}
              src={this.state.urllink}
              width="0"
              height="0"
            />
            <Header header={this.state.header} />

            <div>
              <Select
                defaultValue={this.state.term}
                onChange={this.handleChange}
                options={this.state.avilableTerms}
                value={this.state.avilableTerms.filter(
                  (option) => option.value === this.state.term
                )}
              />
            </div>
            <NameAndNum />
            <Receipt
              data={this.state.financialStatement}
              balance={this.state.balance}
              jdCurrency={this.state.jdCurrency}
            />
            {this.state.outStandingBalance !== "" ? (
              <Alert severity="warning">
                <AlertTitle>
                  Student Has Previous Balance - You Should Pay Previous
                  Semester -<b>{this.state.outStandingBalance}</b>
                </AlertTitle>
              </Alert>
            ) : null}

            {(this.state.showBankstamp &&
              this.state.minimumPayement !== null &&
              this.state.minimumPayement.amount > 0) ||
            (this.state.outStandingBalance !== "" &&
              this.state.minimumPayement !== null &&
              this.state.minimumPayement.amount > 0) ? (
              <React.Fragment>
                {this.state.jdCurrency ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ paddingTop: "10px" }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      (JOD) الدفع لحساب الدينار الاردني
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ paddingTop: "10px" }}
                  >
                    <Typography
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      (ILS) الدفع لحساب الشيكل
                    </Typography>
                  </Grid>
                )}
                <BankStamp
                  minAmount={
                    this.state.minimumPayement === null
                      ? this.state.balance
                      : this.state.minimumPayement.amount
                  }
                  endDate={this.state.minimumPayement.enddate}
                  stuname={this.state.studentName}
                  stunum={this.state.studentNumber}
                  term={this.state.term}
                  jdCurrency={this.state.jdCurrency}
                  balance={this.state.balance}
                ></BankStamp>
                <PALPAY></PALPAY>
                <CreditCard
                  stName={this.state.studentName}
                  stNo={this.state.studentNumber}
                  term={this.state.term}
                  min={
                    this.state.minimumPayement === null
                      ? this.state.balance
                      : this.state.minimumPayement.amount
                  }
                  balance={this.state.balance}
                  studeg={this.props.studentdegree}
                  currency={this.state.jdCurrency ? "JD" : "ILS"}
                ></CreditCard>
              </React.Fragment>
            ) : null}
          </Container>
        ) : (
          <Blocked></Blocked>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    stuno: state.stuno.studentno,
    username: state.uname.username,
    usertype: state.utype.usertype,
  };
};

export default connect(mapStateToProps)(Financial);
