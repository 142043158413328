import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../../../axios/BUAP/students";
import { connect } from "react-redux";
import GetToken from "../../../../Shared/GetToken";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paperItem: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: "bold",
    width: 140,
    display: "inline-block",
  },
  [theme.breakpoints.down("xs")]: {
    title: {
      width: "auto",
    },
  },
}));

const NameAndNum = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [num, setNum] = useState(0);

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  useEffect(() => {
    axios
      .get("NameAndNum/" + props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setName(response.data.studentName);
        var formatname = zeroPad(response.data.studentNumber, 7);
        setNum(formatname);
      });
  }, [props.studentdegree]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.paperItem} elevation={0}>
            <span className={classes.title}>Student Name :</span> {name}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paperItem} elevation={0}>
            <span className={classes.title}>Student No :</span>
            {num}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
  };
};

export default connect(mapStateToProps)(NameAndNum);
