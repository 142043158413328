import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import usernameReducer from "./store/reducers/BUAP/username";
import userTypeReducer from "./store/reducers/BUAP/userType";
import studentDegreeReducer from "./store/reducers/BUAP/studentdegree";
import updateCartReducer from "./store/reducers/BUAP/updatecart";
import studentnoReducer from "./store/reducers/BUAP/studentno";
import allowregReducer from "./store/reducers/BUAP/allowreg";
import updateCourseCartReducer from "./store/reducers/BUAP/coursecart";
import iduserReducer from "./store/reducers/BUAP/iduser";
import nameReducer from "./store/reducers/BUAP/name";
import pagetitleReducer from "./store/reducers/BUAP/pagetitle";
import updateStatusReducer from "./store/reducers/BUAP/updatestatus";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import "./index.css";
//Redux
const rootReducer = combineReducers({
  uname: usernameReducer,
  utype: userTypeReducer,
  studeg: studentDegreeReducer,
  ucart: updateCartReducer,
  stuno: studentnoReducer,
  allowreg: allowregReducer,
  ccart: updateCourseCartReducer,
  idu: iduserReducer,
  fullname: nameReducer,
  page: pagetitleReducer,
  upstatus: updateStatusReducer,
});

const looger = (str) => {
  return (next) => {
    return (action) => {
      const result = next(action);
      return result;
    };
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(looger, thunk))
);

/*    <Provider store={store}>
<React.StrictMode> 
*/
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
