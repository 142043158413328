import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import axios from "../../../../axios/BUAP/students";
import { connect } from "react-redux";
import GetToken from "../../../../Shared/GetToken";
import PersonalInfo from "../../../../components/BUAP/Student/StudentInfo/PersonalInfo";
import Message from "../../../../components/BUAP/Message";
import PrintButton from "../../../../components/Shared/PrintButton/PrintButton";

class FinalExam extends Component {
  state = {
    showFinalExam: false,
    courses: {
      examDay: "",
      courseSection: "",
      sessionTime: "",
      room: "",
      seat: "",
    },
  };
  componentDidMount() {
    axios
      .get("FinalExam/" + this.props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        this.setState({ courses: response.data });
      });

    axios
      .get("showseatno", {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        this.setState({ showFinalExam: response.data });
      });
  }

  render() {
    return (
      <Container>
        {this.state.courses.length > 0 ? (
          <React.Fragment>
            <PersonalInfo stuNo={this.props.stuno} show={false} />
            <br />
            <TableContainer component={Paper}>
              <PrintButton />
              <Table size="small" aria-label="spanning table" padding="none">
                <TableHead>
                  <TableRow style={{ background: "#DCDCDC" }}>
                    <TableCell align="center"></TableCell>
                    <TableCell>Exam Day</TableCell>
                    <TableCell>Course/Section</TableCell>
                    <TableCell>Session Time</TableCell>
                    <TableCell>Room</TableCell>
                    {this.state.showFinalExam ? (
                      <TableCell>Seat</TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(this.state.courses).map((key, index) => {
                    return (
                      <TableRow
                        key={index}
                        style={
                          index % 2
                            ? { background: "#F8F8FF" }
                            : { background: "white" }
                        }
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{this.state.courses[key].examDay}</TableCell>
                        <TableCell>
                          {this.state.courses[key].courseSection}
                        </TableCell>
                        <TableCell>
                          {this.state.courses[key].sessionTime}
                        </TableCell>
                        <TableCell>{this.state.courses[key].room}</TableCell>
                        {this.state.showFinalExam ? (
                          <TableCell>{this.state.courses[key].seat}</TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <Message msgCode={38} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    stuno: state.stuno.studentno,
  };
};

export default connect(mapStateToProps)(FinalExam);
