import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApprovedIcon from "@material-ui/icons/CheckCircleRounded";
import Hold from "@material-ui/icons/HourglassFullRounded";
import Rejected from "@material-ui/icons/CancelPresentationRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Circle from "@material-ui/icons/RadioButtonUnchecked";
import Planned from "@material-ui/icons/Update";
import InCart from "@mui/icons-material/AddShoppingCart";
import Dropped from "@material-ui/icons/Delete";
import { BsCircleHalf } from "@react-icons/all-files/bs/BsCircleHalf";
import { PlusCircle } from "react-bootstrap-icons";

const iconStyle = makeStyles((theme) => ({
  icon: {
    fontSize: 15,
  },
  planned: {
    color: "red",
  },
  completed: {
    color: "green",
  },
  registered: {
    color: "orange",
  },
  completedGroup: {
    transform: "rotate(90deg)",
    color: "green",
  },
}));

const ParadigmIcon = (props) => {
  const iconClasses = iconStyle();
  let icon;
  if (props.status === "Completed") {
    icon = (
      <CheckCircleIcon
        color="primary"
        className={[iconClasses.icon, iconClasses.completed]}
      />
    );
  } else if (props.status === "CompletedGroup") {
    icon = (
      <BsCircleHalf color="primary" className={[iconClasses.completedGroup]} />
    );
  } else if (props.status === "EmptyGroup") {
    icon = <Circle color="primary" className={iconClasses.icon} />;
  } else if (props.status === "Dropped") {
    icon = <Dropped color="primary" className={iconClasses.icon} />;
  } else if (props.status === "Registered" || props.registered === 1) {
    icon = (
      <CheckCircleIcon
        color="success"
        className={[iconClasses.icon, iconClasses.registered]}
      />
    );
  } else if (props.status === "Planned") {
    icon = (
      <Planned
        color="info"
        className={[iconClasses.icon, iconClasses.planned]}
      />
    );
  } else if (
    props.status === "incart" ||
    props.status === "INCART" ||
    props.status === "1" ||
    props.status === 1 ||
    props.status === true
  ) {
    icon = <InCart color="success" className={iconClasses.icon} />;
  } else if (
    props.status === "Approved" ||
    props.status === "2" ||
    props.status === 2
  ) {
    icon = <ApprovedIcon color="primary" className={iconClasses.icon} />;
  } else if (props.status === "Hold" || props.status === "3") {
    icon = <Hold color="primary" className={iconClasses.icon} />;
  } else if (props.status === "Rejected" || props.status === "4") {
    icon = <Rejected color="primary" className={iconClasses.icon} />;
  } else if (typeof props.available === "undefined") {
    icon = "";
  } else if (props.available) {
    //Available
    //icon = <span style={{ color: "green" }}>Available</span>;
    icon = <PlusCircle></PlusCircle>;
  } else {
    //Available
    icon = <span style={{ color: "red" }}>Closed</span>;
  }

  return <div style={{ textAlign: "center" }}>{icon}</div>;
};

export default ParadigmIcon;
