import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from "../../../../axios/BUAP/students";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GetToken from "../../../../Shared/GetToken";
import SpinnerBootstrap from "../../../UI/Spinner/Spinner";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    fontSize: 12,
  },
  paperItem: {
    padding: theme.spacing(0.2),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: "bold",
    width: 100,
    display: "inline-block",
  },
  lineSpace: {
    margin: 0,
    marginBottom: "10px",
  },

  [theme.breakpoints.down("xs")]: {
    title: {
      width: "auto",
    },
  },
});

class Address extends React.Component {
  state = {
    errorMessage: null,
    AddressInfo: {
      cityName: "",
      country: "",
      tel: "",
      region: "",
      email: "",
      mobile: "",
    },
    waitData: true,
  };

  componentDidMount() {
    axios
      .get("AddressInfo/" + this.props.stuNo, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        this.setState({ AddressInfo: response.data });
        this.setState({ waitData: false });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.waitData ? (
          <SpinnerBootstrap />
        ) : (
          <React.Fragment>
            <Typography style={{ marginTop: 15, fontWeight: "bold" }}>
              Contact information
              <hr className={classes.lineSpace} />
            </Typography>

            <div className={classes.root}>
              <hr />
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الشارع">
                      Street :
                    </div>
                    {this.state.AddressInfo.street}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="المدينة">
                      City :
                    </div>
                    {this.state.AddressInfo.cityName}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="المنطقة">
                      Region :
                    </div>
                    {this.state.AddressInfo.region}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الدولة">
                      Country :
                    </div>
                    {this.state.AddressInfo.country}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="البريد الالكتروني">
                      Email :
                    </div>
                    {this.state.AddressInfo.email}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="الهاتف">
                      Tel :
                    </div>
                    {this.state.AddressInfo.tel}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperItem} elevation={0}>
                    <div className={classes.title} title="رقم الموبايل">
                      Mobile :
                    </div>
                    {this.state.AddressInfo.mobile}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Address.propTypes = {
  stuNo: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(Address);
