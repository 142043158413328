import React, { Suspense, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import Spinner from "../../components/UI/Spinner/Spinner";
import { connect } from "react-redux";
import SignIn from "./Login/SignIn";
import Routes from "../../Auth/Routes";
import axios from "../../axios/BUAP/common";
import GetToken from "../../Shared/GetToken";
import * as actionCreators from "../../store/actions/BUAP/BUAP";

const BUAP = (props) => {
  const [view, setView] = useState(null);

  useEffect(() => {
    const login = () => {
      let path = window.location.pathname;
      if (path === "/includes/tel_email_dir/tel_email.php") {
        window.location =
          "https://buapv1.bethlehem.edu/includes/tel_email_dir/tel_email.php";
      }

      if (path === "/index2.php") {
        window.location = "https://buap.bethlehem.edu/";
      }
      const token = GetToken("token");
      if (token !== undefined && props !== undefined && props.username !== "") {
        //Get list of autherized data
        axios
          .get("AutherizedList/", {
            headers: { Authorization: GetToken("token") },
          })
          .then((response) => {
            setView(
              <Layout title={props.page} data={response.data}>
                <Routes data={response.data} />
              </Layout>
            );
            if (response.status === 401) {
              clearSession();
            }
          })
          .catch(function (error) {
            //If the token is expired
            clearSession();
          });
      }
      if (token !== null) {
        const usertype = localStorage.getItem("usertype");
        const username = localStorage.getItem("username");
        const facCode = localStorage.getItem("facCode");
        const studentdegree = localStorage.getItem("studentdegree");
        const stuno = localStorage.getItem("stuno");
        const name = localStorage.getItem("name");
        props.setusertype(usertype);
        props.setusername(username);
        props.setiduser(facCode);
        props.setstudentdegree(studentdegree);
        props.setstuno(stuno);
        props.setname(name);
      } else if (props.usertype === -1 || props.username === "") {
        setView(<SignIn></SignIn>);
      }
    };
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.username]);

  const clearSession = () => {
    localStorage.clear();
    props.setusertype(-1);
    props.setstuno(-1);
    props.setusername("");
    props.setname("");
    props.setstudentdegree(-1);
    setView(<SignIn></SignIn>);
  };

  return <Suspense fallback={<Spinner />}>{view}</Suspense>;
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    usertype: state.utype.usertype,
    page: state.page.pagetitle,
  };
};
//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setusertype: (e) => dispatch(actionCreators.setusertype(e)),
    setusername: (e) => dispatch(actionCreators.setusername(e)),
    setstudentdegree: (e) => dispatch(actionCreators.setstudentdegree(e)),
    setstuno: (e) => dispatch(actionCreators.setstudentno(e)),
    setiduser: (e) => dispatch(actionCreators.setiduser(e)),
    setname: (e) => dispatch(actionCreators.setname(e)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(BUAP));
