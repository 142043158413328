import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import TimeSlot from "./Slots/TimeSlot";
import TrackSlot from "./Slots/TrackSlot";
const ScheduleView = (props) => {
  //Sort the schedulelist by day and time

  //   const mobileList = props.scheduleList.sort(
  //     (a, b) =>
  //       a.dayCode - b.dayCode ||
  //       a.viewTime.split("-")[0].localeCompare(b.viewTime.split("-")[0])
  //   );
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` }) && false;
  useEffect(() => {});

  return (
    <React.Fragment>
      {!isMobile ? (
        <div className="schedule" aria-labelledby="schedule-heading">
          <TimeSlot />
          <TrackSlot />
          {props.listItems}
        </div>
      ) : (
        <div>
          <div>schedule on mobile is under construction.</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ScheduleView;
