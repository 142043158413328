import React, { useEffect, useState } from 'react';
import * as actionCreators from '../../store/actions/BUAP/BUAP';
import { connect } from 'react-redux';
import axiosAPI from '../../axios/BUAP/AuthToken';
import POS from '../../containers/BUAP/Student/POS/POS';
import Paradigm from '../../containers/BUAP/Student/Paradigm/Paradigm';
import ViewOldBuap from '../BUAP/ViewOldBuap';
import AdviseeList from '../../containers/BUAP/Advisor/AdviseeList/AdviseeList';
import Forms from '../../containers/BUAP/Forms/Forms';

const WebView = (props) => {

    //Query string parameters
    const [viewcomponent, setviewcomponent] = useState(null);
    const query = new URLSearchParams(window.location.search);
    const id = query.get("id");
    const component = query.get("component");
    useEffect(() => {
        async function updateData() {
            await axiosAPI.post("WebView",
                {
                    token: id
                }).then(response => {

                    const token = `bearer ${response.data.token}`;
                    localStorage.setItem('token', token);
                    //document.cookie = "token=" + token;
                    props.setusername(response.data.username);
                    if (response.data.rank === 0) {
                        props.setstudentdegree(response.data.id);
                        props.setstuno(response.data.username);
                        if (component === "pos") {
                            setviewcomponent(<POS />);
                        }
                        else if (component === "pr") {
                            setviewcomponent(<Paradigm />);
                        }
                        else if (component === "fn") {
                            setviewcomponent(<ViewOldBuap page="fin" />);
                        }
                        else if (component === "soc") {
                            setviewcomponent(<ViewOldBuap page="socs" />);
                        }
                        else if (component === "tev") {
                            setviewcomponent(<ViewOldBuap page="tee" />);
                        }
                        else if (component === "frm") {
                            setviewcomponent(<Forms />);
                        }
                    }
                    else {
                        localStorage.setItem('usertype', response.data.rank);
                        localStorage.setItem('username', response.data.username);
                        localStorage.setItem('facCode', response.data.id);
                        props.setstudentdegree(-1);
                        props.setstuno(-1);
                        if (component === "adv") {
                            setviewcomponent(<AdviseeList />);
                        }
                        else if (component === "gre") {
                            setviewcomponent(<ViewOldBuap page="gre" />);
                        }
                        else if (component === "scs") {
                            setviewcomponent(<ViewOldBuap page="scs" />);
                        }
                        else if (component === "tee") {
                            setviewcomponent(<ViewOldBuap page="tev" />);
                        }
                        else if (component === "socstu") {
                            setviewcomponent(<ViewOldBuap page="socstu" />);
                        }
                        else if (component === "frm") {
                            setviewcomponent(<Forms />);
                        }
                        else if (component === "gdis") {
                            setviewcomponent(<ViewOldBuap page="gdis" />);
                        }
                    }
                    props.setiduser(response.data.id);
                    props.setusertype(response.data.rank);
                }
                );

        };
        updateData();
    }, [component, id, props]);
    return (
        <div>
            {viewcomponent}
        </div>
    );
}

//Redux
const mapDispatchToProps = dispatch => {
    return {
        setusertype: (e) => dispatch(actionCreators.setusertype(e)),
        setusername: (e) => dispatch(actionCreators.setusername(e)),
        setstudentdegree: (e) => dispatch(actionCreators.setstudentdegree(e)),
        setstuno: (e) => dispatch(actionCreators.setstudentno(e)),
        setiduser: (e) => dispatch(actionCreators.setiduser(e)),
    }
}
export default connect(null, mapDispatchToProps)(WebView);
