import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#FFF5EE',
    color: 'black',
    padding: 10,
    textAlign: 'left',
    marginTop: 15
  },
}));

const DegreeCalculations = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.footer} elevation={1} square>
      {props.children}
    </Paper>
  )
}

export default DegreeCalculations;