import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState = {
    pagetitle: 'Academic portal',
};

const pagetitleReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETPAGETITLE:
            return {
                ...state,
                pagetitle: action.value
            }
        default:
            return state;
    }
}

export default pagetitleReducer;