import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState = {
    usertype: -1,
};

const userTypeReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETUSERTYPE:
            return {
                ...state,
                usertype: action.value
            }
        default:
            return state;
    }
}

export default userTypeReducer;