import React from "react";
import classes from "../Logo/Logo.module.css";
import LogoImg from "../../../assets/images/Logo/BULogo.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Link to="/">
        <img
          className={classes.Logo}
          src={LogoImg}
          alt="Bethlehem University"
        />
      </Link>
    </div>
  );
};

export default Logo;
