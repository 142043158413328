import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import HtmlParser from "react-html-parser";
import classes from "./Receipt.module.css";
import moment from "moment";

export default function Receipt(props) {
  const columns = [
    { name: "No", align: "center" },
    { name: "Description", align: "left" },
    { name: "Date", align: "center" },
    { name: "Debit", align: "center" },
    { name: "Credit", align: "center" },
    { name: "Balance", align: "center" },
  ];
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          size="small"
          className={classes.table}
          aria-label="spanning table"
          padding="none"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.name} align={column.align}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ fontSize: "80px" }}>
            {props.data.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="justify">{HtmlParser(row.name)}</TableCell>
                <TableCell align="center">
                  {row.invDate === "0001-01-01T00:00:00" ||
                  row.name === "Scholarship"
                    ? null
                    : moment(row.invDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="center">
                  {row.debit ? row.amount : null}
                </TableCell>
                <TableCell align="center">
                  {row.debit ? null : row.amount}
                </TableCell>
                <TableCell align="center">{row.balance}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5} align="right">
                <b>Amount Due: {props.jdCurrency ? " (JD)" : " (NIS)"}</b>
              </TableCell>
              <TableCell align="center">
                <b>{props.balance}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
