import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "../../../../axios/BUAP/cart";
import { connect } from "react-redux";
import PopupComponent from "../../../../containers/Registration/PopupComponent";
import GetToken from "../../../../Shared/GetToken";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  [theme.breakpoints.down("xs")]: {
    head: {
      padding: 3,
      fontSize: 9,
    },
    body: {
      padding: 3,
      fontSize: 9,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  link: {
    cursor: "pointer",
    color: "#2a67a8",
    fontWeight: "bold",
  },
  dropIcon: {
    cursor: "pointer",
    color: "#FF0000",
    fontWeight: "bold",
    fontSize: "large",
  },
  dropRegistered: {
    cursor: "pointer",
    color: "#FF6347",
    fontWeight: "bold",
    fontSize: "large",
  },
  [theme.breakpoints.down("xs")]: {
    columnTable: {
      display: "none",
    },
    table: {
      minWidth: 200,
    },
  },
}));

const PreapprovalCoursesStudent = (props) => {
  const classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({
    catalogCode: -1,
    catalogKey: "",
    title: "",
    credits: 0,
    notes: "",
    addDrop: false,
    mandatoryOptional: false,
    registered: false,
  });
  const [waitData, setWaitData] = useState(true);
  useEffect(() => {
    axios
      .get("PreApproval/" + props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setData(response.data);
        setWaitData(false);
      });
  }, [props.updatestudentstatus, props.studentdegree]);

  return (
    <React.Fragment>
      {waitData ? (
        <SpinnerBootstrap />
      ) : (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="customized table"
              padding="none"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Course</StyledTableCell>
                  <StyledTableCell className={classes.columnTable}>
                    Course title
                  </StyledTableCell>
                  <StyledTableCell>Credits</StyledTableCell>
                  <StyledTableCell>Add</StyledTableCell>
                  <StyledTableCell>Drop</StyledTableCell>
                  <StyledTableCell>Mandatory</StyledTableCell>
                  <StyledTableCell>Optional</StyledTableCell>
                  <StyledTableCell>Notes</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data).map((index) => {
                  return (
                    <StyledTableRow key={data[index].catalogCode} hover={true}>
                      <StyledTableCell>
                        {data[index].catalogKey}
                      </StyledTableCell>
                      <StyledTableCell>{data[index].title}</StyledTableCell>
                      <StyledTableCell>{data[index].credits}</StyledTableCell>
                      <StyledTableCell>
                        {data[index].addDrop ? (
                          <CheckCircleIcon color="primary" />
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data[index].addDrop ? null : (
                          <CheckCircleIcon color="secondary" />
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data[index].mandatoryOptional ? (
                          <CheckCircleIcon color="primary" />
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data[index].mandatoryOptional ? null : (
                          <CheckCircleIcon color="secondary" />
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{data[index].notes}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <PopupComponent
            show={modalShow}
            header={"offered courses"}
            onHide={() => setModalShow(false)}
            size="xl"
          >
            Show offered courses for a course
          </PopupComponent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
  };
};
export default connect(mapStateToProps)(PreapprovalCoursesStudent);
