import React, { useState } from "react";
import StudentInfoForAdviseeList from "../../Advisor/AdviseeList/StudentInfoForAdviseeList";
import { Container, Row, Col, Button } from "react-bootstrap";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { connect } from "react-redux";
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";

const ViewStudent = (props) => {
  const [load, setLoad] = useState(false);
  const [usertype, setusertype] = useState(false);

  const viewStudentHandler = (type) => {
    setLoad(true);
    if (type === 0) {
      setusertype(0);
      props.setusertype(0);
    } else {
      const usertype = localStorage.getItem("usertype");
      props.setusertype(usertype);
      setusertype(usertype);
    }
    setLoad(false);
  };
  return (
    <React.Fragment>
      <Container>
        <Row style={{ backgroundColor: "#F0F8FF", height: "60px" }}>
          <Col style={{ margin: "auto" }}>
            Username: <b>{props.username}</b>
          </Col>
          <Col style={{ textAlign: "right", margin: "auto" }}>
            {}
            {usertype === 0 ? (
              <Button variant="primary" onClick={() => viewStudentHandler(1)}>
                Click to view as {props.username}{" "}
              </Button>
            ) : (
              <Button variant="primary" onClick={() => viewStudentHandler(0)}>
                Click to view as a student
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {load ? (
              <Spinner />
            ) : (
              <StudentInfoForAdviseeList
                studentno={props.stuno}
                usertype={props.usertype}
              ></StudentInfoForAdviseeList>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setusertype: (e) => dispatch(actionCreators.setusertype(e)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(ViewStudent));
