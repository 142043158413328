import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LogoImg from "../../../assets/images/Logo/BULogo.png";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/BUAP/BUAP";
import axiosAPI from "../../../axios/BUAP/AuthToken";
import clearLocalStorage from "../../../Shared/clearLocalStorage";
import HomePageImg from "../../../assets/images/BethlehemUniversity.png";
import { Copyright } from "../Copyright/Copyright";
import Spinner from "../../../components/UI/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "white",
    color: "white",
  },
  paper: {
    margin: theme.spacing(7, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: "#1971c2",
    margin: theme.spacing(3, 0, 2),
  },
  signintext: {
    margin: theme.spacing(3, 0, 0),
  },
  txtcontent: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    background: "#1971c2",
    color: "white",
  },
  slang: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    fontSize: "70px",
    color: "#1971c2",
  },
  [theme.breakpoints.down("xs")]: {
    txtcontent: {
      padding: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      background: "none",
      display: "none",
    },
    slang: {
      padding: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      background: "none",
      display: "none",
    },
    image: {
      display: "none",
    },
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [load, setLoad] = React.useState(false);

  useEffect(() => {});

  const usernameHandler = (event) => {
    event.preventDefault();
    const uname = event.target.value.replace("@bethlehem.edu", "");
    setUsername(uname);
  };

  const passwordHandler = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const login = async (event) => {
    event.preventDefault();
    setLoad(true);
    clearLocalStorage();
    axiosAPI
      .post(
        "Authenticate",
        {
          username: username,
          password: password,
          mobile: false,
        },
        { timeout: 50000000 }
      )
      .then(
        (response) => {
          const token = `bearer ${response.data.token}`;
          localStorage.setItem("token", token); //New added
          props.setusername(response.data.username);
          props.setname(response.data.firstName + " " + response.data.lastName);

          if (response.data.rank === 0) {
            localStorage.setItem("studentdegree", response.data.id);
            localStorage.setItem("stuno", response.data.username);
            props.setstudentdegree(response.data.id);
            props.setstuno(response.data.username);
          } else {
            localStorage.setItem("studentdegree", -1);
            localStorage.setItem("stuno", -1);
            props.setstudentdegree(-1);
            props.setstuno(-1);
          }
          localStorage.setItem("usertype", response.data.rank);
          localStorage.setItem("username", username);
          localStorage.setItem("facCode", response.data.id);
          localStorage.setItem(
            "name",
            response.data.firstName + " " + response.data.lastName
          );
          props.setiduser(response.data.id);
          props.setusertype(response.data.rank);
        },
        (error) => {
          setLoad(false);
          setErrorMsg(
            <b style={{ color: "red" }}>
              Failed Authentication, the Username and Password did not match.
              Please try again.
            </b>
          );
        }
      )
      .catch((error) => {
        setLoad(false);
        alert("An error has been occured!");
      });
  };

  const usernameonKeyDown = (e) => {
    if (e.keyCode === 13) {
      document.getElementById("password").focus();
    }
  };

  const passwordonKeyDown = (e) => {
    if (e.keyCode === 13) {
      login(e);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={false}
        md={8}
        style={{ backgroundColor: "white" }}
      >
        <Typography variant="h6" component="h6" className={classes.slang}>
          Enter to learn, leave to serve
          <br />
          نتعلم لكي نخدم
        </Typography>
        <img
          alt="Bethlehem University"
          src={HomePageImg}
          width="100%"
          className={classes.image}
        />
        <Typography variant="h6" component="h6" className={classes.txtcontent}>
          This site is developed to help students and faculty members access
          various academic information and tools. If you have any comments,
          suggestions, or found any inaccurate information, please feel free to
          send us an email at: BUAP@bethlehem.edu
          <br />
          Thank you for using this portal
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={LogoImg} width={250} alt="BUAP" />
          <Typography
            component="h5"
            variant="h5"
            className={classes.signintext}
          >
            Bethlehem University Academic portal (BUAP)
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              onChange={usernameHandler}
              autoFocus
              onKeyDown={usernameonKeyDown}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={passwordHandler}
              onKeyDown={passwordonKeyDown}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          </form>
          {errorMsg}
          {load ? <Spinner></Spinner> : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={login}
          >
            Sign In
          </Button>

          <Box mt={5}>
            <Typography>For support call (Whatsapp Numbers):</Typography>
            <Typography>
              <strong>Password Issues:</strong> 00970566460461
            </Typography>
            <Typography>
              <strong>Technical Issues:</strong> 00972566808870 or
              00970569506399
            </Typography>
            <Typography>
              <strong>Moodle (Eclass) Issues:</strong> 00970566808850 or
              00972566808860
            </Typography>
            <Typography>
              <strong>Financial/registration issues:</strong> 00972562140080
            </Typography>
          </Box>

          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setusertype: (e) => dispatch(actionCreators.setusertype(e)),
    setusername: (e) => dispatch(actionCreators.setusername(e)),
    setstudentdegree: (e) => dispatch(actionCreators.setstudentdegree(e)),
    setstuno: (e) => dispatch(actionCreators.setstudentno(e)),
    setiduser: (e) => dispatch(actionCreators.setiduser(e)),
    setname: (e) => dispatch(actionCreators.setname(e)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
