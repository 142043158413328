import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink, withRouter } from "react-router-dom";
import Profile from "../Profile";
import { FaSignOutAlt as Signout } from "@react-icons/all-files/fa/FaSignOutAlt";
import { AiOutlineHome as HomePage } from "@react-icons/all-files/ai/AiOutlineHome";
import LinkIcon from "@mui/icons-material/Link";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import { connect } from "react-redux";
import clearLocalStorage from "../../../../Shared/clearLocalStorage";
import { Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import PopupComponent from "../../../../containers/Registration/PopupComponent";
import AdditionalLinks from "../AdditionalLinks";
import ViewOldBuap from "../../../BUAP/ViewOldBuap";
import classesStyle from "./nestedlist.module.css";
const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 280,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  mainItem: {
    paddingLeft: theme.spacing(0.1),
  },
  subItem: {
    textDecoration: "none",
  },
  subItemEven: {
    textDecoration: "none",
  },
  subItemTxt: {
    paddingLeft: theme.spacing(1),
  },
  noSpace: {
    padding: theme.spacing(0.1),
    paddingLeft: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    width: "100%",
  },
  itemBorder: {
    borderBottom: "1px solid gainsboro",
    color: "#01284c",
  },
  itemTxt: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  homepage: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  subMenu: {
    padding: theme.spacing(0.2),
    margin: theme.spacing(0),
  },
  icon: {
    fontSize: "20px",
    color: "#868e96",
  },
});

class NestedListMUI extends React.Component {
  state = {
    open: {},
    activePath: "",
    selectedIndex: 0,
    modalShow: false,
    modalContent: null,
  };

  handleClick = (key) => () => {
    this.setState({ [key]: !this.state[key] });
  };

  signoutHandle = async () => {
    //Signout from oldbuap
    <ViewOldBuap page="out" />;
    await clearLocalStorage();
    await this.props.setusertype(-1);
    await this.props.setstuno(-1);
    await this.props.setusername("");
    await this.props.setname("");
    await this.props.setstudentdegree(-1);

    await this.props.history.push("/");
  };
  additionalLinksHandle = () => {
    this.setState({
      modalShow: true,
      modalContent: <AdditionalLinks videos={false} />,
    });
  };
  homepageHandle = () => {
    this.props.history.push("/");
    this.setState({ selectedIndex: 0 });
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
  };

  onHideHandler = () => {
    this.setState({ modalShow: false });
  };

  render() {
    const { lists, classes } = this.props;

    return (
      <Container className={classesStyle.container}>
        <Profile />
        <List component="nav">
          <ListItem
            button
            onClick={this.homepageHandle}
            style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 18 }}
            selected={this.state.selectedIndex === 0}
            className={classes.itemBorder}
          >
            <ListItemIcon className={classes.icon}>
              <HomePage />
            </ListItemIcon>
            <ListItemText
              inset
              primary="Home Page"
              className={classes.homepage}
            />
          </ListItem>
          {lists.map(({ key, label, labelAr, icon: Icon, URL = "", items }) => {
            const open = this.state[key] || false;
            return (
              <div key={key}>
                {URL !== "" ? (
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem className={classes.noSpace}>
                      <NavLink
                        to={URL}
                        key={key}
                        className={classes.item + " " + classes.itemBorder}
                      >
                        <ListItem
                          button
                          selected={this.state.selectedIndex === key}
                          onClick={(event) =>
                            this.handleListItemClick(event, key)
                          }
                          classes={{ root: classes.noSpace }}
                        >
                          <ListItemIcon className={classes.icon}>
                            <Icon />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            title={labelAr}
                            primary={label}
                            className={classes.itemTxt}
                          />
                        </ListItem>
                      </NavLink>
                    </ListItem>
                  </Box>
                ) : (
                  <React.Fragment>
                    <ListItem button onClick={this.handleClick(key)}>
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText
                        inset
                        primary={label}
                        className={classes.mainItem}
                      />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {items.map(
                          ({
                            key: childKey,
                            label: childLabel,
                            icon: ChildIcon,
                            URL: childURL,
                            externalLink: external,
                          }) => (
                            <React.Fragment>
                              {external === false ? (
                                <NavLink
                                  to={childURL}
                                  key={childKey}
                                  className={classes.subItem}
                                >
                                  <ListItem button>
                                    <ListItemIcon className={classes.icon}>
                                      <ChildIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      inset
                                      primary={childLabel}
                                      className={classes.subItemTxt}
                                    />
                                  </ListItem>
                                </NavLink>
                              ) : (
                                <React.Fragment>
                                  {childKey % 2 === 0 ? (
                                    <NavLink
                                      to={{ pathname: childURL }}
                                      target="_blank"
                                      key={childKey}
                                      className={classes.subItem}
                                    >
                                      <ListItem button>
                                        <ListItemIcon className={classes.icon}>
                                          <ChildIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary={childLabel}
                                          className={classes.subMenu}
                                        />
                                      </ListItem>
                                    </NavLink>
                                  ) : (
                                    <NavLink
                                      to={{ pathname: childURL }}
                                      target="_blank"
                                      key={childKey}
                                      className={classes.subItemEven}
                                    >
                                      <ListItem button>
                                        <ListItemIcon className={classes.icon}>
                                          <ChildIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary={childLabel}
                                          className={classes.subMenu}
                                        />
                                      </ListItem>
                                    </NavLink>
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </List>
                    </Collapse>
                  </React.Fragment>
                )}
              </div>
            );
          })}
          <ListItem
            button
            onClick={this.additionalLinksHandle}
            className={classes.itemBorder}
            style={{ paddingLeft: 17, paddingBottom: 1, paddingTop: 1 }}
          >
            <ListItemIcon className={classes.icon}>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText className={classes.homepage} inset primary="Links" />
          </ListItem>

          <ListItem
            button
            onClick={this.signoutHandle}
            style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 18 }}
            className={classes.itemBorder}
          >
            <ListItemIcon className={classes.icon}>
              <Signout />
            </ListItemIcon>
            <ListItemText
              inset
              primary="Sign out"
              className={classes.homepage}
            />
          </ListItem>
        </List>

        <PopupComponent
          show={this.state.modalShow}
          header={"Links"}
          onHide={() => this.onHideHandler(false)}
          size="xl"
        >
          {this.state.modalContent}
        </PopupComponent>
      </Container>
    );
  }
}

NestedListMUI.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    username: state.uname.username,
    usertype: state.utype.usertype,
  };
};
//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setusertype: (e) => dispatch(actionCreators.setusertype(e)),
    setusername: (e) => dispatch(actionCreators.setusername(e)),
    setstudentdegree: (e) => dispatch(actionCreators.setstudentdegree(e)),
    setstuno: (e) => dispatch(actionCreators.setstudentno(e)),
    setname: (e) => dispatch(actionCreators.setname(e)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(NestedListMUI))
);
