const getSemester = (sem) => {
  const year = sem.toString().substring(0, 4);
  const term = parseInt(sem.toString().substring(4, 5));
  if (term === 1) {
    return "Spring-" + year;
  } else if (term === 2) {
    return "Summer-" + year;
  } else if (term === 3) {
    return "Fall-" + year;
  }

  return 0;
};

export default getSemester;
