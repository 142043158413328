import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import axios from "../../../axios/BUAP/registration";
import GetToken from "../../../Shared/GetToken";
import * as actionCreators from "../../../store/actions/BUAP/BUAP";
import NotificationAndNeedattentions from "./NotificationAndNeedattentions";
import SpinnerBootstrap from "../../UI/Spinner/Spinner";

const Alerts = (props) => {
  const [notifiaction, setNotifiaction] = useState(false);
  const [needAttentions, setNeedAttentions] = useState(false);
  const [wait, setWait] = useState(false);
  const prevCountRef = useRef();

  useEffect(() => {
    if (prevCountRef.upstatus !== props.upstatus) {
      axios
        .get("Notifications/" + props.studentdegree, {
          headers: { Authorization: GetToken("token") },
        })
        .then((response) => {
          setNotifiaction(response.data.notifiactions);
          setNeedAttentions(response.data.needAttentions);
          setWait(true);
        });
    }
  }, [props.studentdegree, props.upstatus]);

  return (
    <React.Fragment>
      {wait ? (
        <NotificationAndNeedattentions
          notifiaction={notifiaction}
          needAttentions={needAttentions}
          studentdegree={props.studentdegree}
        />
      ) : (
        <SpinnerBootstrap />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    updatecart: state.ucart.updatecart,
    usertype: state.utype.usertype,
    upstatus: state.upstatus.updatestatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupdatecart: (e) => dispatch(actionCreators.setupdatecart(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
