import * as actionTypes from '../../actions/BUAP/actionsTypes';

const inititalState = {
    coursecart: {
        courseCode:0,
        status:""
    },
};

const updateCourseCartReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETCOURSECART:
            return {
                ...state,
                coursecart: {
                    courseCode: action.value.courseCode,
                    status: action.value.status
                }
            }
        default:
            return state;
    }
}

export default updateCourseCartReducer;