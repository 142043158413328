import BUGroupedDataOne from "./BUGroupedDataOne";
import classes from "./BUGroupedData.module.css";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PopupComponent from "../../../../containers/Registration/PopupComponent";
import ListOfCourses from "../../../../containers/BUAP/Student/Paradigm/ListOfCourses";
import { useMediaQuery } from "react-responsive";

//Grouping the data
function GroupBy(array, keys) {
  let i, key, temp, split;
  let data = array.reduce((result, currentValue) => {
    key = "";
    for (i = 0; i < keys.length; i++) {
      key = key + currentValue[keys[i]] + "_";
    }
    if (!result[key]) {
      result[key] = 0;
    }
    return result;
  }, {});
  let grouped = [];
  Object.keys(data).forEach(function (key) {
    temp = {};
    split = key.split("_");
    for (i = 0; i < split.length - 1; i++) {
      temp[keys[i]] = split[i];
    }
    grouped.push(temp);
  });
  return grouped;
}

function BUGroupedData(props) {
  const isMobile = useMediaQuery({ query: `(max-width: 479px)` });
  const groupd = GroupBy(props.data, ["level", "semester"]);
  //check if the registration is opened or not
  const add = props.columns.some(function (item) {
    return item.name === "add";
  });

  const [showPrerequisite, setShowPrerequisite] = useState(false);
  const [showRelatedTo, setShowRelatedTo] = useState(false);
  const [showOfferedIn, setShowOfferedIn] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [header, setHeader] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "showPrerequisite") {
      setShowPrerequisite(value);
    } else if (name === "showRelatedTo") {
      setShowRelatedTo(value);
    } else if (name === "showOfferedIn") {
      setShowOfferedIn(value);
    }

    if (value) {
      document
        .querySelectorAll(".pr" + name)
        .forEach((a) => (a.style.display = "initial"));
    } else {
      document
        .querySelectorAll(".pr" + name)
        .forEach((a) => (a.style.display = "none"));
    }
  };

  const showCourseInParadimg = (
    groupType,
    courseCode,
    catalogKey,
    courseType
  ) => {
    setModalContent(
      <ListOfCourses
        coursecode={courseCode}
        group={groupType === 2 ? true : false}
        courseType={courseType}
      />
    );
    setModalShow(true);
    setHeader(catalogKey);
  };
  return (
    <div>
      <Container className={classes.containerbox}>
        {!isMobile ? (
          <Row>
            <Col>
              <input
                name="showPrerequisite"
                type="checkbox"
                checked={showPrerequisite}
                onChange={handleInputChange}
              />{" "}
              Prerequisite
              <span className={classes.paddingInput}>
                <input
                  name="showRelatedTo"
                  type="checkbox"
                  checked={showRelatedTo}
                  onChange={handleInputChange}
                  className={classes.paddingInput}
                />{" "}
                Related to
              </span>
              <span className={classes.paddingInput}>
                <input
                  name="showOfferedIn"
                  type="checkbox"
                  checked={showOfferedIn}
                  onChange={handleInputChange}
                  className={classes.paddingInput}
                />{" "}
                Offered in
              </span>
            </Col>
          </Row>
        ) : null}
        <PopupComponent
          show={modalShow}
          header={header}
          onHide={() => setModalShow(false)}
          size="xl"
        >
          {modalContent}
        </PopupComponent>
      </Container>

      {groupd.map((column) => (
        <BUGroupedDataOne
          semester={column.level}
          level={column.semester}
          data={props.data.filter(
            (row) =>
              row.semester === column.semester && row.level === column.level
          )}
          columns={props.columns.filter(
            (column) => column.name !== "level" && column.name !== "semester"
          )}
          add={add}
          showCourseInParadimg={showCourseInParadimg}
          prerequisite={showPrerequisite}
          offeredIn={showOfferedIn}
          relatedTo={showRelatedTo}
        ></BUGroupedDataOne>
      ))}
    </div>
  );
}

export default BUGroupedData;
